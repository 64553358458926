import React, { Fragment } from 'react';
import FieldGroup from '../../smart/field_group';
import SmartModal from '../../smart/modal';
import { Can } from '../../ability_context';

export default class Modal extends SmartModal {
  renderForm() {
    const logo = this.formValue('logo');

    return (
      <Fragment>
        <FieldGroup
          type="text"
          label="Title"
          name="venue[title]"
          defaultValue={this.formValue('title')}
          error={this.formError('title')}
        />

        <FieldGroup
          id="email"
          type="text"
          label="Email"
          name="venue[email]"
          defaultValue={this.formValue('email')}
          error={this.formError('email')}
        />

        <FieldGroup
          type="text"
          label="Phone number"
          name="venue[phone_number]"
          defaultValue={this.formValue('phone_number')}
          error={this.formError('phone_number')}
        />

        <FieldGroup
          type="select"
          options={this.includedData('phone_formats')}
          label="Phone format"
          name="venue[phone_format]"
          defaultValue={this.formValue('phone_format')}
          error={this.formError('phone_format')}
        />

        <FieldGroup
          type="checkbox"
          options={this.includedData('display_phone_number')}
          placeholder="Phone number to be visible on your standalone website?"
          name="venue[display_phone_number]"
          defaultValue={this.formValue('display_phone_number')}
          error={this.formError('display_phone_number')}
        />

        <FieldGroup
          type="text"
          label="Extension"
          name="venue[extension]"
          defaultValue={this.formValue('extension')}
          error={this.formError('extension')}
        />

        <FieldGroup
          type="text"
          label="Address 1"
          name="venue[address_1]"
          defaultValue={this.formValue('address_1')}
          error={this.formError('address_1')}
        />

        <FieldGroup
          type="text"
          label="Address 2"
          name="venue[address_2]"
          defaultValue={this.formValue('address_2')}
          error={this.formError('address_2')}
        />

        <FieldGroup
          type="select"
          options={this.includedData('cities')}
          label="City"
          name="venue[city_id]"
          defaultValue={this.formValue('city_id')}
          error={this.formError('city')}
        />

        <FieldGroup
          type="text"
          label="Postcode"
          name="venue[postcode]"
          defaultValue={this.formValue('postcode')}
          error={this.formError('postcode')}
        />

        <FieldGroup
          type="select"
          options={this.includedData('split_payment_options')}
          label="Split payment schedule"
          name="venue[split_payment_option_id]"
          defaultValue={this.formValue('split_payment_option_id')}
          error={this.formError('split_payment_option_id')}
        />

        <FieldGroup
          type="input"
          label="Deposit (%) payable on booking"
          name="venue[preorder_first_part_percent]"
          defaultValue={this.formValue('preorder_first_part_percent')}
          error={this.formError('preorder_first_part_percent')}
        />

        <FieldGroup
          type="input"
          options={this.includedData('split_payment_options')}
          label="Balance due x days before event"
          name="venue[preorder_second_part_due_days]"
          defaultValue={this.formValue('preorder_second_part_due_days')}
          error={this.formError('preorder_second_part_due_days')}
        />

        <FieldGroup
          type="select"
          options={this.includedData('local_authorities')}
          label="Local authority"
          name="venue[local_authority_id]"
          defaultValue={this.formValue('local_authority_id')}
          error={this.formError('local_authority')}
        />

        <FieldGroup
          type="textarea"
          label="Description"
          name="venue[description]"
          rows={8}
          defaultValue={this.formValue('description')}
          error={this.formError('description')}
        />

        <FieldGroup
          type="file"
          label="Logo"
          name="venue[logo]"
          error={this.formError('logo')}
        />

        {logo &&
          <div>
            <img src={logo.url} />
          </div>
        }

        <Can I="editStandaloneWebsite" a="AdminVenues">
          <FieldGroup
            type="checkbox"
            value={true}
            placeholder="Stand-alone Website"
            name="venue[standalone_website]"
            defaultValue={this.formValue('standalone_website')}
            error={this.formError('standalone_website')}
            onlyAdmin={true}
          />
        </Can>

        <Can I="editSlug" a="AdminVenues">
          <FieldGroup
            label="Unique identifier"
            name="venue[slug]"
            defaultValue={this.formValue('slug')}
            error={this.formError('slug')}
            onlyAdmin={true}
          />
        </Can>

        <Can I="editSubdomain" a="AdminVenues">
          <FieldGroup
            label="Unique identifier standalone"
            name="venue[subdomain]"
            defaultValue={this.formValue('subdomain')}
            error={this.formError('subdomain')}
            onlyAdmin={true}
          />
        </Can>


        <Can I="editSHCommission" a="AdminVenues">
          <FieldGroup
            label="SH commission (%)"
            name="venue[commission_percent]"
            defaultValue={this.formValue('commission_percent')}
            error={this.formError('commission_percent')}
            onlyAdmin={true}
          />
        </Can>
      </Fragment>
    );
  }
}
