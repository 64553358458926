import React, { Component } from 'react';
import PaymentsTable from '../payments/table';

export default class Payments extends Component {
  render() {
    const { data } = this.props;

    if (!(Array.isArray(data) && data.length > 0)) return null;

    return (
      <div className="section">
        <div className="section-header">
          <h3>Payments</h3>
        </div>

        <PaymentsTable items={data}/>
      </div>
    );
  }
}
