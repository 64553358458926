import React, { Component } from 'react';

export default class BillVenueAddress extends Component {
  render() {
    const { activity } = this.props;

    if (!activity) return null;

    const { address_1, city_title, postcode } = activity;

    return (
      <div className="">
        <strong>ADDRESS</strong>
        <table className="table-jsmall">
          <tbody>
          <tr><td>{address_1}</td></tr>
          <tr><td>{city_title}</td></tr>
          <tr><td>{postcode}</td></tr>
          </tbody>
        </table>
        <div className="clearfix"/>
      </div>
    );
  }
}
