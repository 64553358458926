import React, { Fragment } from 'react';
import SmartBlock from '../../smart/block';
import Modal from './modal';
import { Button } from 'react-bootstrap';
import BookingOptionItemModal from '../booking_option_items/modal';
import HeaderConfig from './header_config';
import Table from './table';

export default class Block extends SmartBlock {
  constructor(props) {
    super(props);

    this.state = {
      status: 'active'
    }
  }

  handleDisable = (id) =>  {
    this.handleAction('Disable', { id: id });
  };

  handleEnable = (id) => {
    this.handleAction('Enable', { id: id });
  };

  render() {
    const { status } = this.state;

    return (
      <Fragment>
        <Modal {...this.props} />

        <BookingOptionItemModal
          title='Booking Option Item'
          name='BookingOptionItem'
          path={`${window.location.pathname}/booking_option_items`}
        />

        <div className="panel panel-default panel-block">
          <div className="panel-heading">
            <h3 className="pull-left">Booking options</h3>

            <HeaderConfig value={status} onChange={(val) => this.setState({ status: val })}/>

            <div className="btn-group pull-right">
              <Button bsStyle="primary" onClick={this.handleNew}>
                Add booking option
              </Button>
            </div>
            <div className="clearfix"/>
          </div>
          <div className="panel-body panel-body-with-table">
            <div className="table-box">
              <Table
                status={status}
                data={this.state.data}
                onEdit={this.handleEdit}
                onDelete={this.handleDelete}
                onDisable={this.handleDisable}
                onEnable={this.handleEnable}
              />
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}

