import React, { Component, Fragment } from 'react';
import BookingShow from '../../bookings/show';
import ActionsCell from "./actions_cell";
import MessageModal from "../../messages/modal";

const HeaderInfo = () => (
  <Fragment>
    <p>You will find all the relevant details in relation to the booking below. This includes:</p>

    <ul>
      <li>a detailed order summary;</li>
      <li>payment information; and</li>
      <li>all the answers to your venue questions.</li>
    </ul>

    <p/>

    <p>
      If you require any further information, please message the venue directly.
    </p>
  </Fragment>
);

export default class Show extends Component {
  renderActions(row) {
    return (
      <div className="vert-offset-bottom-10">
        <ActionsCell hideViewBooking={true} row={row} pullLeft={true}/>
      </div>
    )
  }

  render() {
    return (
      <Fragment>
        <MessageModal
          name='Message'
          path='/account/messages'
          title="Message"
        />

        <BookingShow
          headerInfo={<HeaderInfo/>}
          url={`${window.location.pathname}.json`}
          renderActions={this.renderActions}
        />
      </Fragment>
    )
  }
}
