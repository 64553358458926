import React from 'react';
import BillTotals from './bill_totals';
import BookingItem from './booking_item';
import PaymentOptions from './payment_options';
import PromoCode from './promo_code';
import Modal from './modal';
import AttendeeModal from './attendee/modal';

export default ({ items,
                  billData,
                  onRemoveItem,
                  onEditItem,
                  onEditAttendeeItem,
                  onUpdate,
                  extendedList,
                  internalNotes,
                  selectedScheduleItemIds }) => {

  if (items === null) return null;

  const { status } = billData;

  return (
    <div>
      {status === 'pending' &&
        <>
          <Modal
            path={'admin/booking_items'}
            title="Booking Item"
            name="BookingItem"
          />

          <AttendeeModal
            path={'admin/booking_items'}
            title="Booking Item Attendee"
            name="BookingItemAttendee"
          />
        </>
      }

      <div className="table-block">
        <table className="table table-bordered-2">
          <thead>
          <tr>
            <th scope="col">Attendee</th>
            <th scope="col">DoB</th>
            <th scope="col">Activity</th>
            <th scope="col">Booking option</th>
            <th scope="col">Start date</th>
            <th scope="col">Price</th>
            <th scope="col"/>
            <th scope="col"/>
          </tr>
          </thead>
          {items.map((item) =>
            <BookingItem
              key={item.id}
              onRemoveItem={onRemoveItem}
              onEditItem={onEditItem}
              onEditAttendeeItem={onEditAttendeeItem}
              extendedList={extendedList}
              internalNotes={internalNotes}
              selectedScheduleItemIds={selectedScheduleItemIds}
              {...item}
            />
          )}
        </table>
      </div>
      <div className="row vert-offset-bottom-10">
        <div className="col-md-6">
        </div>
        <div className="col-md-6">
          {!internalNotes &&
          <BillTotals bill={billData}/>
          }
        </div>
      </div>

      {!internalNotes &&
      <>
        <div className="row">
          <div className="col-lg-12">
            <PaymentOptions bill={billData} onUpdate={onUpdate}/>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <PromoCode bill={billData} onUpdate={onUpdate}/>
          </div>
        </div>
      </>
      }
    </div>
  );
}
