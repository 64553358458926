import React, { Fragment } from 'react';
import SmartModal from '../../smart/modal';
import FieldGroup from '../../smart/field_group';
import { UserContext } from '../../user';
import axios from 'axios';
import PubSub from "pubsub-js";

export default class SignUpModal extends SmartModal {
  showSignUp = () => { this.setState({ loading: false }) };

  renderSignUpForm = () => {
    const { data } = this.state;
    const { role } = data || {};

    let TCLabel = (
      <div className="text-center">
        By signing up, I agree to SchoolHire&nbsp;
        <a target="_blank" href="/terms_and_conditions">Terms &amp; Conditions</a>
        &nbsp;and&nbsp;
        <a target="_blank" href="/privacy_policy">Privacy Policy</a>
      </div>
    );

    let formRole = role || 'user';

    return (
      <div style={{ width: '70%', margin: '0 auto' }}>
        <input defaultValue={formRole} type="hidden" name="user[role]"/>

        <FieldGroup
          placeholder="Name *"
          required="required"
          type="text"
          name="user[name]"
          defaultValue={this.formValue("name")}
          error={this.formError("name")}
        />

        {formRole === 'user' &&
        <Fragment>
          <FieldGroup
            placeholder="Organisation (if applicable)"
            type="text"
            name="user[organisation]"
            defaultValue={this.formValue("organisation")}
            error={this.formError("organisation")}
          />

          <FieldGroup
            placeholder="Role in organisation (if applicable)"
            type="text"
            name="user[role_in_organisation]"
            defaultValue={this.formValue("role_in_organisation")}
            error={this.formError("role_in_organisation")}
          />

          <FieldGroup
            placeholder="Address *"
            required="required"
            type="text"
            name="user[address]"
            defaultValue={this.formValue("address")}
            error={this.formError("address")}
          />

          <FieldGroup
            placeholder="Postcode *"
            type="text"
            name="user[postcode]"
            defaultValue={this.formValue("postcode")}
            error={this.formError("postcode")}
          />
        </Fragment>
        }

        {formRole === 'venue_admin' &&
        <Fragment>
          <FieldGroup
            placeholder="Organisation *"
            type="text"
            required={true}
            name="user[organisation]"
            defaultValue={this.formValue("organisation")}
            error={this.formError("organisation")}
          />

          <FieldGroup
            placeholder="Role in organisation *"
            type="text"
            required={true}
            name="user[role_in_organisation]"
            defaultValue={this.formValue("role_in_organisation")}
            error={this.formError("role_in_organisation")}
          />
        </Fragment>
        }

        <FieldGroup
          placeholder="Phone number *"
          required="required"
          type="text"
          name="user[phone]"
          defaultValue={this.formValue("phone")}
          error={this.formError("phone")}
        />

        <FieldGroup
          placeholder="Email *"
          required="required"
          type="email"
          name="user[email]"
          defaultValue={this.formValue("email")}
          error={this.formError("email")}
        />

        <FieldGroup
          placeholder="Password *"
          required="required"
          type="password"
          name="user[password]"
          defaultValue={this.formValue("password")}
          error={this.formError("password")}
        />

        <span className="help-block">
          Password requirements: minimum of 8 characters, with at least 1 upper case letter,
          1 lower case letter and 1 number
        </span>

        <FieldGroup
          placeholder="Password confirmation *"
          required="required"
          type="password"
          name="user[password_confirmation]"
          defaultValue={this.formValue("password_confirmation")}
          error={this.formError("password_confirmation")}
        />

        <FieldGroup
          placeholder={TCLabel}
          required="required"
          type="checkbox"
          name="user[terms_and_conditions]"
          defaultValue={false}
          value={true}
          error={this.formError("terms_and_conditions")}
        />
      </div>
    );
  };

  renderSignUpButtons = () => {
    return (
      <UserContext.Consumer>
        {user => (
          <div className="form">
            <div className="sbm">
              <input type="button" name="commit" onClick={() => this.applySignUp(user)} defaultValue="Create Account"/>
            </div>
          </div>
        )}
      </UserContext.Consumer>
    )
  };

  applySignUp = (user) => {
    axios
      .post('/users.json', new FormData(this.formRef.current))
      .then((response) => {
        this.handleClose();
        PubSub.publish(`${this.props.name}.SignedUp`, {});
        user.change(response.data.data);

        if (this.state.data.showMessageModal) PubSub.publish('Message.ShowModal');
      })
      .catch((error) => {
        console.log(error);
        this.setState({ formErrors: error.response.data.errors });
      });
  }
}

