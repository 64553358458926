import React from 'react';
import SmartModal from '../smart/modal';
import FieldGroup from '../smart/field_group';
import { Button } from 'react-bootstrap';
import axios from "axios";
import PubSub from "pubsub-js";
import SpinnerModal from "../spinner_modal";

function uniqInterlocutors(data) {
  return Object.values(data.reduce((r, e) => (r[e.type + '|' + e.id] = e, r), {}))
}

export default class Modal extends SmartModal {
  constructor(props) {
    super(props);

    this.state = {
      modalStyle: undefined,
      show: false,
      data: {},
      included: {},
      formData: {},
      formErrors: {},
      loading: true,
      showSpinner: false
    };

    this.formRef = React.createRef();
  }

  getInbox() {
    return this.state.data.inbox;
  }

  getInterlocutors() {
    const { interlocutors } = this.state.data;

    return (
      uniqInterlocutors(interlocutors)
        .filter(item => (item.type === 'User' && !item.schoolhire))
        .map(item => { return { value: item.id, label: item.name } })
    )
  }

  renderForm() {
    const inbox = this.getInbox();
    const interlocutors = this.getInterlocutors();

    return (
      <div>
        <input type="hidden" name="inbox_type" value={inbox.type}/>
        <input type="hidden" name="inbox_id" value={inbox.id}/>
        <input type="hidden" name="interlocutor_type" value="User"/>

        <FieldGroup
          label="To:"
          type="select2"
          options={interlocutors}
          defaultValue={interlocutors}
          isMulti={true}
          required={true}
          name="interlocutor_id"
        />

        <FieldGroup
          label="Message:"
          type="textarea"
          required={true}
          name="message[body]"
          rows={5}
          error={this.formError("body")}
        />

        <FieldGroup
          label="Attachment:"
          type="file"
          name="message[attachment_attributes][attachment]"
        />
      </div>
    )
  }

  applyNew = () => {
    const { path, name } = this.props;
    let data = this.getData();
    const config = { headers: { 'content-type': 'multipart/form-data' } };

    this.setState({ showSpinner: true, formErrors: [] })

    axios
      .post(path, data, config)
      .then((response) => {
        this.handleClose();
        PubSub.publish(`${name}.Created`, {});
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      })
      .finally(() => {
        this.setState({ showSpinner: false })
      });
  };

  handleCloseSpinner() {
    this.setState({ showSpinner: false})
  }

  renderButtons() {
    const { showSpinner } = this.state;

    return (
      <div>
        <Button className='btn btn-default' onClick={this.handleClose}>Cancel</Button>
        <Button className='btn btn-primary' onClick={this.handlePerform}>Send</Button>

        <SpinnerModal show={showSpinner}/>
      </div>
    )
  }
}
