import FieldGroup from "../../../smart/field_group";
import React, { useState, useEffect } from "react";
import Fetch from "../../fetch";

export default ({ onReady, onChange }) => {
  const [categories, setCategories] = useState(null);

  useEffect(() => { Fetch.activityCategories().then((response) => setCategories(response.data.data)) }, []);
  useEffect(() => { if (categories !== null) { onReady() } }, [categories])

  return (
    <FieldGroup
      label="Categories:"
      type="select"
      className={'activity-category-input'}
      emptyOption={{ id: '', title: 'All' }}
      options={categories}
      name="activity_category_id"
      onChange={onChange}
    />
  )
}