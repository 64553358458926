import React, { Component } from 'react';
import Page from '../page';
import Info from './info';
import Modal from './modal';
import axios from 'axios';
import PubSub from 'pubsub-js';
import { EditButton } from "./buttons";
import EditCardButton from './EditCardButton';

class LeftSide extends Component {
  render() {
    return (
      <div className="btn-toolbar">
        <EditCardButton />
        <EditButton title="Edit"/>
      </div>
    )
  }
}

export default class Show extends Component {
  constructor(props) {
    super(props);

    this.state = { data: undefined }
  }

  componentDidMount() {
    PubSub.subscribe('Profile.Updated', this.fetchData);
    this.fetchData();
  }

  fetchData = () => {
    axios
      .get('/account/profile.json')
      .then(response => {
        const { data, included } = response.data;
        this.setState({ data, included});
      })
      .catch(error => console.log(error));
  };

  render() {
    const { data } = this.state;

    if (!data) return null;

    return (
      <div>
        <Modal title="Profile" path="/account/profile" name="Profile"/>

        <Page.Header leftSide={<LeftSide/>}>
          Profile
        </Page.Header>

        <Info {...data}/>
      </div>
    );
  }
}
