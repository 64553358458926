import React, { Component } from 'react';

export default class Documents extends Component {
  render() {
    const { data } = this.props;

    return (
      <div className="section">
        <div className="section-header">
          <h3>Terms and Conditions</h3>
        </div>
        <p>
          As part of proceeding with the booking request, the user has confirmed that they
          have read and agreed with all the terms and conditions within the document(s) below
        </p>
        <div className="vert-offset-bottom-15"/>

        {data.map((item, index) =>
          <div key={index} className="vert-offset-bottom-10">
            <a className="terms_and_conditions_document_link" target="_blank" href={item.file.url}>
              {item.title}
            </a>
          </div>
        )}
      </div>
    );
  }
}