import React, { Component } from 'react';
import ReactTable from "react-table";
import axios from 'axios';
import Header from './table/header';
import PaginationComponent from './table/PaginationComponent';

export default class SmartTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      pages: null,
      loading: true,
      pageSize: 20,
      sorted: undefined,
      page: undefined,
      filter: {}
    };

    this.myRef = React.createRef();
  }

  componentWillMount() {
    const { name } = this.props;
    PubSub.subscribe(name, this.receiveMessage);
  }

  receiveMessage = (message) => {
    let updatedItemActions = ['Created', 'Updated', 'Deleted'];
    let action = message.split('.')[1];

    if (updatedItemActions.includes(action)) this.fetchData()
  };

  onChangeSearch = (filter) => {
    const { onChangeSearch } = this.props;

    this.setState({ filter }, () => {
      if (onChangeSearch) onChangeSearch(filter);
      this.fetchData({ pare: 0, sorted: this.state.sorted});
    });
  };

  fetchData = (props) => {
    let { pageSize, filter } = this.state;
    let { page, sorted } = props || this.state;

    if (props) this.setState({ page, sorted });

    this
      .requestData(pageSize, page, sorted, filter)
      .then(response => {
        let res = response.data;
        this.setState({ data: res.data, pages: res.pages, loading: false });
      });
  };

  requestData(pageSize, page, sorted, filter) {
    const { dataPath } = this.props;

    return new axios.get(dataPath, {
      params: {
        per_page: pageSize,
        page: page,
        sorted: sorted,
        filter: filter
      },
      paramsSerializer: (params) => jQuery.param(params)
    })
  }

  renderHeader() {
    let { showHeader, TopLeft }  = this.props;
    if (showHeader === undefined) { showHeader = true }

    if (!showHeader) return null;

    return <Header TopLeft={TopLeft} onChangeSearch={this.onChangeSearch} />;
  }

  handleChangeSorted = (sorted) => this.setState({ sorted });

  render() {
    const { data, pages, loading, pageSize } = this.state;
    let { TableLeftSide, columns, expanded, defaultSorted, SubComponent }  = this.props;
    const page = this.state.page || 0;

    let colClasses = ['hidden', 'col-lg-12'];

    if (TableLeftSide) colClasses = ['col-lg-2 col-md-3', 'col-lg-10 col-md-9'];

    return (
      <div className="smart-table vert-offset-bottom-10">
        {this.renderHeader()}

        <div className="row">
          {TableLeftSide &&
          <div className={colClasses[0]}>{/*style={{ paddingRight: 0 }}>*/}
            <TableLeftSide onChangeSearch={this.onChangeSearch}/>
          </div>
          }
          <div className={colClasses[1]}>
            <ReactTable
              ref={this.myRef}
              data={data}
              page={page}
              pages={pages}
              loading={loading}
              manual
              resizable={false}
              multiSort={false}
              columns={columns}
              expanded={expanded}
              defaultSorted={defaultSorted}
              SubComponent={SubComponent}
              showPageSizeOptions={false}
              // showPageJump={false}
              defaultPageSize={pageSize}
              onFetchData={this.fetchData}
              onSortedChange={this.handleChangeSorted}
              className="-striped -highlight"
              PaginationComponent={PaginationComponent}
            />
          </div>
        </div>
      </div>
    );
  }
}