import React, { Component } from 'react';
import ActivityCategory from '../activity_categories/item';
import axios from 'axios';

function getActivityCategories() {
  const params = new URLSearchParams();
  params.append('filter[visible_on_homepage]', true);
  return axios.get('/activity_categories.json', { params: params });
}

export default class ActivityCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    getActivityCategories()
      .then((response) => {
        const { data } = response.data;
        this.setState({ activity_categories: data })
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const { activity_categories } = this.state;

    if (activity_categories === undefined) return null;

    return (
      <div id="top_list">
        <div className="container">
          <h3>Explore our top activities</h3>
          <div className="row">
            {activity_categories.map((item, index) =>
              <div className="col-sm-4" key={index}>
                <ActivityCategory {...item}/>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
