import React, { Fragment } from 'react';
import AreasInfo from '../areas/info';
import Modal from './modal';
import { Can } from '../../ability_context';
import Nl2Br from "../../smart/nl2br";

export default class Info extends AreasInfo {
  render() {
    const { data } = this.state;
    if (data === undefined) return null;

    const {
      activity_category,
      internal_reference,
      places,
      own_canonical_link,
      description,
      venue_address_enabled,
      venue
    } = data;

    const {
      address_1,
      city,
      postcode,
      coordinates_detected,
      latitude,
      longitude
    } = (venue_address_enabled ? venue : data);

    return(
      <Fragment>
        <Modal title='Activity' name="Activity" path="/admin/activities"/>

        <div className="row">
          <div className="col-lg-5 col-sm-12">
            <dl className="dl-horizontal">
              <dt>Venue:</dt>
              <dd><a href={`/admin/venues/${venue.id}`}>{venue.title}</a></dd>

              <dt>Activity Category:</dt>
              <dd>{(activity_category || {}).title}</dd>

              <dt>Internal reference:</dt>
              <dd>{internal_reference}</dd>

              <dt>Address 1:</dt>
              <dd>{address_1}</dd>

              <dt>City:</dt>
              <dd>{(city || {}).title}</dd>

              <dt>Postcode:</dt>
              <dd>{postcode}</dd>

              {coordinates_detected &&
                <Fragment>
                  <dt>Coordinates:</dt>
                  <dd>
                    <a href={`http://www.google.com/maps/place/${latitude},${longitude}`} target="_blank">
                      [{latitude}, {longitude}]
                    </a>
                  </dd>
                </Fragment>
              }

              <dt>Number of spaces:</dt>
              <dd>{places}</dd>

              <Can I="seeOwnCanonicalLink" a="AdminActivities">
                <div>
                  <dt>Own canonical link:</dt>
                  <dd>{own_canonical_link ? 'Yes' : 'No'}</dd>
                </div>
              </Can>
            </dl>
          </div>
          <div className="col-lg-7 col-xs-12">
            <div className="pull-right">
              <div className="btn-group btn-group-xs">
                <a className='btn btn-primary' target="_blank" href="#" onClick={this.onPreviewClick}>Preview</a>
                <a className="btn btn-primary" href="#" onClick={this.onEditClick}>Edit</a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 col-sm-12">
            <dl className="dl-horizontal">
              <dt>Details:</dt>
              <dd><Nl2Br text={description}/></dd>
            </dl>
          </div>
        </div>
      </Fragment>
    )  
  }
}