import React from 'react';
import SmartBlock from "../../smart/block";
import FieldGroup from "../../smart/field_group";
import axios from 'axios';

export default class Block extends SmartBlock {
  constructor(props) {
    super(props);

    let extra = { formErrors: [] };
    this.state = { ...this.state, ...extra } ;

    this.formRef = React.createRef();

    this.updateData = this.updateData.bind(this);
    this.updateVatRegistered = this.updateVatRegistered.bind(this);
  }

  updateData() {
    const { path } = this.props;

    let data = new FormData(this.formRef.current);

    axios
      .put(`${path}.json`, data)
      .then((response) => {
        this.setState({ formErrors: [] });
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  }

  formError(name) {
    const { formErrors } = this.state;
    if (formErrors === undefined) return null;
    return (name in formErrors) ? formErrors[name] : null;
  }

  updateVatRegistered() {
    let { data } = this.state;
    data.vat_registered = !data.vat_registered;
    this.setState({ data }, () => this.updateData())
  };

  handleChangeArea = () => {
    let { data } = this.state;
    data.venue_vat = !data.venue_vat;
    this.setState({ data }, () => this.updateData())
  };

  renderForm() {
    const { data } = this.state;
    if (data === undefined) return null;

    let currentData = data.venue_vat ? data.venue : data;

    return (
      <div style={{ padding: 15 }}>
        <div className="m-f">
          <FieldGroup
            type="checkbox"
            value="true"
            placeholder="VAT registered"
            disabled={data.venue_vat}
            checked={currentData.vat_registered}
            name="area[vat_registered]"
            onChange={this.updateVatRegistered}
          />
        </div>
        {data.vat_registered &&
        <div className="vat-field">
          {!data.venue_vat &&
          <FieldGroup
            label="VAT (%)"
            defaultValue={currentData.vat}
            required={true}
            disabled={data.venue_vat}
            type="debounce-text"
            name="area[vat]"
            onChange={this.updateData}
            error={this.formError('vat')}
          />
          }

          {data.venue_vat &&
          <FieldGroup
            label="VAT (%)"
            defaultValue={data.venue.vat_registered ? data.venue.vat : 0}
            required={true}
            disabled={true}
            type="text"
            name="area[vat]"
          />
          }
        </div>
        }
      </div>
    )
  }

  renderHeaderConfiguration() {
    const { data } = this.state;

    if (data === []) return null;
    if (data.type !== 'Activity') return null;

    let venueVatLabel = `${data.venue.vat}%`;

    if (!data.venue.vat_registered) {
      venueVatLabel = 'not registered';
    }

    return (
      <div className="price-radio-button">
        <FieldGroup
          type="radio-group"
          name="area[venue_vat]"
          defaultValue={data.venue_vat}
          options={[
            { id: true, title: `Activity has the same VAT settings as the venue (${venueVatLabel})` },
            { id: false, title: "Activity has its own VAT settings" },
          ]}
          onChange={this.handleChangeArea}
        />
      </div>
    )
  }

  render() {
    return (
      <div>
        <form ref={this.formRef}>
          <div className="panel panel-default panel-block">
            <div className="panel-heading">
              <h3 className="pull-left">VAT</h3>

              {this.renderHeaderConfiguration()}

              <div className="clearfix"/>
            </div>
            <div className="panel-body panel-body-with-table">
              {this.renderForm()}
            </div>
          </div>
        </form>
      </div>
    )
  }
}

