import React, { Component } from 'react';
import TimePicker from 'rc-time-picker';

export default class TimePickerInput extends Component {
  render() {
    const { name } = this.props;

    return (
      <TimePicker
        name={name}
        showSecond={false}
        inputReadOnly
        minuteStep={5}
      />
    );
  }
}