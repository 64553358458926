import React, { Component } from 'react';
import axios from 'axios';
import Standard from './standard/page';
import Offline from './offline/page';

export default class Index extends Component {
  constructor() {
    super();

    this.state = {
      loading: true,
      data: null,
      included: null
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { bookingId } = this.props;

    axios
      .get(`/bookings/${bookingId}/checkout/detailed_summary.json`)
      .then(response => {
        const { data, included } = response.data;
        this.props.onUpdatedTimer(included.timer)
        this.setState({ loading: false, data, included});
      })
      .catch(error => console.log(error));
  };

  handleSuccess = () => {
    const { included } = this.state;
    const createdByVenue = included.booking.data.created_by_venue;

    window.location = createdByVenue ? '/admin/bookings' : '/account/bookings';
  };

  render() {
    const { loading, data, included } = this.state;
    const { onBack } = this.props;

    if (loading) return null;

    const booking = included.booking.data;
    const bill = included.bill.data;
    const amount = included.amount;

    const params = {
      data,
      booking,
      bill,
      amount: amount,
      bookingId: booking.id,
      onBack: onBack,
      onSuccess: this.handleSuccess,
      paymentPath: `/bookings/${booking.id}/payment.json`
    };

    return (booking.payment_option_uid === 'offline_payment') ? <Offline {...params}/> :  <Standard {...params}/>;
  }
}
