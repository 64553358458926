import React from 'react';
import Item from "./item";

export default ({ data, onEdit, onDelete, onDisable, onEnable }) => {
  if (!(Array.isArray(data) && data.length > 0)) return null;

  return (
    <table className="table table-striped table-bordered table-xs">
      <thead>
      <tr>
        <th>Title</th>
        <th>Type</th>
        <th>Amount</th>
        <th>Apply discounts</th>
        <th style={{width: 100}}></th>
      </tr>
      </thead>
      <tbody>

      {data.map((item, index) =>
        <Item
          key={index}
          {...item}
          onEdit={onEdit}
          onDelete={onDelete}
          onDisable={onDisable}
          onEnable={onEnable}
        />
      )}
      </tbody>
    </table>
  );
}