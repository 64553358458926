import React, { Component, Fragment } from 'react';
import DataItem from './data_item';

export default class BankDetails extends Component {
  render() {
    const { data } =  this.props;

    if (!data) return null;

    const {
      invoice_local_authority,
      company_registration_number,
      charity_number,
      bank_account_name,
      bank_account_sortcode,
      bank_account_number
    } = data;

    return (
      <Fragment>
        <ul className="invoice-list">
          <DataItem title="Registered office">{invoice_local_authority}</DataItem>
          <DataItem title="Company registration number">{company_registration_number}</DataItem>
          <DataItem title="Charity number">{charity_number}</DataItem>
          <br />
          <DataItem title="Bank account name">{bank_account_name}</DataItem>
          <DataItem title="Bank account sortcode">{bank_account_sortcode}</DataItem>
          <DataItem title="Bank account number">{bank_account_number}</DataItem>
        </ul>
      </Fragment>
    )
  }
}
