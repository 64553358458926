import React, { Component } from 'react';
import Select from 'react-select';

export default class Activities extends Component {
  handleChange = (selectedOption) => {
    const { onChange } = this.props;
    onChange(selectedOption);
  };

  render() {
    const { activities, activity } = this.props;

    return (
      <div className="section">
        <h4>Choose activity</h4>

        <Select
          name="form-field-name"
          value={activity}
          onChange={this.handleChange}
          getOptionLabel={(option) => option.internal_reference }
          getOptionValue={(option) => option.id }
          clearable={false}
          options={activities}
        />
      </div>
    );
  }
}