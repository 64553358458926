import React, { Component } from 'react';
import moment from 'moment';
import Caption from './date_picker_input_caption';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate } from 'react-day-picker/moment';

export default class DatePickerInput extends Component {
  constructor(props) {
    super(props);

    this.fromMonth = (props.fromMonth || moment().subtract(10, 'years').toDate());
    this.toMonth = (props.toMonth || moment().add(10, 'years').toDate());

    this.state = {
      selectedDay: props.defaultValue,
      month: props.month,
      isDisabled: false
    };
  }

  handleDayChange = (selectedDay, modifiers) => {
    const { onChange, onRealChange } = this.props;

    this.setState({ selectedDay, isDisabled: modifiers.disabled === true}, () => {
      if (onChange) { onChange(selectedDay) };
      if (onRealChange) { onRealChange({ target: { value: selectedDay }}) }
    });
  };

  handleChangeMonth = (month) => {
    this.setState({ month })
  };

  render() {
    const { selectedDay, month } = this.state;
    const { format, name } = this.props;
    let selectedDayValue = selectedDay;

    if (selectedDay === undefined) selectedDayValue = '';

    return (
      <div className="day-picker-box">
        <DayPickerInput
          value={selectedDay}
          month={month}
          onDayChange={this.handleDayChange}
          format={format || "ddd, D MMM YYYY"}
          formatDate={formatDate}
          locale='en-gb'
          placeholder=''
          inputProps={{ className: 'form-control' }}
          dayPickerProps={{
            fromMonth: this.fromMonth,
            toMonth: this.toMonth,
            month: month,
            onMonthChange: this.handleChangeMonth,
            selectedDays: selectedDay,
            locale: 'en-gb',
            localeUtils: MomentLocaleUtils,
            captionElement: (
              <Caption
                month={month}
                fromMonth={this.fromMonth}
                toMonth={this.toMonth}
                onChangeMonth={this.handleChangeMonth}
              />
            )
          }}
        />
        <input name={name} type='hidden' value={selectedDayValue} readOnly/>
      </div>
    );
  }
}
