import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';

export default class Select extends Component {
  constructor(props) {
    super(props);

    this.titleKey = props.titleKey || 'title';
    this.valueKey = props.valueKey || 'id';
    this.defaultValue = props.defaultValue;

    this.renderOptions = this.renderOptions.bind(this);
  }

  renderEmptyOption(option) {
    if (typeof option !== 'object') return null;
    return <option value="">{option[this.titleKey]}</option>;
  }

  renderOptions(options) {
    if (typeof options !== 'object') return null;
    return (
      options.map((option, index) =>
        <option key={index} value={option[this.valueKey]}>{option[this.titleKey]}</option>
      )
    );
  }

  render() {
    // Do not remove titleKey, valueKey
    const { emptyOption, options, titleKey, valueKey, firstIfOne, ...props } = this.props;
    let showEmpty = true;

    if (!options) return <FormControl/>;

    if (options.length === 1 && firstIfOne) { showEmpty = false };

    return (
      <FormControl componentClass="select" {...props}>
        {showEmpty && this.renderEmptyOption(emptyOption)}
        {this.renderOptions(options)}
      </FormControl>
    );
  }
}