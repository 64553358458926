import React from 'react';
import SmartModal from '../../smart/modal';
import axios from 'axios';

export default class Modal extends SmartModal {
  constructor(props) {
    super(props);

    this.applyEnable = this.applyEnable.bind(this);
    this.applyDisable = this.applyDisable.bind(this);
  }

  applyEnable() {
    const { path, name } = this.props;
    const { data } = this.state;

    axios
      .put(`${path}/${data.id}/enable.json`)
      .then((response) => {
        PubSub.publish(`${name}.Updated`, {});
      })
      .catch((error) => {
        console.log('error');
      });
  }

  applyDisable() {
    const { path, name } = this.props;
    const { data } = this.state;

    axios
      .put(`${path}/${data.id}/disable.json`)
      .then((response) => {
        PubSub.publish(`${name}.Updated`, {});
      })
      .catch((error) => {
        console.log('error');
      });
  }

  renderForm() {
    return (
      <div>
        <SmartModal.FieldGroup
          type="text"
          label="Code"
          name="promo_code[code]"
          defaultValue={this.formValue('code')}
          error={this.formError('code')}
        />
        <SmartModal.FieldGroup
          type="date"
          label="Expiration date"
          name="promo_code[expired_at]"
          defaultValue={this.formValue('expired_at')}
          error={this.formError('expired_at')}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Percent"
          name="promo_code[percent]"
          defaultValue={this.formValue('percent')}
          error={this.formError('percent')}
        />
      </div>
    );
  }
}
