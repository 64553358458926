import React, { Component } from 'react';
import Block from "./block";
import Page from '../page';

export default class Index extends Component {
  render() {
    return (
      <div>
        <Page.Header leftSide={<Page.NewButton title="Add city" name="City"/>}>
          Cities
        </Page.Header>

        <Block title="City" path="/admin/cities" name="City"/>
      </div>
    );
  }
}
