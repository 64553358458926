import React, { Component } from 'react';
import FieldGroup from '../smart/field_group';
import moment from 'moment';

export default class Attendee extends Component {
  formError(name) {
    const { errors } = this.props;
    if (errors === undefined) return null;
    return (name in errors) ? errors[name] : null;
  }

  render() {
    const { changeName, changeBirthday, removeAttendee, attendees, index } = this.props;

    return (
      <div className="row attendee">
        <div className="col-lg-4">
          <FieldGroup
            label="Attendee name:"
            onChange={(e) => changeName(e.target.value, index)}
            error={this.formError("name")}
          />
        </div>
        <div className="col-lg-6">
          <FieldGroup
            label="Date of birth:"
            onChange={(value) => changeBirthday(value, index)}
            format="DD MMM YYYY"
            type="birth"
            error={this.formError("birthday")}
          />
        </div>
        <div className="col-lg-2">
          {attendees.length > 1 && <a className="btn-action btn-remove" onClick={(e) => removeAttendee(e, index)}/>}
        </div>
      </div>
    );
  }
}
