import React, { Component, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import BookingItems from "../../booking_items/table";
import SplitPaymentsTable from "../../split_payments/table";
import { BackButton } from "../step_buttons";

export default class Page extends Component {
  render() {
    const { onBack, bill, description, bookButton } = this.props;

    const bookingItems = bill.booking_items.data;
    const splitPayments = bill.split_payments.data;
    const splitPaymentsIncluded = bill.split_payments.included;

    return (
      <div>
        <BookingItems bill={{uuid: 0}} billData={bill} items={bookingItems} showActions={false}/>

        <div className="clearfix" />

        {splitPayments && splitPayments.length > 1 &&
        <div className="section">
          <div className="section-header">
            <h3>Payment Schedule</h3>
          </div>

          <SplitPaymentsTable items={splitPayments} included={splitPaymentsIncluded}/>
        </div>
        }

        <div className="clearfix" />

        {description}

        <div className="vert-offset-bottom-20 vert-offset-top-20">
          <div className="btn-toolbar pull-right">
            <BackButton onClick={onBack}/>
            {bookButton}
          </div>
          <div className='clearfix'/>
        </div>
      </div>
    );
  }
}