import React from 'react';
import BookButton from './button';
import ParentPage from '../page';

const Description = () => (
  <div className="summery-text">
    <p>
      Once you request to book, the Venue will be notified and payment will be taken.
    </p>
    <p>
      Please note we only accept Visa and Mastercard as a method of payment and
      the transaction will appear as 'SchoolHire' on your bank statement.
    </p>
  </div>
);

export default (props) => (
  <ParentPage
    description={<Description/>}
    bookButton={<BookButton {...props}/>}
    {...props}
  />
);
