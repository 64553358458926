import React, { Component } from 'react';
import { ActionsCell as ParentActionsCell } from '../../smart/cells';
import PubSub from 'pubsub-js';

export default class ActionsCell extends Component {
  handleAmendBooking(row) {
    PubSub.publish('Booking.Amend', { id: row.id })
  }

  getViewBookingUrl = (row) => `/admin/bookings/${row.id}`;
  getViewInvoiceUrl = (row) => `/admin/bookings/${row.id}/invoices`;
  getInternalNotesUrl = (row) => `/admin/bookings/${row.id}/internal_notes`;

  handleViewBooking = (row) => window.location = this.getViewBookingUrl(row);
  handleViewInvoice = (row) => window.location = this.getViewInvoiceUrl(row) ;
  handleInternalNotes = (row) => window.location = this.getInternalNotesUrl(row);

  getAmendBookingHidden = (row) => !row.can_be_amended;
  getCancelBookingHidden = (row) => !row.can_be_cancelled;

  handleSendMessage(row) {
    PubSub.publish(
      'Message.New',
      {
        inbox: { type: 'Venue', id: row.venue_id },
        interlocutor: { type: 'User', id: row.user_id } //, name: row.customer_name }
      }
    )
  }

  handleCancelBooking(row) {
    PubSub.publish('Booking.Cancel', { id: row.id })
  }

  handleChangeInvoiceCategory(row) {
    PubSub.publish('Booking.ChangeInvoiceCategory', { id: row.id })
  }

  handleAttachments = (row) => {
    PubSub.publish('ListAttachments.Index',
      {
        venue_customer_id: row.venue_customer_id,
        modalTitle: `${row.customer_name} - Document(s) provided`
      }
    );
  };

  render() {
    const { row, bsSize, hideViewBooking, pullLeft } = this.props;

    return (
      <ParentActionsCell
        row={row}
        bsSize={bsSize}
        pullLeft={pullLeft}
        items={[
          { title: 'View booking',
            action: this.handleViewBooking,
            hidden: hideViewBooking,
            href: this.getViewBookingUrl
          },
          {
            title: 'Messages',
            action: this.handleSendMessage
          },
          {
            title: 'View invoice',
            action: this.handleViewInvoice,
            href: this.getViewInvoiceUrl
          },
          {
            title: 'Amend booking',
            action: this.handleAmendBooking,
            hidden: this.getAmendBookingHidden
          },
          {
            title: 'Cancel booking',
            action: this.handleCancelBooking,
            hidden: this.getCancelBookingHidden
          },
          {
            title: 'Internal notes',
            action: this.handleInternalNotes,
            href: this.getInternalNotesUrl
          },
          {
            title: 'Document manager',
            action: this.handleAttachments,
          }
        ]}
      />
    )
  }
}
