import React, { Component } from 'react';
import SmartTable from '../../smart/table';
import { ActiveCell, PublishedCell, ActionsCell } from './cells';
import Modal from './modal';
import VenueActivityCategoryModal from '../venue_activity_categories/modal';
import { IdCell, DateCell, WithTooltip } from '../../smart/cells';
import TableTopLeft from './table_top_left';
import GroupMessageModal from '../../messages/group_message_modal';
import VenueActivityCategoryLink from './venue_activity_category_link';

export default class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleVenuesColumn: true
    }
  }

  onChangeSearch = (params) => {
    this.setState({ visibleVenuesColumn: (params.venue_id === "") })
  };

  render() {
    const { visibleVenuesColumn }= this.state;

    return (
      <div>
        <Modal {...this.props}/>

        <GroupMessageModal
          path="admin/messages"
          name="GroupMessage"
          title="Message"
        />

        <VenueActivityCategoryModal
          path="admin/venue_activity_categories"
          name="VenueActivityCategory"
          title="Category"
        />

        <SmartTable
          {...this.props}
          TopLeft={TableTopLeft}
          onChangeSearch={this.onChangeSearch}
          dataPath='/admin/activities.json'
          defaultSorted={[
            { id: "activity_category_title", desc: false },
            { id: "internal_reference", desc: false }
          ]}
          columns={[
            {
              Header: "Active",
              accessor: "active",
              width: 60,
              Cell: props => <ActiveCell active={props.original.active}/>
            },
            {
              Header: "ID",
              accessor: "id",
              width: 50,
              Cell: props => <IdCell id={props.original.id}/>
            },
            {
              Header: "Venue",
              accessor: "venue_title",
              show: visibleVenuesColumn,
              Cell: props => (
                <WithTooltip>
                  <a href={`/admin/venues/${props.original.venue_id}`}>
                    {props.original.venue_title}
                  </a>
                </WithTooltip>
              )
            },
            {
              Header: "Internal reference",
              accessor: "internal_reference",
              Cell: props => (
                <WithTooltip>
                  <a href={props.original.link}>{props.original.internal_reference}</a>
                </WithTooltip>
              )
            },
            {
              Header: "Category",
              accessor: "activity_category_title",
              Cell: props => (
                <WithTooltip>
                  <VenueActivityCategoryLink {...props.original}/>
                </WithTooltip>
              )
            },
            {
              Header: "Created by",
              accessor: "creator_name",
              width: 150,
              Cell: props => (
                <WithTooltip>
                  <span>{props.original.owner.name}</span>
                </WithTooltip>
              ) 
            },
            {
              Header: "Date created",
              accessor: "created_at",
              width: 100,
              Cell: props => <DateCell date={props.value}/>
            },
            {
              Header: "Published",
              accessor: "published",
              width: 90,
              Cell: props => <PublishedCell published={props.original.published}/>
            },
            {
              Header: "Actions",
              sortable: false,
              width: 70,
              style: {overflow: 'inherit'},
              Cell: props => <ActionsCell row={props.original}/>
            }
          ]}
        />
      </div>
    );
  }
}
