import React, { Component, Fragment } from 'react';
import ReactPaginate from 'react-paginate';

export default class Paginate extends Component {
  handlePageClick = (page) => {
    const { onPageClick } = this.props;
    if (onPageClick) onPageClick(page);
  };

  render() {
    const { page, pageCount } = this.props;

    if (!Number.isInteger(pageCount)) return null;
    if (pageCount < 2) return null;

    return (
      <div className='report-pagination'>
        <ReactPaginate
          initialPage={page-1}
          previousLabel={"previous"}
          nextLabel={"next"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={this.handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          disableInitialCallback={true}
        />
      </div>
    );
  }
}
