import React, { Component } from 'react';

export default class Submenu extends Component {
  render() {
    return null;

    return (
      <ul className="maintabs_submenu">
        <li><a href="/admin/bookings">Bookings</a></li>
      </ul>
    )
  }
}