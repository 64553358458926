import React from 'react';
import SmartModal from '../../smart/modal';
import axios from 'axios';
import FieldGroup from '../../smart/field_group';

export default class Modal extends SmartModal {
  showEdit = () => {
    axios
      .get('/account/profile/edit.json')
      .then((response) => {
        const response_data = response.data;
        this.setState({
          formData: response_data.data,
          included: response_data.included,
          loading: false
        });
      })
      .catch((error) => {
        console.log(error)
      });
  };

  applyEdit = () => {
    const { name } = this.props;
    let data = this.getData();

    const config = { headers: { 'content-type': 'multipart/form-data' } };

    axios
      .put('/account/profile.json', data, config)
      .then((response) => {
        this.handleClose();
        PubSub.publish(`${name}.Updated`, {});
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  }

  renderForm() {
    return (
      <div>
        <FieldGroup
          type="text"
          label="Name"
          name="user[name]"
          defaultValue={this.formValue('name')}
          error={this.formError('name')}
        />
        <FieldGroup
          type="text"
          label="Email"
          name="user[email]"
          defaultValue={this.formValue('email')}
          error={this.formError('email')}
        />
        <FieldGroup
          type="text"
          label="Phone number"
          name="user[phone]"
          defaultValue={this.formValue('phone')}
          error={this.formError('phone')}
        />
        <FieldGroup
          type="text"
          label="Address"
          name="user[address]"
          defaultValue={this.formValue('address')}
          error={this.formError('address')}
        />
        <FieldGroup
          type="text"
          label="Postcode"
          name="user[postcode]"
          defaultValue={this.formValue('postcode')}
          error={this.formError('postcode')}
        />
        <FieldGroup
          type="text"
          label="Organisation"
          name="user[organisation]"
          defaultValue={this.formValue('organisation')}
          error={this.formError('organisation')}
        />
        <FieldGroup
          type="text"
          label="Role in organisation"
          name="user[role_in_organisation]"
          defaultValue={this.formValue('role_in_organisation')}
          error={this.formError('role_in_organisation')}
        />
      </div>
    )
  }
}
