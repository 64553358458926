import React, { Component, Fragment } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';
import BookingItemsTable from '../../booking_items/table';
import Editor from "./editor";
import InternalNotesWrapper from "./context";
import FormToObject from 'form-to-object';

export default class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: undefined
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    axios
      .get(`${window.location.pathname}.json`)
      .then((response) => {
        const response_data = response.data;
        this.setState({ data: response_data.data });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  handleSubmit = (type) => {
    let data = new FormToObject(this.formRef.current);

    axios
      .put(window.location.pathname, { internal_notes: { ...data, type: type } })
      .then(() => {
        this.fetchData();
      })
      .catch((error) => { console.log(error) })
  };

  render() {
    const { data } = this.state;

    if (!data) return null;

    return (
      <Fragment>
        <h3>
          Internal Notes
        </h3>

        <form ref={this.formRef}>
          <div className="row">
            <div className="col-lg-12">
              <InternalNotesWrapper items={data.booking_items.data}>
                <BookingItemsTable
                  extendedList={true}
                  internalNotes={true}
                  billData={data}
                  items={data.booking_items.data}
                />
              </InternalNotesWrapper>
            </div>
          </div>

          <Editor onSubmit={this.handleSubmit}/>
        </form>
      </Fragment>
    );
  }
}