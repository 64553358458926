import React, { Component } from 'react';

class Item extends Component {
  render() {
    const { title, link } = this.props;
    const liContent = link ? <a href={link}>{title}</a> : title;

    return <li>{liContent}</li>
  }
}

const Breadcrumbs = (props) => {
  if (!props.data) return null;

  return (
    <div className="breadcrumb-menu">
      <ol className="breadcrumb">
        {props.data.map((data, index) => <Item key={index} {...data}/>)}
      </ol>
    </div>
  )
};

export default Breadcrumbs;