import React, { Fragment } from 'react';
import SmartModal from '../../smart/modal';
import PubSub from 'pubsub-js';
import axios from "axios";
import FieldGroup from "../../smart/field_group";

export default class AttendeeModal extends SmartModal {
  constructor(props) {
    super(props);
  }

  showEdit = () => {
    const { path } = this.props;
    const { data } = this.state;

    axios
      .get(`${path}/${data.id}/attendee/edit.json`)
      .then((response) => {
        const response_data = response.data;
        this.setState(
          { formData: response_data.data, included: response_data.included, loading: false },
          () => this.onShowEdit()
        );
      })
      .catch((error) => { console.log(error) });
  };

  renderForm() {
    this.editForm = React.createRef();

    return (
      <Fragment>
        <FieldGroup
          type="text"
          label="Attendee"
          name="booking_option[attendee_name]"
          defaultValue={this.formValue('attendee_name')}
          error={this.formError('attendee_name')}
        />
      </Fragment>
    );
  }

  applyEdit = () => {
    const { path, name } = this.props;
    const { bookingId } = this.state.data;
    let data = this.getData();

    const config = { headers: { 'content-type': 'multipart/form-data' } };

    axios
      .put(`${path}/${this.state.data.id}/attendee.json`, data, config)
      .then((response) => {
        this.handleClose();
        PubSub.publish('BookingItem.Updated', { id: bookingId })
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  };
}
