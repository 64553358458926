import React, { Component } from 'react';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import Formatted from "../formatted";

function filterToEventDates(scheduleDates, filter) {
  return scheduleDates.filter(item => filter(item)).map((item) => item.eventDate);
}

export default class Calendar extends Component {
  handleDayClick = (day) => {
    const { eventDates, onClickEventDate } = this.props;
    const dateString = moment(day).format('YYYY-MM-DD');

    if (eventDates.find(item => item.availablePlaces === 0 && item.eventDateString === dateString)) return;

    const  item = eventDates.find(item => item.eventDateString === dateString);

    if (item !== undefined) onClickEventDate(item);
  };

  render() {
    const { eventDates, payPerDay } = this.props;

    let dates = eventDates.map((item) => item.eventDate);
    let startDates = filterToEventDates(eventDates, item => item.isStartDate && !item.selected && item.availablePlaces > 0);
    let scheduleDates = filterToEventDates(eventDates, item => !item.isStartDate && !item.selected && item.availablePlaces > 0);
    let selectedStartDates = filterToEventDates(eventDates, item => item.isStartDate && item.selected && item.availablePlaces > 0);
    let selectedScheduleDates = filterToEventDates(eventDates, item => !item.isStartDate && item.selected && item.availablePlaces > 0);
    let fullyBookedDates = filterToEventDates(eventDates, item => item.availablePlaces === 0);
    let selectedDates = eventDates.filter(item => item.selected);

    let firstDate = dates[0];
    let lastDate = dates[dates.length - 1];

    let month;

    if (payPerDay) {
      month = dates.filter(item => moment(item).isSameOrAfter(moment()))[0] || lastDate;
    } else {
      month = firstDate;
    }

    let modifiers = { startDates, scheduleDates, selectedStartDates, selectedScheduleDates, fullyBookedDates };

    return (
      <div className="section" id="calendar-section">
        <h4>Select your dates</h4>

        <div className="calendar-panel pull-left">
          <div className="calendar">
            <DayPicker
              month={month}
              modifiers={modifiers}
              fromMonth={firstDate}
              toMonth={lastDate}
              onDayClick={this.handleDayClick}
            />
          </div>
        </div>

        <div className="calendar-legend pull-left">
          <div className="calendar">
            <div>
              <div className="DayPicker-Day DayPicker-Day-Legend"/>
              – Dates out of schedule
            </div>
            <div>
              <div className="DayPicker-Day DayPicker-Day--scheduleDates DayPicker-Day-Legend"/>
              – Dates in schedule
            </div>
            <div>
              <div className="DayPicker-Day DayPicker-Day--startDates DayPicker-Day-Legend"/>
              – Available start dates
            </div>
            <div>
              <div className="DayPicker-Day DayPicker-Day--selectedScheduleDates DayPicker-Day-Legend"/>
              – Dates selected
            </div>
            <div>
              <div className="DayPicker-Day DayPicker-Day--fullyBookedDates DayPicker-Day-Legend"/>
              – Dates fully booked
            </div>
          </div>
        </div>

        {selectedDates.length > 0 &&
        <div className="dates-selected pull-left">
          <h4>Dates selected</h4>
          <table>
            <tbody>
            {selectedDates.map((selectedDate, index) =>
              <tr key={index}>
                <td><Formatted.Date date={selectedDate.eventDate}/></td>
                <td>&nbsp;&nbsp;&nbsp;</td>
                <td>{selectedDate.timeSlot}</td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
        }

        <div className="clearfix"/>
      </div>
    )
  }
}
