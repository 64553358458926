import React, { Component } from 'react';
import FieldGroup from '../../../smart/field_group';
import Fetch from "../../fetch";
import classnames from 'classnames';
import moment from 'moment';

function storeItem(name, value) {
  return window.localStorage.setItem(`reports.${name}`, value)
}

function loadItem(name) {
  return window.localStorage.getItem(`reports.${name}`)
}

export default class Filter extends Component {
  constructor(props) {
    super(props);

    let storedStartDate = loadItem('start_date');
    let storedEndDate = loadItem('end_date');

    this.state = {
      ready: false,
      venues: undefined,
      activities: undefined,
      time_period: 'this_month',
      start_date: storedStartDate ? moment(storedStartDate).toDate() : moment().subtract(1, 'months').toDate(),
      end_date: storedEndDate ? moment(storedEndDate).toDate() : moment().toDate()
    };

    this.handlers = {};
  }

  componentDidMount() {
    this.fetchVenues();
  }

  fetchVenues() {
    const { onReady } = this.props;

    Fetch
      .venues()
      .then(response => {
        const venues = response.data.data;
        const venue_id = (venues[0] || {}).id;

        this.setState({ ready: true, venues, venue_id }, () => {
          if (onReady) onReady();
          this.fetchActivities();
        });
      });
  }

  fetchActivities() {
    const { venue_id } = this.state;

    if (venue_id === '') return true;

    Fetch
      .activities({ venue_id, skip_pagination: true })
      .then(response => this.setState({ activities: response.data.data }));
  }

  fetchBookingOptions() {
    const { activity_id } = this.state;

    if (activity_id === '') return true;

    Fetch
      .bookingOptions({ activity_id: activity_id })
      .then(response => this.setState({ booking_options: response.data.data }));
  }

  handleChange = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        this.setState({ [name]: event.target.value }, () => {
          if (name === 'venue_id') {
            this.setState({
              activities: undefined,
              activity_id: null,
              booking_options: undefined,
              booking_option_id: null
            }, this.fetchActivities);
          }

          if (name === 'activity_id') {
            this.setState({
              booking_options: undefined,
              booking_option_id: null
            }, this.fetchBookingOptions);
          }

          if (name === 'start_date') storeItem('start_date', event.target.value);
          if (name === 'end_date') storeItem('end_date', event.target.value);

          const { onChanged } = this.props;
          if (onChanged) onChanged();
        });
      };
    }

    return this.handlers[name];
  };

  render() {
    const {
      ready,
      venues,
      activities,
      booking_options,
      time_period,
      start_date,
      end_date
    } = this.state;

    if (!ready) return null;

    return (
      <div className='activity-report-filter'>
        <FieldGroup
          label="Venue:"
          type="select"
          name="filter[venue_id]"
          options={venues}
          onChange={this.handleChange('venue_id')}
        />

        {activities && activities.length > 2 &&
        <FieldGroup
          label="Activity:"
          type="select"
          name="filter[activity_id]"
          titleKey='internal_reference'
          emptyOption={{ id: '', internal_reference: 'All' }}
          options={activities}
          value={this.state.activity_id}
          onChange={this.handleChange('activity_id')}
        />
        }

        {booking_options && booking_options.length > 2 &&
        <FieldGroup
          label="Booking options:"
          type="select"
          name="filter[booking_option_id]"
          emptyOption={{ id: '', title: 'All' }}
          options={booking_options}
          value={this.state.booking_option_id}
          onChange={this.handleChange('booking_option_id')}
        />
        }

        <FieldGroup
          label="Period:"
          type="select"
          name="filter[time_period]"
          value={time_period}
          options={[
            { id: "today", title: "Today" },
            { id: "this_week", title: "This Week" },
            { id: "this_month", title: "This Month" },
            { id: "custom", title: "Custom" }
          ]}
          onChange={this.handleChange('time_period')}
        />

        <span className={classnames(['custom_dates', { 'hidden': time_period !== 'custom' }])}>
          <FieldGroup
            label='Start:'
            type="date"
            defaultValue={start_date}
            name="filter[start_date]"
            onRealChange={this.handleChange('start_date')}
          />
          <FieldGroup
            label='End:'
            type="date"
            defaultValue={end_date}
            name="filter[end_date]"
            onRealChange={this.handleChange('end_date')}
          />
        </span>
      </div>
    );
  }
}
