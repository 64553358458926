import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import SmartModal from '../../smart/modal';
import axios from 'axios';
import Table from './modal/table';
import Info from './modal/info';

export default class Modal extends SmartModal {
  showInfo = () => {
    const { path } = this.props;
    const { id } = this.state.data;

    axios
      .get(`${path}/schedule_items/${id}.json`)
      .then((response) => {
        const responseData = response.data;
        this.setState({ formData: responseData.data, included: responseData.included, loading: false });
      })
      .catch((error) => { this.handleClose() });
  };

  renderBody() {
    if (this.state.loading) return null;

    const { formData, included } = this.state;
    const items = (included || {}).booking_items;

    if (!this.state.formData) return null;

    return (
      <Fragment>
        <Info {...formData}/>
        <Table items={items} formData={formData}/>
      </Fragment>
    );
  }

  renderButtons() {
    return null;
  }
}
