import React, { Component } from 'react';
import Map from './map';
import Breadcrumb from './breadcrumb';
import Filter from './filter';
import Footer from '../layouts/footer';
import ActivitiesList from "./activities_list";
import axios from "axios";

const defaultCenter = { lat: 51.500152, lng: -0.126236 }; // London
const defaultDistance = 18; // London

export default class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      data: undefined,
      included: undefined,
      center: defaultCenter,
      distance: defaultDistance,
      paramsStore: {}
    };

    this.filterRef = React.createRef();
  }

  componentDidMount() {
    this.changeMapSize();
    window.addEventListener("resize", this.changeMapSize.bind(this));
  }

  changeMapSize() {
    let offsetTop = $('#search-map').offsetTop;
    let height = window.innerHeight;

    offsetTop = 112;

    $('#search-map > div').height(height - offsetTop);
  }

  getMarkers() {
    const { data } = this.state;

    if (data === undefined) return [];

    return data;
  }

  handleChangeCenter = (center) => {
    this.setState({ center }, () => {
      this.fetchData({ latitude: center.lat, longitude: center.lng, update_location: false });
    });
  };

  fetchData = (params) => {
    const queryParams = Object.assign({}, this.state.paramsStore, params);

    this.setState({ loading: true, paramsStore: queryParams });

    axios
      .get('/all-activities.json', { params: queryParams })
      .then((response) => {
        const { data, included } = response.data;

        this.setState({ loading: false, data });
        if (included.center) this.setState({ center: included.center });
        if (included.distance) this.setState({ distance: included.distance });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  render() {
    const { data, loading, center, distance } = this.state;

    return (
      <div className="wrapper">
        <Breadcrumb/>
        <div className="search-wrapper">
          <div className="container">
            <div className="content-main">
              <div className="search-list content-left">
                <div className="filter">
                  <Filter filterRef={this.filterRef} updateData={this.fetchData}/>
                  <ActivitiesList loading={loading} data={data}/>
                </div>
                <Footer type="search"/>
              </div>
            </div>
            <div className="content-right" id="search-map">
              <div className='center-marker'/>
              <Map
                loading={loading}
                markers={this.getMarkers()}
                center={center}
                distance={distance}
                onChangeCenter={this.handleChangeCenter}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}