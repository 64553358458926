import SmartLabel from "../../smart/label";

export default class StatusLabel extends SmartLabel {
  constructor() {
    super();

    this.items = {
      blocked: { className: 'label-user-role-venue', title: 'Blocked' },
      pending: { className: 'label-user-role-subadmin', title: 'Pending' },
      active: { className: 'label label-user-role-user', title: 'Active' },
    }
  }
}