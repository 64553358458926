import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import ErrorBoundary from "../error_boundary";

export default class Attachment extends Component {
  constructor(props) {
    super(props);
    this.state = { removed: false };
  }

  remove = (e) => {
    e.preventDefault();
    const { document, onRemove, index } = this.props;
    (document) ? this.setState({ removed: true }) : onRemove(e, index);
  };

  revert = (e) => {
    e.preventDefault();
    this.setState({ removed: false });
  };

  render() {
    const { document, onlyView } = this.props;
    const { removed }  = this.state;
    const formSpace = 'booking[attachments_attributes][]';

    let className = classnames(['question_document', { 'removed_question_document': removed }]);

    let rightButton = null;
    let inputField = null;

    if (document === undefined) {
      inputField = <input name={`${formSpace}[attachment]`} className="file optional" type="file"/>;
    } else {
      inputField = <span><a href={document.url} target="_blank">{document.title}</a></span>;
    }

    if (!onlyView) {
      if (removed) {
        rightButton = (
          <Fragment>
            <a className="delete_question_document" href="#" onClick={this.revert}>Revert</a>
            <input name={`${formSpace}[id]`} type="hidden" value={document.id}/>
            <input name={`${formSpace}[_destroy]`} type="hidden" value="true"/>
          </Fragment>
        )
      } else {
        rightButton = (
          <Fragment>
            <a className="delete_question_document" href="#" onClick={this.remove}>Delete</a>
          </Fragment>
        )
      }
    }

    return (
      <ErrorBoundary>
        <div className={className}>
          <div className="pull-right">{rightButton}</div>
          {inputField}
        </div>
      </ErrorBoundary>
    )
  }
}