import React, { Component } from 'react';
import Table from "./table";
import Page from '../page';

export default class Index extends Component {
  render() {
    return (
      <div>
        <Page.Header leftSide={<Page.NewButton title="Add customer" name="Customer"/>}>
          Customers
        </Page.Header>

        <Table title="Customer" name="Customer" path="/admin/customers"/>
      </div>
    );
  }
}
