import React, { Component, Fragment } from 'react';
import BookingItemRow from './booking_item_row';
import Formatted from "../../../formatted";

export default class BookingItemsTable extends Component {
  render() {
    const { booking_items } = this.props;

    if (!booking_items) return null;

    const {
      activity,
      event_date,
      booking_option,
      time_slot,
      address
    } = this.props.activity;

    return (
      <table className='table table-bordered'>
        <thead>
        <tr className='success'>
          <td className='text-center'>{activity}</td>
          <td className='text-center'/>
          <td className='text-center'>{booking_option}</td>
          <td className='text-center'><Formatted.Date value={event_date}/></td>
          <td className='text-center'>{time_slot}</td>
          <td className='text-center'>{address}</td>
        </tr>
        <tr>
          <th>Customer</th>
          <th>Attendee</th>
          <th>DoB</th>
          <th>Order ID</th>
          <th>Extras</th>
          <th>Internal notes</th>
        </tr>
        </thead>
        <tbody>
        {booking_items.map((booking_item, index) =>
          <BookingItemRow key={index} {...booking_item} />
        )}
        </tbody>
      </table>
    );
  }
}
