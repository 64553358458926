import React from "react";

export default class CustomPageComponent extends React.Component {
  handlePageChange = e => {
    const page = e.target.value ? Number(e.target.value) - 1 : 0;
    this.props.onPageChange(page);
  };

  handlePageSizeChange = e => {
    const pageSize = Number(e.target.value);
    this.props.onPageSizeChange(pageSize);
  };

  render() {
    const { pages, page, pageSize, canPrevious, canNext } = this.props;

    return (
      <div className="pagination-bottom">
        <div className="-pagination">
          <div className="-previous">
            <button type="button"
                    onClick={() => this.props.onPageChange(page - 1)}
                    disabled={!canPrevious}
                    className="-btn">
              Previous
            </button>
          </div>
          <div className="-center">
            {pages > 1 ? <span className="-pageInfo">
            Page <div className="-pageJump">
              <input aria-label="jump to page"
                     type="number"
                     value={page + 1}
                     onChange={this.handlePageChange}/>
            </div> of <span className="-totalPages">{pages}</span></span>  : null}
          </div>
          <div className="-next">
            <button type="button"
                    onClick={() => this.props.onPageChange(page + 1)}
                    disabled={!canNext}
                    className="-btn">
              Next
            </button>
          </div>
        </div>
      </div>
    )
  }
}