import React, { Component } from 'react';
import ParentSubTabs from '../subtabs';
import { AbilityContext } from '../../ability_context';

class Tabs extends Component {
  render() {
    const { active, ability } = this.props;

    return (
      <ParentSubTabs
        active={active}
        items={[
          {
            name: 'activity',
            title: 'Activity',
            href: '/admin/reports/activities',
            hidden: ability.cannot('seeActivities', 'AdminReports')
          },
          {
            name: 'bookings',
            title: 'Bookings',
            href: '/admin/reports/bookings',
            hidden: ability.cannot('seeBookings', 'AdminReports')
          },
          {
            name: 'customers',
            title: 'Customers',
            href: '/admin/reports/customers',
            hidden: true
          },
          {
            name: 'remittance',
            title: 'Remittance',
            href: '/admin/reports/remittance',
            hidden: ability.cannot('seeRemittance', 'AdminReports')
          }
        ]}
      />
    )
  }
}

export default class Submenu extends Component {
  render() {
    const { active } = this.props;

    return (
      <AbilityContext.Consumer>
        {ability => <Tabs active={active} ability={ability}/>}
      </AbilityContext.Consumer>
    )
  }
}
