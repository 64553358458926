import React, { Component } from 'react';
import axios from 'axios';
import PubSub from 'pubsub-js';

export default class MessagesButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      unread_conversations_count: undefined
    }
  }

  componentDidMount() {
    PubSub.subscribe('UpdateUnreadMessagesCount', this.fetchMassages);
    this.fetchMassages();
  }

  fetchMassages = () => {
    axios
      .get('/messages.json')
      .then((response) => this.setState({ unread_conversations_count: response.data.data.unread_conversations_count }))
      .catch((error) => console.log(error));
  };

  render() {
    const { path } = this.props;
    const { unread_conversations_count } = this.state;

    return (
      <li className="main-item">
        <a className="main-menu-link" href={path}>
          <i className="fa fa-commenting-o"/>
          Messages
          {unread_conversations_count > 0 &&
          <span className="label label-warning pull-right">{unread_conversations_count}</span>
          }
        </a>
      </li>
    );
  }
}