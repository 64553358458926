import React, { Component } from 'react';
import Page from '../page';
import Table from "./table";

export default class Index extends Component {
  render() {
    return (
      <div>
        <Page.Header>
          Bookings
        </Page.Header>

        <Table name="Booking" path="/account/bookings" title="Booking"/>
      </div>
    );
  }
}
