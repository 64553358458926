import React from 'react';
import SmartModal from '../../smart/modal';

export default class Modal extends SmartModal {
  renderForm() {
    return (
      <div>
        <SmartModal.FieldGroup
          type="text"
          label="Attendees"
          name="multiple_attendees_discount[attendees]"
          defaultValue={this.formValue("attendees")}
          error={this.formError("attendees")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Discount (%)"
          name="multiple_attendees_discount[percent]"
          defaultValue={this.formValue("percent")}
          error={this.formError("percent")}
        />
      </div>
    );
  }
}
