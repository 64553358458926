import React, { Component } from 'react';
import classnames from 'classnames';

const params = {
  system_admin: { className: 'label-user-role-system-admin', title: 'System admin' },
  venue_admin: { className: 'label-user-role-venue', title: 'Venue Admin' },
  subadmin: { className: 'label-user-role-subadmin', title: 'Subadmin' },
  user: { className: 'label-user-role-user', title: 'User' },
};

export default class RoleLabel extends Component {
  render() {
    let { role } = this.props;
    let roleParams = params[role];

    if (roleParams === undefined) return null;

    let spanClasses = classnames(['label', roleParams.className]);

    return (
      <span className={spanClasses}>{roleParams.title}</span>
    );
  }
};
