import React from 'react';
import { appendComponents } from '../render';

import MessagesIndex from './messages/index';
import ProfilesShow from './profiles/show';
import BookingsIndex from './bookings/index';
import BookingsShow from './bookings/show';
import InvoicesIndex from './invoices/index';
import FavouritesIndex from './favourites/index';

appendComponents({
  account_messages_index: {
    component: <MessagesIndex/>
  },
  account_profiles_show: {
    component: <ProfilesShow/>
  },
  account_bookings_index: {
    component: <BookingsIndex/>
  },
  account_bookings_show: {
    component: <BookingsShow/>
  },
  account_invoices_index: {
    component: <InvoicesIndex/>
  },
  account_favourites_index: {
    component: <FavouritesIndex/>
  }
});