import React, { Fragment } from 'react';
import SmartModal from '../../smart/modal';
import FieldGroup from '../../smart/field_group';
//import { Can } from '../../ability_context';

export default class Modal extends SmartModal {
  renderTumbler(name, callback) {
    return (
      <div className="price-radio-button">
        <FieldGroup
          type="radio-group"
          name={`venue_activity_category[${name}]`}
          defaultValue={this.formValue(name)}
          options={[
            { id: false, title: `same payment options as the venue` },
            { id: true, title: "set specific payment options for the activity category" },
          ]}
          onChange={callback}
        />
      </div>
    )
  }

  renderSplitPaymentFields(options) {
    const { disabled, defaultValues } = options;

    return (
      <Fragment>
        <FieldGroup
          type="select"
          disabled={disabled}
          options={this.includedData('split_payment_options')}
          label="Split payment schedule"
          name="venue_activity_category[split_payment_option_id]"
          defaultValue={defaultValues.split_payment_option_id}
          error={this.formError('split_payment_option_id')}
        />
      </Fragment>
    );
  }

  renderDepositFields(options) {
    const { disabled, defaultValues } = options;

    return (
      <Fragment>
        <FieldGroup
          type="input"
          disabled={disabled}
          label="Deposit (%) payable on booking"
          name="venue_activity_category[preorder_first_part_percent]"
          defaultValue={defaultValues.preorder_first_part_percent}
          error={this.formError('preorder_first_part_percent')}
        />

        <FieldGroup
          type="input"
          disabled={disabled}
          label="Balance due x days before event"
          name="venue_activity_category[preorder_second_part_due_days]"
          defaultValue={defaultValues.preorder_second_part_due_days}
          error={this.formError('preorder_second_part_due_days')}
        />
      </Fragment>
    );
  }

  toggleSplitPayment = (e) => {
    let { formData } = this.state;
    formData.split_payment_option_enabled = (e.target.value === 'true');
    this.setState({ formData }, () => { console.log(this.state) });
  };

  toggleDeposit = (e) => {
    let { formData } = this.state;
    formData.preorder_first_part_enabled = (e.target.value === 'true');
    this.setState({ formData }, () => { console.log(this.state) });
  };

  renderForm() {
    const venue = this.includedData('venue');
    const activity_category = this.includedData('activity_category');
    const venue_activity_category = this.state.formData;
    const {
      split_payment_option_enabled,
      preorder_first_part_enabled
    } = venue_activity_category;

    return (
      <Fragment>
        <dl className="dl-horizontal">
          <dt>Venue:</dt>
          <dd>{venue.title}</dd>

          <dt>Activity category:</dt>
          <dd>{activity_category.title}</dd>
        </dl>

        <h4>Split payment</h4>

        {this.renderTumbler('split_payment_option_enabled', this.toggleSplitPayment)}
        {split_payment_option_enabled && this.renderSplitPaymentFields({ defaultValues: venue_activity_category })}
        {!split_payment_option_enabled && this.renderSplitPaymentFields({ disabled: true, defaultValues: venue })}

        <h4>Deposit</h4>

        {this.renderTumbler('preorder_first_part_enabled', this.toggleDeposit)}
        {preorder_first_part_enabled && this.renderDepositFields({ defaultValues: venue_activity_category })}
        {!preorder_first_part_enabled && this.renderDepositFields({ disabled: true, defaultValues: venue })}

        <h4>Offline payment</h4>

        <FieldGroup
          type="radio-group"
          options={[
            { id: 'per_customer', title: 'offline payment option set per customer' },
            { id: 'always_available', title: 'offline payment option always available' }
          ]}
          name="venue_activity_category[offline_payment_type]"
          defaultValue={this.formValue('offline_payment_type')}
          error={this.formError('offline_payment_type')}
        />
      </Fragment>
    );
  }
}
