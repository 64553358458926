import React, { Component} from 'react';
import { Checkbox } from 'react-bootstrap';
import Formatted from "../formatted";

export default class Extra extends Component {
  handleChange = (e) => {
    const { index, onChange } = this.props;
    onChange(index, e.target.checked);
  };

  render() {
    const { title, price, price_with_vat, checked, pricing_calculation_type } = this.props;

    let sChecked = (checked === true);
    let priceBoxValue = null;

    if (pricing_calculation_type === 'percent') {
      priceBoxValue = <Formatted.Percent percent={price}/>
    } else {
      priceBoxValue = <Formatted.Price price={price_with_vat}/>
    }

    return (
      <div className="col-md-4">
        <div className="extra">
          <div className="price-box pull-right">
            {priceBoxValue}
          </div>

          <Checkbox checked={sChecked} onChange={this.handleChange}>
            {title}
          </Checkbox>
        </div>
      </div>
    )
  }
}
