import React from 'react';
import { appendComponents } from './render';

import HomeIndex from './home/index';
import OpportunitiesIndex from './opportunities/index';
import BookingIndex from './booking/index';
import VenuesShow from './venues/show';
import SearchesIndex from './searches/index';
import InfoPagesShow from './info_pages/show';
import UserPasswordEdit from './users/passwords/edit';
import CheckoutsIndex from './checkouts/index';

appendComponents({
  home_index: {
    component: <HomeIndex/>,
    containerClasses: 'none'
  },
  opportunities_index: {
    component: <OpportunitiesIndex/>,
    containerClasses: 'none'
  },
  booking_index: {
    component: <BookingIndex/>
  },
  venues_show: {
    component: <VenuesShow/>
  },
  subdomain_venues_show: {
    component: <VenuesShow/>
  },
  searches_index: {
    showFooter: false,
    component: <SearchesIndex/>,
    containerClasses: 'none'
  },
  checkout_show: {
    component: <CheckoutsIndex/>
  },
  info_pages_show: {
    component: <InfoPagesShow/>
  },
  users_passwords_edit: {
    component: <UserPasswordEdit/>
  }
});
