import React from 'react';
import PubSub from 'pubsub-js';
import { Button } from 'react-bootstrap';

export const EditButton = (props) => (
  <Button bsStyle='primary' onClick={() => PubSub.publish('Profile.Edit')}>
    {props.title}
  </Button>
);

