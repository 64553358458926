import React from 'react';
import { Modal } from 'react-bootstrap';

export default function SpinnerModal(props) {
  const { show } = props;

  console.log('show', show)

  return (
    <Modal show={show} dialogClassName="modal-sm">
      <Modal.Body>
        <div id="loader-wrapper">
          <div id="loader"/>
        </div>
      </Modal.Body>
    </Modal>
  );
}