import React, { Component } from 'react';
import classnames from 'classnames';
import Moment from 'react-moment';

export default class Interlocutor extends Component {
  changeInterlocutor = (e) => {
    e.preventDefault();
    const { onChange, data } = this.props;
    onChange(data);
  };

  render() {
    const { data, interlocutor } = this.props;
    const { id, type, name, last_message_at, unread } = data;

    let selectedInterlocutor = interlocutor || {};

    let classNames = classnames(
      [
        'list-group-item',
        'pair_conversation_item',
        { 'selected': (selectedInterlocutor.id === id && selectedInterlocutor.type === type) },
        { 'unread': unread }
      ]
    );

    return (
      <li className={classNames}>
        {/*<span className="flag not_flagged">&nbsp;</span>*/}
        <a title={name} className="venue_user_conversation " href="#" onClick={this.changeInterlocutor}>
          <span className='interlocutor-name'>{name}</span>
        </a>
        <span className="last_message_time">
          <Moment date={last_message_at} format='ddd, DD MMM' locale="en-gb"/>
        </span>
      </li>
    )
  }
}
