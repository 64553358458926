import React, { Component } from 'react';
import SmartTable from '../../smart/table';
import { DateCell, WithTooltip } from '../../smart/cells';
import Filter from "./filter";
import { PriceCell }  from '../../smart/cells'
import StatusCell from '../../bookings/status_cell';
import ModificationStatusCell from '../../admin/modifications/status_cell';
import Modal from './modal';
import ActionsCell from './actions_cell';
import MessageModal from "../../messages/modal";

export default class Table extends Component {
  render() {
    return (
      <div>
        <Modal {...this.props}/>

        <MessageModal
          name='Message'
          path='/account/messages'
          title="Message"
        />

        <SmartTable
          TableLeftSide={Filter}
          name="Booking"
          showHeader={false}
          dataPath='/account/bookings.json'
          defaultSorted={[
            {
              id: "created_at",
              desc: true
            }
          ]}
          columns={[
            {
              Header: "Date of request",
              accessor: "created_at",
              width: 100,
              Cell: props => <DateCell date={props.value}/>
            },
            {
              Header: "Venue",
              accessor: "venue_title",
              Cell: (props) => {
                return (
                  <WithTooltip>
                  <a href={props.original.venueLink} target="_blank">{props.value}</a>
                  </WithTooltip>
                )
              }
            },
            {
              Header: "Activities",
              accessor: "activity_internal_references",
              Cell: props => <WithTooltip>{props.value}</WithTooltip>
            },
            {
              Header: "Attendees",
              accessor: "attendees",
              sortable: false,
              Cell: props => <WithTooltip>{props.original.attendees.join(', ')}</WithTooltip>
            },
            {
              Header: "Price",
              accessor: "gross_total",
              width: 90,
              Cell: props => <PriceCell value={props.value}/>
            },
            {
              Header: "Status",
              accessor: "status",
              width: 120,
              resizable: false,
              Cell: props => <StatusCell status={props.original.status} offline={props.original.offline}/>
            },
            {
              Header: "Amendment",
              accessor: "modification_status",
              width: 85,
              resizable: false,
              sortable: false,
              Cell: props => <ModificationStatusCell value={props.original.modification_status}/>
            },
            {
              Header: "Actions",
              sortable: false,
              width: 70,
              resizable: false,
              style: { overflow: 'inherit' },
              Cell: props => <ActionsCell bsSize='xs' row={props.original}/>
            }
          ]}
        />
      </div>
    );
  }
}