import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import Modal from '../../../PaymentSystem/Modal';

function currency(price) {
  const params = { style: 'currency', currency: 'GBP', currencyDisplay: 'symbol' };
  const intl = new Intl.NumberFormat('en-GB', params);

  return intl.format(price);
}

export default ({ paymentPath, amount }) =>  {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        show={showModal}
        setShow={setShowModal}
        paymentPath={paymentPath}
        buttonTitle={`Pay ${currency(amount)}`}
        spinnerMessage="Please wait while we process your payment"
      />

      <Button bsStyle='primary' bsSize='large' className="btn-no-radius" onClick={() => setShowModal(true)}>
        Book now
      </Button>
    </>
  )
}
