import React, { Component, Fragment } from 'react';
import debounce from 'debounce';

export default class DebounceInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue || '',
      dValue: props.defaultValue || ''
    };

    this.sendChanged = debounce(this.sendChanged, 300);
  }

  handleChange = (e) => {
    const { value } = e.target;

    this.setState({ value }, () => this.sendChanged(value));
  };

  sendChanged = (value) => {
    const { onChange } = this.props;

    this.setState({ dValue: value }, () => {
      if (onChange) onChange({ target: { value } });
    });
  };

  render() {
    const {
      onChange, // remove from props
      defaultValue, // remove from props
      ...props
    } = this.props;

    const {
      value
    } = this.state;

    return (
      <Fragment>
        <input
          ref={this.inputRef}
          value={value}
          className="form-control"
          onChange={this.handleChange}
          {...props}
        />
      </Fragment>
    )
  }
}