import React, { Component } from 'react';

export default ({ children }) => (
  <div className="tab-content">
    <div className="tab-pane active">
      <div className="navtab-menu">
        <div className="submenu_holder">
          {children}
        </div>
      </div>
    </div>
  </div>
)
