import React, { Component } from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';

export const Context = React.createContext();

export default class InternalNotesWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIds: [],
      toggle: this.toggle,
      selectIds: this.selectIds,
      deselectIds: this.deselectIds
    };
  }

  toggle = (id) => {
    let { selectedIds } = this.state;
    
    if (selectedIds.includes(id)) {
      let index = selectedIds.indexOf(id);
      selectedIds.splice(index, 1);
    } else {
      selectedIds.push(id);
    }

    this.setState({ selectedIds });
  };

  selectIds = (ids) => {
    let { selectedIds } = this.state;

    selectedIds = [...new Set([...selectedIds, ...ids])];

    this.setState({ selectedIds })
  };

  deselectIds = (ids) => {
    let { selectedIds } = this.state;

    selectedIds = selectedIds.filter((selectedId) => !ids.includes(selectedId));

    this.setState({ selectedIds })
  };

  selectAll() {
    const { items } = this.props;

    let selectedIds = [];

    items.forEach((item) => item.schedule_items.forEach((schedule_tem) => selectedIds.push(schedule_tem.id)));

    this.setState({ selectedIds })
  }

  deselectAll() {
    this.setState({ selectedIds: [] })
  }

  render() {
    const { children } = this.props;

    return(
      <Context.Provider value={this.state}>
        <ButtonGroup bsSize='xs' className='internal-notes-actions-main'>
          <Button className='btn-success' onClick={() => this.selectAll()}>All</Button>
          <Button onClick={() => this.deselectAll()}>None</Button>
        </ButtonGroup>

        {children}
      </Context.Provider>
    )
  }
}

