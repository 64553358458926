import React, { Component } from 'react';

export default class VenueRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      destroy: props.venue_user === null
    }
  }

  changeDestroy = () => {
    this.setState({ destroy: !this.state.destroy });
  };

  render() {
    const { index, id, title, venue_user } = this.props;
    const { destroy } = this.state;

    return (
      <tr>
        <td>
          <div className="form-group user_venue_users__destroy">
            <div className="checkbox">
              <input
                defaultValue="true"
                type="hidden"
                name={`user[venue_users_attributes][${index}][_destroy]`}
              />
              <label>
                <input
                  className="create_role"
                  type="checkbox"
                  defaultValue="false"
                  checked={!destroy}
                  name={`user[venue_users_attributes][${index}][_destroy]`}
                  onChange={this.changeDestroy}
                />
                {title}
              </label>
            </div>
          </div>

          <div className="form-group hidden user_venue_users_venue_id">
            <input
              className="hidden form-control"
              type="hidden"
              defaultValue={id}
              name={`user[venue_users_attributes][${index}][venue_id]`}
            />
          </div>

          <div className="form-group hidden user_venue_users_id">
            <input
              className="hidden form-control"
              type="hidden"
              name={`user[venue_users_attributes][${index}][id]`}
              value={(venue_user || {}).id}
            />
          </div>
        </td>
        <td>
          <div className="form-group">
            <select
              defaultValue={(venue_user || {}).access_type}
              className="select form-control"
              name={`user[venue_users_attributes][${index}][access_type]`}
              disabled={destroy}>
              <option value="read_only">Read Only</option>
              <option value="full_access">Full Access</option>
            </select>
          </div>
        </td>
      </tr>
    )
  }
}