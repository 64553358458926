import React, { Component } from 'react';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import moment from 'moment';

export default class Input extends Component {
  constructor(props) {
    super(props);
    this.state = { year: null, month: null, day: null };
  }

  changeYear = (year) => this.setState({ year }, this.updateDate);
  changeMonth = (month) => this.setState({ month }, this.updateDate);
  changeDay = (day) => this.setState({ day }, this.updateDate);

  updateDate = () => {
    const { onChange } = this.props;
    const { year, month, day } = this.state;

    if (!onChange) return true;

    if ([parseInt(year), parseInt(month), parseInt(day)].some(x => isNaN(x))) {
      return onChange(null)
    }

    const newDate = moment();
    newDate.set('year', year);
    newDate.set('month', month);
    newDate.set('date', day);
    newDate.startOf('day');

    onChange(newDate.isValid() ? newDate.format('YYYY-MM-DD') : null);
  }

  render() {
    return (
      <div className='form-inline'>
        <div className='form-group'>
          <DayPicker
            defaultValue={'Day'}
            year={this.state.year}
            month={this.state.month}
            endYearGiven
            required={true}
            value={this.state.day}
            onChange={this.changeDay}
            classes={'form-control'}
          />
        </div>

        <div className='form-group'>
          <MonthPicker
            defaultValue={'Month'}
            endYearGiven
            year={this.state.year}
            required={true}
            value={this.state.month}
            onChange={this.changeMonth}
            classes={'form-control'}
          />
        </div>

        <div className='form-group'>
          <YearPicker
            defaultValue={'Year'}
            start={1930}
            end={new Date().getFullYear()}
            reverse
            required={true}
            value={this.state.year}
            onChange={this.changeYear}
            classes={'form-control'}
          />
        </div>
      </div>
    );
  }
}
