import React, { Component } from 'react';
import SmartBlock from '../../smart/block';
import Image from './image';

export default class Block extends SmartBlock {
  constructor(props) {
    super(props);

    this.getImage = this.getImage.bind(this);
  }

  getImage(position) {
    const { data } = this.state;
    let item = undefined;

    if (Array.isArray(data) && data.length > 0) {
      item = data.filter((dataItem) => dataItem.position === position)[0];
    }

    return <Image key={position} path={this.props.path} position={position} item={item} />
  }

  render() {
    const { length } = this.props;
    const positions = [...Array(length || 3).keys()];

    return (
      <div>
        <div className="photos row row-gallery">
          {positions.map((position) => this.getImage(position))}
        </div>
      </div>
    )
  }
}
