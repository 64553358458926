import FieldGroup from "../../../smart/field_group";
import React, { useState } from "react";
import queryString from "query-string";

export default ({ onChange }) => {
  const [browserQueryString, setBrowserQueryString] = useState(queryString.parse(location.search))

  return (
    <FieldGroup
      label="Search"
      type="text-debounce"
      name="terms"
      defaultValue={browserQueryString.terms}
      onChange={onChange}
    />
  )
}
