import React, { Component } from 'react';
import axios from 'axios';
import DatePickerInput from '../smart/date_picker_input';
import PlaceInput from "../smart/place_input";
import moment from 'moment';
import Select from "../smart/select";
import { toastr } from 'react-redux-toastr';

export default class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activity_categories: undefined,
    };

    this.formRef = React.createRef();
    this.performForm =  this.performForm.bind(this);
    this.fetchData =  this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    axios
      .get('/activity_categories.json')
      .then((response) => {
        const { data } = response.data;
        this.setState({ activity_categories: data })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  performForm(e) {
    e.preventDefault();

    let data = new FormData(this.formRef.current);
    data.append('date', moment(data.get('date')).format('L'));
    let params = new URLSearchParams(data);

    axios
      .get('/all-activities.json', { params: params })
      .then(() => { this.redirect(params) })
      .catch((error) => {
        let errors = error.response.data.errors;
        let key = Object.keys(errors)[0];
        toastr.error('Error', errors[key].join(', '))
      });
  }

  redirect(params) {
    window.location.href = `/all-activities?${params.toString()}`;
  }

  render() {
    const { activity_categories } = this.state;

    return (
      <div id="search_line" >
        <form action="/all-activities" ref={this.formRef}>
          <ul>
            <li className="custom_select_block facility-search-field">
              <Select
                className="home-search-field"
                name="category"
                emptyOption={{ id: '', title: 'Activity' }}
                valueKey="title"
                options={activity_categories}
              />
            </li>
            <li className="location-block facility-search-field">
              <PlaceInput name="area" placeholder="Location"/>
            </li>
            <li className="datepicker_block facility-search-field">
              <DatePickerInput name="date" format="ddd, DD MMM YYYY"/>
            </li>
            <li className="submit">
              <input
                className="home-search-submit"
                type="submit"
                name="commit"
                onClick={this.performForm}
                defaultValue="search"
              />
            </li>
          </ul>
        </form>
        <div className="clear"/>
      </div>
    )
  }
};
