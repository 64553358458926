import React, { Component, Fragment } from 'react';
import SmartTable from '../../smart/table';
import Filter from './filter';
import { PriceCell, WithTooltip } from '../../smart/cells';
import ActionsCell  from './actions_cell';
import Modal from './modal';
import MessageModal from "../../messages/modal";
import AttachmentsListModal from "../attachments/list_modal";

export default class Table extends Component {
  constructor(props) {
    super(props);

    this.state = { visibleVenuesColumn: true }
  }

  onChangeSearch = (params) => {
    this.setState({ visibleVenuesColumn: (params.venue_id === "") })
  };

  render() {
    const {visibleVenuesColumn}= this.state;

    return (
      <Fragment>
        <Modal {...this.props}/>

        <MessageModal
          name='Message'
          path='/admin/messages'
          title="Message"
        />

        <AttachmentsListModal
          name='ListAttachments'
          path='/admin/bookings'
          title="Document manager"
        />

        <SmartTable
          {...this.props}
          TableLeftSide={Filter}
          onChangeSearch={this.onChangeSearch}
          showHeader={false}
          dataPath='/admin/customers.json'
          defaultSorted={[{ id: "name", desc: false }]}
          columns={[
            {
              Header: "Name",
              accessor: "name",
              width: 200,
              Cell: props => <WithTooltip>{props.value}</WithTooltip>
            },
            {
              Header: "Phone number",
              accessor: "phone",
              Cell: props => <WithTooltip>{props.value}</WithTooltip>
            },
            {
              Header: "Email",
              accessor: "email",
              Cell: props => <WithTooltip>{props.value}</WithTooltip>
            },
            {
              Header: "Venue",
              accessor: "venues",
              width: 180,
              show: visibleVenuesColumn,
              Cell: props => <WithTooltip>{props.value}</WithTooltip>
            },
            {
              Header: "Bookings",
              accessor: "bookings_count",
              width: 80,
              Cell: props => <div className="text-right">{props.value}</div>
            },
            {
              Header: "Total spend",
              sortable: false,
              accessor: "sum_gross_total",
              width: 100,
              Cell: props => <PriceCell value={props.value}/>
            },
            {
              Header: "Actions",
              sortable: false,
              width: 70,
              style: { overflow: 'inherit' },
              Cell: props => <ActionsCell bsSize='xs' row={props.original}/>
            }
          ]}
        />
      </Fragment>
    );
  }
}