import React, { Component } from 'react';
import Modal from './modal';
import PubSub from 'pubsub-js';

export default class NewMessageButton extends Component {
  showModal = () => {
    PubSub.publish(
      'Message.New',
      {
        inbox: { type: 'User', id: window.PageData.schoolhire.id },
        interlocutor: { type: 'Venue' }
      }
    )
  };

  render() {
    return (
      <div className="vert-offset-bottom-15 vert-offset-top-15">
        <Modal path="admin/messages" name="Message" title="Message"/>
        <button className="btn btn-block btn-primary btn-no-radius btn-lg" onClick={this.showModal}>
          New message
        </button>
      </div>
    );
  }
}
