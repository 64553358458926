import React, { Component, Fragment } from 'react';
import { UserContext } from "../user";
import MessageModal from "../messages/modal";
import PubSub from 'pubsub-js';

export default class MessageButton extends Component {
  componentDidMount() {
    PubSub.subscribe('Message.ShowModal', this.showMessageModal);
  }

  handleClick = (user) => {
    if (user.isLoggedIn()) {
      this.showMessageModal();
    } else {
      this.showLoginModal();
    }
  };

  showMessageModal = () => {
    const { venue_id, user_id } = window.PageData;

    let params = {
      inbox: { type: 'User', id: user_id },
      interlocutor: { type: 'Venue', id: venue_id }
    };

    PubSub.publish('Message.New', params)
  };

  showLoginModal = () => {
    PubSub.publish('Devise.SignIn', { autoProcess: true, modalTitle: 'Sign In', showMessageModal: true });
  };

  render() {
    return (
      <div className="message-block">
        <Fragment>
          <MessageModal name='Message' path='/account/messages' title="Message"/>

          <UserContext.Consumer>
            {user => <button className="message" onClick={() => this.handleClick(user)}>Message</button>}
          </UserContext.Consumer>
        </Fragment>
      </div>
    )
  }
}
