import React, { Component } from 'react';
import Tabs from '../areas/tabs';
import Info from './info';
import AttendeeQuestions from '../attendee_questions/block';

export default class Show extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: undefined,
      venue: { title: 'Venue' },
      activity_category: { title: 'Category' }
    };
  }

  updateData = (data, included) => {
    const venue = included.venue.data;
    const activity_category = included.activity_category.data;
    this.setState({ data, venue, activity_category })
  };

  render() {
    const { data, venue, activity_category } = this.state;

    return (
      <div>
        <h2>{activity_category.title} in {venue.title}</h2>
        <Tabs
          list={[
            {
              name: 'info',
              title: "Payment options",
              component: (
                <Info
                  name="VenueActivityCategory"
                  updatePageData={this.updateData}
                  path={window.location.pathname}
                />
              )
            },
            {
              name: 'questions',
              title: "Questions",
              component: (
                <AttendeeQuestions
                  path={`${window.location.pathname}/attendee_questions`}
                  title="Question"
                  name='Question'
                />
              )
            },
          ]}
        />
      </div>
    );
  }
}
