import React, { Component } from 'react';

export default class SocialBlock extends Component {
  render() {
    const url = window.location.href;

    const facebook = `http://www.facebook.com/sharer.php?u=${url}`;
    const twitter = `https://twitter.com/share?url=${url}&text=Check this schoolhire link!`;
    const pinterest = `http://pinterest.com/pin/create/link/?url=${url}`;

    return (
      <div className="social-block">
        <table className="table-bordered">
          <tbody>
            <tr>
              <td colSpan={3}>
                <h4>Share</h4>
              </td>
            </tr>
            <tr>
              <td className="col-md-3">
                <a target="_blank" href={facebook}>
                  <img src={require('images/fb-share.png')} alt="Fb share" />
                </a>
              </td>
              <td className="col-md-3 custom-tweet-button">
                <a target="_blank" href={twitter}>
                  <img src={require('images/twitter-share.png')} alt="Twitter share" />
                </a>
              </td>
              <td className="col-md-3">
                <a target="_blank" href={pinterest}>
                  <img src={require('images/pinterest-share.png')} alt="Pinterest share" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}