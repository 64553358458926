import React, { Component } from 'react';
import Question from './question';
import axios from 'axios';
import { BackButton, ContinueButton } from '../step_buttons';

export default class Index extends Component {
  constructor() {
    super();

    this.state = {
      data: undefined,
      included: undefined,
      errorDetails: {}
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { bookingId, involvedAttendeeId } = this.props;

    axios
      .get(`/bookings/${bookingId}/checkout/attendee_questions/${involvedAttendeeId}.json`)
      .then((response) => {
        const { data, included } = response.data;
        this.setState({ data, included });
      })
      .catch((error) => console.log(error));
  }

  perform = () => {
    const { bookingId, onNext, involvedAttendeeId } = this.props;
    const data = new FormData(this.formRef.current);
    data.append('involved_attendee[key]', 'Hhf3y4vb-34bv-4cv-23yfb');

    axios
      .put(`/bookings/${bookingId}/checkout/attendee_questions/${involvedAttendeeId}.json`, data)
      .then(() => onNext())
      .catch((error) => {
        const responseData = error.response.data;
        const formErrors = responseData.errors;

        this.setState({ formErrors })
      });
  };

  render() {
    const { data, formErrors } = this.state;
    const { onBack, allowBack } = this.props;

    if (data === undefined) return null;

    return (
      <form ref={this.formRef}>
        <div className='row'>
          {data.map((item, index) =>
            <div className='col-md-6' key={item.id}>
              <div className="questions">
                <Question index={index} data={item} errors={formErrors}/>
              </div>
            </div>
          )}
        </div>

        <div className="vert-offset-bottom-20 vert-offset-top-20">
          <div className="btn-toolbar pull-right">
            {allowBack && <BackButton onClick={onBack}/>}
            <ContinueButton onClick={this.perform}/>
          </div>
          <div className="clearfix" />
        </div>
      </form>
    );
  }
}
