import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from '../../PaymentSystem/Modal';

export default () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        show={showModal}
        setShow={setShowModal}
        paymentPath={'/account/card.json'}
        spinnerMessage={'Please wait while we update your card'}
        buttonTitle={'Update card'}
      />

      <Button bsStyle='primary' onClick={() => setShowModal(true)}>
        Update card details
      </Button>
    </>
  )
}
