import React, { Component, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import Attachment from './attachment';
import axios from 'axios';

export default class Attachments extends Component {
  constructor(props) {
    super(props);

    this.state = { newDocuments: [0] };

    this.formRef = React.createRef();
  }

  addDocument = (e) => {
    e.preventDefault();
    let newDocuments = this.state.newDocuments;
    newDocuments.push(0);
    this.setState(newDocuments)
  };

  removeNewDocument = (e, index) => {
    e.preventDefault();
    let { newDocuments } = this.state;
    newDocuments.splice(index, 1);
    this.setState(newDocuments)
  };

  getData = () => {
    return new FormData(this.formRef.current);
  };

  handleUpdate = () => {
    const { bookingId } = this.props;
    let data = this.getData();

    const config = { headers: { 'content-type': 'multipart/form-data' } };

    axios
      .post(`${window.location.pathname}/attachments.json`, data, config)
      .then((response) => {
        PubSub.publish(`Booking.Updated`);
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  };

  render() {
    const { data, onlyView } = this.props;
    const { newDocuments } = this.state;

    if (onlyView && !(Array.isArray(data) && data.length > 0)) return null;

    return (
      <div className="section">
        <div className="section-header">
          <h3>Documents</h3>
        </div>
        <div className="form-group file form-inline">
          <form ref={this.formRef}>
            <div className="documents">
              {data.map((document, index) =>
                <Attachment
                  key={document.id}
                  document={document}
                  index={index}
                  onlyView={onlyView}
                />
              )}

              {!onlyView &&
              <Fragment>
                {newDocuments.map((document, index) =>
                  <Attachment key={index} onRemove={this.removeNewDocument} index={index}/>
                )}

                <a className="add-question" href="#" onClick={this.addDocument}>Add document</a>
              </Fragment>
              }
            </div>
          </form>
        </div>

        {!onlyView &&
        <div className='pull-right'>
          <Button bsStyle='primary' onClick={this.handleUpdate}>Update</Button>
        </div>
        }

        <div className='clearfix'/>
      </div>
    )
  }
}