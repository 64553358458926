import React, { Component } from 'react';
import Formatted from '../../../formatted';

export default class Totals extends Component {
  render() {
  const {
    net_price,
    vat,
    gross_price
  } = this.props;

  return (
    <div className="bookings_reports_totals" style={{ marginBottom: 15 }}>
      <div>
        Total Net price: <strong><Formatted.Price price={net_price}/></strong>
      </div>
      <div>
        Total VAT: <strong><Formatted.Price price={vat}/></strong>
      </div>
      <div>
        Total Gross price: <strong><Formatted.Price price={gross_price}/></strong>
      </div>
    </div>
  )
}
}
