import React, { Component, Fragment } from 'react';
import FieldGroup from '../../smart/field_group';
import Fetch from "../fetch";
import moment from 'moment';

export default class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      venues: undefined,
      activities: undefined,
      venueActivityCategories: undefined
    };

    this.handlers = {};
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchVenues();
  }

  fetchVenues = () => {
    const { onReady } = this.props;

    Fetch
      .venues()
      .then(response => {
        const venues = response.data.data;
        const venue_id = response.data.data[0].id;
        this.setState({ venues, venue_id, ready: true }, () => {
          this.fetchActivities();
          this.fetchActivityCategories();
          onReady();
        });
      });
  };

  fetchActivities = () => {
    const { venue_id } = this.state;

    Fetch
      .calendarActivities({ venue_id, skip_pagination: true })
      .then(response => {
        const activities = response.data.data;
        this.setState({ activities });
      });
  };

  fetchActivityCategories = () => {
    const { venue_id } = this.state;

    Fetch
      .calendarActivityCategories({ venue_id })
      .then(response => {
        const activityCategories = response.data.data;
        this.setState({ activityCategories, ready: true });
      });
  };

  handleChange = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        this.setState({ [name]: event.target.value }, () => {
          if (name === 'venue_id') {
            this.setState({ activities: undefined, activity_id: null }, () => {
              this.fetchActivities();
              this.fetchActivityCategories();
            });
          }

          const { onChanged } = this.props;
          if (onChanged) onChanged();
        });
      };
    }

    return this.handlers[name];
  };

  render() {
    const { venues, activities, activityCategories } = this.state;

    return (
      <Fragment>
        <FieldGroup
          label="Venue:"
          type="select"
          name="venue_id"
          className='venue-input'
          options={venues}
          onChange={this.handleChange('venue_id')}
        />

        <FieldGroup
          label="Activities:"
          type="select"
          name="activity_id"
          className='activity-input'
          valueKey='id'
          titleKey='internal_reference'
          emptyOption={{ id: '', internal_reference: 'All activities' }}
          options={activities}
          onChange={this.handleChange('activity_id')}
        />

        {!this.state.activity_id &&
        <FieldGroup
          label="Categories:"
          type="select"
          name='activity_category_id'
          className='activity-category-input'
          emptyOption={{ id: '', title: 'All categories' }}
          options={activityCategories}
          onChange={this.handleChange('activity_category_id')}
        />
        }

        <FieldGroup
          label="Date:"
          type="date2"
          name="event_date"
          className='date-input'
          format="ddd, D MMM YYYY"
          defaultValue={moment().toDate()}
          onRealChange={this.handleChange('event_date')}
        />

        {!this.state.activity_id &&
        <FieldGroup
          label="Activity status:"
          type="select"
          name="activity_status"
          className='activity-status-input'
          valueKey='id'
          defaultValue='published'
          options={[
            { id: 'all', title: 'All activities' },
            { id: 'published', title: 'Published activities' },
            { id: 'unpublished', title: 'Unpublished activities' }
          ]}
          onChange={this.handleChange('activity_status')}
        />
        }
      </Fragment>
    );
  }
}
