import React, { Component } from 'react';
import { DebounceInput } from 'react-debounce-input';

export default class HeaderSearch extends Component {
  render() {
    const { onChangeSearch } = this.props;

    return (
      <div className="form-inline">
        <div className="form-group">
          <label>Search</label>
          <DebounceInput
            name='terms'
            className="form-control"
            minLength={1}
            debounceTimeout={150}
            onChange={onChangeSearch}
          />
        </div>
      </div>
    );
  }
}