import React, { useState, useEffect } from 'react';
import FieldGroup from "../smart/field_group";
import axios from 'axios';

export default ({ bill, onUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [paymentOptionUid, setPaymentOptionUid] = useState(bill.payment_option_uid);

  useEffect(() => { setPaymentOptionUid(bill.payment_option_uid) }, [bill.payment_option_uid])

  const handleChangePaymentOption = (paymentOptionUid) => {
    let data = { payment_option_uid: paymentOptionUid };

    setPaymentOptionUid(paymentOptionUid);
    setLoading(true);

    axios
      .put(`/bookings/${bill.booking_id}/bills/${bill.id}/payment_option`, data)
      .then(() => {
        setLoading(false);
        onUpdate();
      })
      .catch((error) => console.log(error));
  };

  if (!bill.payment_options) return null;

  return (
    <div className="pull-right payment-option-field-wrapper">
      <FieldGroup
        label='Payment option:'
        type="next-radio-group"
        valueKey="uid"
        name="payment_option"
        value={paymentOptionUid}
        options={bill.payment_options.data}
        onChange={handleChangePaymentOption}
      />
    </div>
  )
}
