import React, { Component } from 'react';

import Tabs from '../areas/tabs';
import Info from './info';
import Images from '../images/block';
import Vats from '../vats/block';
import PromoCodes from '../promo_codes/block';
import Documents from '../documents/block';
import Questions from '../questions/block';
import MetaTags from '../meta_tags/block';
import InvoiceCategories from '../invoice_categories/block';

export default class Show extends Component {
  constructor(props) {
    super(props);
    this.state = { data: { title: 'Venue' } };
  }

  updateData = (data) => { this.setState({ data })};

  render() {
    const { title } = this.state.data;

    return (
      <div>
        <h2>{title}</h2>
        <Tabs
          list={[
            {
              name: 'info',
              title: "Information",
              component: (
                <Info
                  name="Venue"
                  updatePageData={this.updateData}
                  path={window.location.pathname}
                />
              )
            },
            {
              name: 'images',
              title: "Images",
              component: (
                <Images
                  path={`${window.location.pathname}/images`}
                  name='Image'
                />
              )
            },
            {
              name: 'pricing',
              title: "Pricing",
              component: (
                <div>
                  <Vats path={`${window.location.pathname}/vat`}/>
                  <PromoCodes
                    title="Promo Code"
                    name="PromoCode"
                    path={`${window.location.pathname}/promo_codes`}
                  />
                </div>
              )
            },
            {
              name: 'documents',
              title: "Documents",
              component: (
                <Documents
                  path={`${window.location.pathname}/documents`}
                  title="Document"
                  name='Document'
                />
              )
            },
            {
              name: 'questions',
              title: "Questions",
              component: (
                <Questions
                  path={`${window.location.pathname}/questions`}
                  title="Question"
                  name='Question'
                />
              )
            },
            {
              name: 'invoice_categories',
              title: "Invoice Categories",
              component: (
                <InvoiceCategories
                  path={`${window.location.pathname}/invoice_categories`}
                  title="Invoice Category"
                  name='InvoiceCategory'
                />
              )
            },
            {
              name: 'meta_tags',
              title: "MetaTags",
              show: false,
              component: (
                <MetaTags
                  title='Meta tags'
                  name='MetaTags'
                  path={`${window.location.pathname}/meta_tags`}
                />
              )
            }
          ]}
        />
      </div>
    );
  }
}
