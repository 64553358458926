import React, { Component, Fragment } from 'react';
import Formatted from "../../../formatted";

export default class TableFooter extends Component {
  render() {
    const {
      total,
      vat_total,
      gross_total,
      sh_commission,
      venue_payment_fees,
      net_total,
      sh_vat,
      gross_sh_total,
      total_amount_remitted
    } = this.props;

    return (
      <Fragment>
        <tr className='footer-row'>
          <td><strong>Total:</strong></td>
          <td/>
          <td/>
          <td className="text-center"><Formatted.Price price={total} brackets={true}/></td>
          <td className="text-center"><Formatted.Price price={vat_total} brackets={true}/></td>
          <td className="text-center"><Formatted.Price price={gross_total} brackets={true}/></td>
          <td className="text-center"><Formatted.Price price={sh_commission} brackets={true}/></td>
          <td className="text-center"><Formatted.Price price={venue_payment_fees} brackets={true}/></td>
          <td className="text-center"><Formatted.Price price={net_total} brackets={true}/></td>
          <td className="text-center"><Formatted.Price price={sh_vat} brackets={true}/></td>
          <td className="text-center"><Formatted.Price price={gross_sh_total} brackets={true}/></td>
          <td className="text-center"><Formatted.Price price={total_amount_remitted} brackets={true}/></td>
        </tr>

        <tr className='footer-row footer-space' colSpan="12"/>
      </Fragment>
    )
  }
}