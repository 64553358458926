import React, { Component, Fragment } from 'react';
import FieldGroup from "../smart/field_group";
import axios from 'axios';

export default class PromoCode extends Component {
  constructor() {
    super();

    this.state = { error: '' };
  }

  handleChangePromoCode = (e) => {
    e.preventDefault();
    const { bill } = this.props;

    let data = { code: this.inputRef.value };

    axios
      .put(`/bookings/${bill.booking_id}/bills/${bill.id}/promo_code`, data)
      .then(() => this.props.onUpdate())
      .catch((error) => {
        if (error.response && error.response.status === 422) {
          this.setState({ error: error.response.data.errors.promo_code });
        }
        console.log(error);
      });
  };

  render() {
    const { bill } = this.props;
    const { error } = this.state;

    if (!bill.payment_options) return null;

    return (
      <Fragment>
        <div className="pull-right promo-code-field-wrapper">
          <FieldGroup
            inputRef={ref => { this.inputRef = ref }}
            label="Promo code:"
            custom={{
              appendInputGroup: <a href='#' onClick={this.handleChangePromoCode}>Apply</a>
            }}
            error={error}
            onChange={() => this.setState({ error: null })}
          />
        </div>
      </Fragment>
    )
  }
}
