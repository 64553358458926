import React, { Component } from 'react';
import classnames from 'classnames';

export default class CheckoutMenu extends Component {
  linkClick(e, index) {
    e.preventDefault();
    const { step, onChangeStep } = this.props;

    if (index < step) onChangeStep(index);
  }

  render() {
    const items = [
      { title: 'Attendees Information' },
      { title: 'Further Information' },
      { title: 'Terms and Conditions' },
      { title: 'Detailed Summary' },
    ];

    const { step } = this.props;

    return (
      <ul className="nav nav-pills nav-stacked checkout-navigation">
        {items.map((item, index) => {
          let liClass = classnames({
            'active': step === index,
            'disabled': step < index
          });

          return (
            <li key={index} className={liClass} role="presentation">
              <a href="#" onClick={(e) => this.linkClick(e, index)}>{item.title}</a>
            </li>
          )
        })}
      </ul>
    )
  }
}