import React, { Component, Fragment } from 'react';
import DataItem from './data_item';

export default class UserDetails extends Component {
  render() {
    const { data } =  this.props;

    if (!data) return null;

    const { name, address, postcode, phone, organisation, email } = data;

    return (
      <Fragment>
        <h5>USER DETAILS</h5>

        <ul className="invoice-list">
          <DataItem title="Name">{name}</DataItem>
          <DataItem title="Organisation">{organisation}</DataItem>
          <DataItem title="Address">{address}</DataItem>
          <DataItem title="Postcode">{postcode}</DataItem>
          <br />
          <DataItem title="Phone">{phone}</DataItem>
          <DataItem title="Email"><a href={`mailto:${email}`}>{email}</a></DataItem>
        </ul>
      </Fragment>
    )
  }
}
