import React from 'react';
import Modal from './modal';
import SmartBlock from '../../smart/block';
import VisibleOnHomepageButton from "./visible_on_homepage_button";

export default class Block extends SmartBlock {
  renderTable() {
    const { data } = this.state;
    if (!(Array.isArray(data) && data.length > 0)) return null;

    return (
      <table className="table table-striped table-sm">
        <thead>
        <tr>
          <th></th>
          <th>Title</th>
          <th style={{ width: 100 }}>Homepage</th>
          <th style={{ width: 100 }}/>
        </tr>
        </thead>
        <tbody>
        {data.map((item, index) =>
          <tr key={index}>
            <td style={{ width: 1 }}><img src={item.thumb}/></td>
            <td>{item.title}</td>
            <td className="text-center"><VisibleOnHomepageButton {...item} /></td>
            <td>{this.renderItemActions(item)}</td>
          </tr>
        )}
        </tbody>
      </table>
    )
  }


  render() {
    return (
      <div>
        <Modal {...this.props} />
        {this.renderTable()}
      </div>
    )
  }
}

