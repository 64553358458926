import React from 'react';
import Modal from './modal';
import SmartBlock from '../../smart/block';
import InternalNoteLabel from "./internal_note_label";
import { Button } from 'react-bootstrap';

export default class Block extends SmartBlock {
  renderTable() {
    let { data, included } = this.state;

    if (!(Array.isArray(data) && data.length > 0)) return null;

    return (
      <table className="table table-bordered">
        <tbody>
        {data.map((item, index) =>
          <tr key={index}>
            <td style={{borderRight: 'none'}}>
              <div>
                {item.text}
              </div>
              <div>
                <input type="checkbox" checked={item.allow_options} readOnly/>
                Allow users to select answer from Yes/No/Not applicable options
              </div>
              <div>
                <input type="checkbox" checked={item.allow_yes_no_options} readOnly/>
                Allow users to select answer from Yes/No options
              </div>
              <div>
                <input type="checkbox" checked={item.allow_documents} readOnly/>
                Allow users to upload documents
              </div>
              <div>
                <input type="checkbox" checked={item.allow_comment} readOnly/>
                Allow users to leave additional comments
              </div>
            </td>
            <td style={{borderLeft: 'none'}} className='text-right'>
              <div>
                <InternalNoteLabel value={item.link_to_internal_notes_enabled}/>
              </div>
              <div>{item.comment}</div>
            </td>
            <td style={{width: 100}}>
              {included.current_areal_type !== item.areal_type &&
                <div className="label label-warning" style={{display: 'block', whiteSpace: 'inherit'}}>
                  {item.areal_type} question
                </div>
              }

              {included.current_areal_type === item.areal_type &&
                <div className="btn-group">
                  <Button bsSize='xs' bsStyle='primary' onClick={() => this.handleEdit(item.id)}>Edit</Button>
                  <Button bsSize='xs' bsStyle='danger' onClick={() => this.handleDelete(item.id)}>Delete</Button>
                </div>
              }
            </td>
          </tr>
        )}
        </tbody>
      </table>
    )
  }

  render() {
    return (
      <div>
        <Modal {...this.props} />

        <div className="panel panel-default panel-block">
          <div className="panel-heading">
            <h3 className="pull-left">Questions</h3>
            <div className="btn-group pull-right">
              <a className="btn btn-primary" onClick={this.handleNew}>Add question</a>
            </div>
            <div className="clearfix"/>
          </div>
          <div className="panel-body panel-body-with-table">
            <div className="table-box">
              {this.renderTable()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

