import React, { Component, Fragment } from 'react';
import BookingShow from '../../bookings/show';
import Modal from './modal';
import ActionsCell from './actions_cell';
import MessageModal from "../../messages/modal";
import AttachmentsListModal from "../attachments/list_modal";

const HeaderInfo = () => (
  <Fragment>
    <p>You will find all the relevant details in relation to the booking below. This includes:</p>

    <ul>
      <li>a detailed order summary;</li>
      <li>payment information; and</li>
      <li>user details; and</li>
      <li>all the answers to your venue questions.</li>
    </ul>
    <p/>

    <p>
      Any amendments to the booking can be proposed via the amend booking button. Should a booking need 
      to be cancelled entirely, this is done via the cancel booking button.
    </p>
    
    <p>
      If you require any further information, please message the venue directly.
    </p>
  </Fragment>
);

export default class Show extends Component {
  renderActions(row) {
    return (
      <div className="vert-offset-bottom-10">
        <ActionsCell hideViewBooking={true} row={row} pullLeft={true}/>
      </div>
    )
  }

  render() {
    return (
      <Fragment>
        <Modal
          name="Booking"
          path="/admin/bookings"
          title="Booking"
        />

        <MessageModal
          name='Message'
          path='/admin/messages'
          title="Message"
        />

        <AttachmentsListModal
          name='ListAttachments'
          path='/admin/bookings'
          title="Document manager"
        />

        <BookingShow
          headerInfo={<HeaderInfo/>}
          url={`${window.location.pathname}.json`}
          renderActions={this.renderActions}
          onlyViewAttachments={true}
        />
      </Fragment>
    )
  }
}
