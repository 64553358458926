import React, { Component } from 'react';
import Inboxes from "./inboxes";
import Interlocutors from "./interlocutors";
import Messages from "./messages";
import Filter from "./filter";
import NewMessageButton from './new_message_button';
import axios from 'axios';
import { Can } from '../ability_context';
import PubSub from 'pubsub-js';

export default class Chat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inboxes: undefined,
      senders: undefined,
      inbox: undefined,
      sender: undefined,
      messages: undefined
    };
  }

  componentDidMount() {
    this.fetchInboxes();
    PubSub.subscribe('GroupMessage.Created', this.fetchMessages);
  }

  changeInbox = (inbox) => {
    this.setState({ inbox, messages: null, sender: null }, this.fetchInterlocutors);
  };

  changeInterlocutor = (interlocutor) => {
    this.setState({ interlocutor, messages: null }, this.fetchMessages);
  };

  fetchInboxes = (options) => {
    const { path } = this.props;
    const { skipChangeInbox } = (options || {});

    axios
      .get(`${path}/inboxes.json`)
      .then(response => {
        const inboxes = response.data.data;
        this.setState({ inboxes }, () => {
          if (!skipChangeInbox) {
            if (inboxes && inboxes.length > 0) {
              this.changeInbox(inboxes[0])
            }
          } else {
            this.fetchInterlocutors({ silent: true })
          }
        });
      })
  };

  fetchInterlocutors = (params) => {
    const { silent } = params || {};
    const { inbox } = this.state;
    const { path } = this.props;

    if (!inbox) return null;

    let query_sting = $.param({ inbox_type: inbox.type, inbox_id: inbox.id });

    axios
      .get(`${path}/interlocutors.json?${query_sting}`)
      .then((response) => {
        const interlocutors = response.data.data;
        this.setState({ interlocutors }, () => {
          if (silent === true) return true;
          if (interlocutors && interlocutors.length > 0) {
            this.changeInterlocutor(interlocutors[0])
          }
        });
      })
  };

  fetchMessages = () => {
    const { inbox, interlocutor } = this.state;
    const { path } = this.props;

    if (!inbox || !interlocutor) return null;

    let query_sting = $.param({
      inbox_type: inbox.type,
      inbox_id: inbox.id,
      interlocutor_type: interlocutor.type,
      interlocutor_id: interlocutor.id
    });

    axios
      .get(`${path}.json?${query_sting}`)
      .then((response) => {
        const messages = response.data.data;
        const included = response.data.included;

        this.setState({ messages, included }, () => {
          this.fetchInboxes({ skipChangeInbox: true });
          PubSub.publish('UpdateUnreadMessagesCount', {});
        });
      })
  };

  handleSendMessage = () => {
    this.fetchMessages();
    this.fetchInterlocutors({ silent: true });
  };

  render() {
    const { path } = this.props;
    const { inboxes, inbox, interlocutors, interlocutor, messages, included } = this.state;

    return (
      <div className="row row-eq-height" id="conversations">
        <div className="col-lg-3 left-column" style={{ paddingTop: 0 }}>

          <Can I="newMessageModal" a="AdminMessages">
            <NewMessageButton/>
          </Can>
          {/*<Filter/>*/}
          <Inboxes
            data={inboxes}
            inbox={inbox}
            onChange={this.changeInbox}
          />

          <div className="clearfix"/>

          <Interlocutors
            data={interlocutors}
            inbox={inbox}
            inboxes={inbox}
            interlocutor={interlocutor}
            onChange={this.changeInterlocutor}
          />
        </div>

        <div className="col-lg-9 right-column">
          <Messages
            data={messages}
            included={included}
            interlocutor={interlocutor}
            inbox={inbox}
            onSendMessage={this.handleSendMessage}
            path={path}
          />
        </div>
      </div>
    );
  }
}
