import React, { Component } from 'react';
import Form from "./form";

const slides = [
  require('images/slider001.jpg'),
  require('images/slider002.jpg'),
  require('images/slider003.jpg'),
  require('images/slider004.jpg'),
  require('images/slider005.jpg'),
  require('images/slider006.jpg')
];

export default class Search extends Component {
  constructor(props) {
    super(props);

    this.state = { slideNumber: 0 };
  }

  componentDidMount() {
    $("#home-form").backstretch(slides, { duration: 3000, fade: 750 })
  }

  render() {
    return (
      <div id="home-form" style={{ backgroundColor: '#657a91' }}>
        <div className="container" id="top">
          <h1>Find and book an activity in your area now</h1>
          <h2>Activity hire made simple: search, book and pay in minutes</h2>
          <Form/>
        </div>
      </div>
    )
  }
};