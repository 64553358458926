import React  from 'react';
import axios from 'axios';
import PubSub from 'pubsub-js';
import FieldGroup from '../../smart/field_group';
import SmartModal from '../../smart/modal';
import { toastr } from 'react-redux-toastr';

export default class ResetPasswordModal extends SmartModal {
  showResetPassword = () => {
    this.setState({ loading: false })
  };

  resetPassword = () => {
    let data = this.getData();

    axios
      .post('/users/password.json', data)
      .then((response) => {
        this.handleClose();
        PubSub.publish(`${this.name}.ReseedPassword`, {});
        toastr.success('', 'You will receive an email with instructions on how to reset your password in a few minutes.')
      })
      .catch((error) => {
        console.log(error);
      });
  };

  renderResetPasswordForm = () => {
    if (this.state.loading) return <div className="text-center">Loading....</div>;

    return (
      <div className="form">

        <div className="text-center">
          We'll send password reset instructions.
        </div>

        <FieldGroup
          type="text"
          label={false}
          placeholder="Email"
          name="user[email]"
          defaultValue={this.formValue("title")}
          error={this.formError("title")}
        />
      </div>
    )
  };

  renderResetPasswordButtons = () => (
    <div className="form">
      <div className="sbm w305">
        <button type="button" onClick={this.resetPassword}>Reset password</button>
      </div>
    </div>
  );
}
