import React, { Component } from 'react';
import classnames from 'classnames';

export default class Gallery extends Component {
  constructor(props) {
    super(props);

    this.imageGalleryRef = React.createRef();
    this.lightGalleryRef = React.createRef();
  }

  componentDidMount() {
    $(this.lightGalleryRef.current).lightGallery();

    $(this.imageGalleryRef.current).lightSlider({
      item: 1,
      loop: true,
      slideMargin: 0,
      pager: false
    });

    $(this.imageGalleryRef.current).lightGallery();
  }

  componentWillUnmount() {
    //$(this.lightGalleryRef.current).data('lightGallery').destroy(true);
    //$(this.imageGalleryRef.current).data('lightGallery').destroy(true);
  }

  render() {
    const { images } = this.props;

    // return (
    //   <div className="row">
    //     <div className="col-lg-12">
    //       <div style={{ width: 774, height: 530, background: '#eee', float: 'left' }}>
    //         <ul id="imageGallery" ref={this.imageGalleryRef}>
    //           {images.map((image, index) =>
    //             <li key={index} data-src={image.src} data-thumb={image.thumb}>
    //               <img className="img-responsive" src={image.src} />
    //             </li>
    //           )}
    //         </ul>
    //       </div>

    //       <div style={{ width: 380, float: 'right' }}>
    //         {/*<div style={{ height: 258, background: 'blue', marginBottom: 14 }}>*/}
    //         {/*</div>*/}

    //         {/*<div style={{ height: 258, background: 'green' }}>*/}
    //         {/*</div>*/}

    //         <div className="lSSlideOuter custom">
    //           <ul id="lightGallery" className="lightGallery lSPager lSGallery" ref={this.lightGalleryRef}>
    //             {images.slice(0, 3).map((image, index) => {
    //                 return (
    //                   <li key={index} className={classnames({'hide': index === 0})} data-src={image.src}>
    //                     <img alt="thumbnail" src={image.thumb}/>
    //                   </li>
    //                 )
    //               }
    //             )}
    //           </ul>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // );

    return (
      <div className="row">
        <div className="col-xs-12 col-sm-8 main-photo">
          <ul id="imageGallery" ref={this.imageGalleryRef}>
            {images.map((image, index) =>
              <li key={index} data-src={image.src} data-thumb={image.thumb}>
                <img className="img-responsive" src={image.src} />
              </li>
            )}
          </ul>
        </div>

        <div className="col-xs-12 col-sm-4 thumb-photo">
          <div style={{ marginLeft: -20 }}>
            <div className="lSSlideOuter custom">
              <ul id="lightGallery" className="lightGallery lSPager lSGallery" ref={this.lightGalleryRef}>
                {images.slice(0, 3).map((image, index) => {
                  console.log(image, index);
                    return (
                      <li key={index} className={classnames({'hide': index === 0})} data-src={image.src}>
                        <img alt="thumbnail" src={image.thumb}/>
                      </li>
                    )
                  }
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}