import React, { Component } from 'react';
import ActivityCategory from '../activity_categories/item';


export default class ActivityCategories extends Component {
  render() {
    const { list, venueId } = this.props;

    return (
      <div className="activities-block">
        <h3>
          <img src={require("images/facil-icon.png")} />
          Activities
        </h3>
        <div className="row row-gallery">
          {list.map((activity_category, index) =>
            <div key={index} className="col-md-4">
              <ActivityCategory venueId={venueId} {...activity_category}/>
            </div>
          )}
        </div>
      </div>
    )
  }
}