import React, { Component } from 'react';
import AccessLabel from './access_label';

export default class AccessesCell extends Component {
  render() {
    const { accesses } = this.props;

    if (accesses === null) return null;

    return (
      <div style={{ padding: 0, margin: -1 }}>
        <table className="table table-striped table-bordered table-xs" style={{ marginBottom: 0 }}>
          <thead>
          <tr>
            <th>Venue</th>
            <th>Access</th>
          </tr>
          </thead>
          <tbody>
          {accesses.map((access, index) =>
            <tr key={index}>
              <td>{access.venue_title}</td>
              <td className="text-center"><AccessLabel value={access.access_type}/></td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    )
  }
};