import React, { Component } from 'react';
import axios from 'axios';

const menuItems = [
  { title: 'About us', href: '/about_us' },
  { title: 'How it works', href: '/how_it_works' },
  { title: 'Contact & Support', href: '/contact_and_support' },
  { title: 'Terms & Conditions', href: '/terms_and_conditions' },
  { title: 'Privacy Policy', href: '/privacy_policy' },
];

export default class Show extends Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    this.fetchInfoPage();
  }

  fetchInfoPage = () => {
    axios
      .get(`${window.location.pathname}.json`)
      .then((response) => {
        const { data } = response.data;
        this.setState({ data })
      })
      .catch((error) => {
        console.log(error);
      })
  };

  renderMainContext() {
    const { data } = this.state;
    if (data === undefined) return null;

    const { title, content } = data;

    return (
      <div className="main_content">
        <h1>{title}</h1>
        <div className="img-responsive">
          <div dangerouslySetInnerHTML={{ __html: content }}/>
        </div>
      </div>
    )
  }

  render() {
    return (
      <section id="page">
        <div className="container">
          <div className="left_nav">
            <h4>Company</h4>
            <ul>
              {menuItems.map((item, index) => {
                let className = (item.href === window.location.pathname) ? 'active' : '';
                return <li key={index}><a className={className} href={item.href}>{item.title}</a></li>
              })}
            </ul>
          </div>
          {this.renderMainContext()}
        </div>
      </section>
    );
  }
}
