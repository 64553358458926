import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Modal from './modal';
import SmartBlock from '../../smart/block';
import Row from './row';

export default class Block extends SmartBlock {
  renderTable() {
    const { data } = this.state;

    if (!data) return null;
    if (data.length === 0) return null;

    return (
      <table className="table table-striped table-bordered table-xs">
        <thead>
          <tr>
            <th>Code</th>
            <th>Expiration date</th>
            <th>Percent</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {data.map((item) => <Row key={item.id} {...item} onEdit={this.handleEdit} onDelete={this.handleDelete}/>)}
        </tbody>
      </table>
    )
  }

  render() {
    return (
      <div>
        <Modal {...this.props} />

        <div className="panel panel-default panel-block">
          <div className="panel-heading">
            <h3 className="pull-left">Promo Codes</h3>
            <div className="btn-group pull-right">
              <a className="btn btn-primary" onClick={this.handleNew}>Add promo code</a>
            </div>
            <div className="clearfix"/>
          </div>
          <div className="panel-body panel-body-with-table">
            <div className="table-box">
              {this.renderTable()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

