import React, { useRef } from 'react';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import axios from 'axios';
import FieldGroup from "../smart/field_group";

const popoverTop = (
  <Popover id="popover-positioned-top-overall-discount">
    As all dates are in the past, it is not possible to use the price adjustment section
  </Popover>
);

export default ({ disabled_price_adjustment, path, id, onUpdate, overall_discount_item }) => {
  const formRef = useRef();
  const { amount, reason } = overall_discount_item;

  const handleUpdate = () => {
    const data = new FormData(formRef.current);

    axios
      .put(`${path}/bills/${id}.json`, data)
      .then((response) => { onUpdate() })
      .catch((error) => console.log(error));
  };

  return (
    <div className="be_boxed vert-offset-bottom-20">
      <form className="form-horizontal" ref={formRef}>
        <input type="hidden" name="bill[overall_discount_measure]" value="sum"/>

        <FieldGroup
          formType="inline"
          type="text"
          label="Venue price adjustment"
          name="bill[overall_discount]"
          disabled={disabled_price_adjustment}
          defaultValue={amount}
          custom={{
            colLabelStyle: { width: 225 },
            colInputStyle: { width: 160 }
          }}
        />

        <FieldGroup
          formType="inline"
          type="text"
          label="Reason for price adjustment"
          name="bill[overall_discount_reason]"
          defaultValue={reason}
          disabled={disabled_price_adjustment}
          custom={{
            colLabelStyle: { width: 225 }
          }}
        />

        <div className="pull-right">
          {disabled_price_adjustment ?
            <OverlayTrigger trigger="hover" placement="top" overlay={popoverTop}>
              <Button bsStyle="default">
                Update
              </Button>
            </OverlayTrigger>
            :
            <Button bsStyle="default" onClick={handleUpdate}>
              Update
            </Button>
          }
        </div>

        <div className="clearfix" />
      </form>
    </div>
  );
}