import React, { Component } from 'react';
import { DateCell, PercentCell } from "../../smart/cells";
import StatusButton from "./status_button";
import { Button } from 'react-bootstrap';

const StatusLabel = props => {
  const enabled = <span className="label label-promo-code-enabled">Enabled</span>;
  const disabled = <span className="label label-promo-code-disabled">Disabled</span>;
  const expired = <span className="label label-promo-code-disabled">Expired</span>;

  return (props.expired) ? expired : ((props.enabled) ? enabled : disabled);
};

export default class Row extends Component {
  render() {
    const {
      id,
      code,
      expired_at,
      percent,
      enabled,
      expired,
      onEdit,
      onDelete
    } = this.props;

    return (
      <tr>
        <td>
          {code}
        </td>
        <td style={{ width: 120 }}>
          <DateCell date={expired_at} />
        </td>
        <td style={{ width: 50 }}>
          <PercentCell value={percent} />
        </td>
        <td style={{ width: 80 }} className='text-center'>
          <StatusLabel enabled={enabled} expired={expired} />
        </td>
        <td style={{width: 145}}>
          <div className="btn-group">
            <StatusButton id={id} enabled={enabled} />

            <Button bsStyle="primary" bsSize="xsmall" onClick={() => onEdit(id)}>Edit</Button>
            <Button bsStyle="danger" bsSize="xsmall" onClick={() => onDelete(id)}>Delete</Button>
          </div>
        </td>
      </tr>
    )
  }
}