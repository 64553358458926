import React from 'react';
import SmartModal from '../../smart/modal';
import { FormGroup, HelpBlock } from 'react-bootstrap'

export default class Modal extends SmartModal {
  renderForm() {
    return (
      <div>
        <SmartModal.FieldGroup
          type="text"
          label="Title"
          name="region[title]"
          defaultValue={this.formValue('title')}
          error={this.formError('title')}
        />
      </div>
    );
  }
}
