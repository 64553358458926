import React from 'react';

const Social = props => {
  return (
    <dl>
      <dt>Follow Us</dt>
      <dd>
        <ul className="soc">
          <li>
            <a href="https://www.facebook.com/Schoolhire">
              <img alt="Facebook" src={require('images/fb_ico.png')}/>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/SchoolHire">
              <img alt="Twitter" src={require('images/tw_ico.png')}/>
            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/9436244?trk=tyah&amp;trkInfo=clickedVertical%3Acompany%2Cidx%3A1-1-1%2CtarId%3A1431642034963%2Ctas%3Aschoolhire">
              <img alt="LinkedIn" src={require('images/in_ico.png')}/>
            </a>
          </li>
        </ul>
      </dd>
    </dl>
  )
};

export default Social;