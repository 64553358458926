import React, { Component, Fragment } from 'react';
import Filter from './filter';
import WeekCalendar from "./week_calendar";
import FormToObject from 'form-to-object';
import axios from 'axios';
import moment from 'moment';
import MessageModal from '../../messages/modal';

export default class Table extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      filterData: null,
      date: moment().toDate()
    };

    this.filterRef = React.createRef();
  }

  fetchData() {
    const data = FormToObject(this.filterRef.current);

    axios
      .get('/admin/calendar.json', {
        params: { calendar: { filter: data } },
        paramsSerializer: function (params) {
          return jQuery.param(params)
        }
      })
      .then((response) => this.setState({ data: response.data.data }))
  }

  handleFilterReady = () => {
    this.fetchData()
  };

  handleFilterChanged = () => {
    this.fetchData()
  };

  render() {
    return (
      <Fragment>
        <MessageModal
          name='Message'
          path='/admin/messages'
          title="Message"
        />

        <form ref={this.filterRef} className="form-inline normal-font-weight-labels calendar-filter">
          <Filter
            date={this.state.date}
            onReady={this.handleFilterReady}
            onChanged={this.handleFilterChanged}
          />
        </form>
        <div className="clearfix"/>
        <div className="vert-offset-bottom-20">
          <WeekCalendar data={this.state.data}/>
        </div>
      </Fragment>
    );
  }
}
