import React from 'react';
import Modal from './modal';
import SmartBlock from '../../smart/block';

export default class Block extends SmartBlock {
  renderTable() {
    const { data, included } = this.state;

    if (!(Array.isArray(data) && data.length > 0)) return null;

    return (
      <table className="table table-striped table-bordered">
        <thead>
        <tr>
          <th>Title</th>
          <th>Comment</th>
          <th>File</th>
          <th style={{width: 100}}/>
        </tr>
        </thead>
        <tbody>
        {data.map((item, index) =>
          <tr key={index}>
            <td>{item.title}</td>
            <td>{item.comment}</td>
            <td>
              {item.file &&
                <div>
                  <a className="btn btn-xs btn-warning pull-right" href={item.file.url} target="_blank">
                    Download File
                  </a>
                  {item.file.name}
                </div>
              }
            </td>
            <td>
              {included.current_areal_type !== item.areal_type &&
                <div className="label label-warning" style={{display: 'block', whiteSpace: 'inherit'}}>
                  {item.areal_type} document
                </div>
              }
              {included.current_areal_type === item.areal_type &&
                <div className="btn-group">
                  <a className="btn btn-xs btn-primary" onClick={(e) => this.handleEdit(item.id)}>Edit</a>
                  <a className="btn btn-xs btn-danger" onClick={(e) => this.handleDelete(item.id)}>Delete</a>
                </div>
              }
            </td>
          </tr>
        )}
        </tbody>
      </table>
    )
  }

  render() {
    return (
      <div>
        <Modal {...this.props}/>

        <div className="panel panel-default panel-block">
          <div className="panel-heading">
            <h3 className="pull-left">Documents</h3>
            <div className="btn-group pull-right">
              <a className="btn btn-primary" onClick={this.handleNew}>Add document</a>
            </div>
            <div className="clearfix"/>
          </div>
          <div className="panel-body panel-body-with-table">
            <div className="table-box">
              {this.renderTable()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

