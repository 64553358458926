import React, { Component } from 'react';
import { PriceCell } from '../smart/cells';
import Formatted from '../formatted';
import Info from './info';
import InternalNotesInfo from '../admin/internal_notes/info';
import classnames from 'classnames';
import StatusLabel from './status_label';

export default class BookingItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showInfo: false,
      removed: false
    }
  }

  handleRemoveItem = () => {
    const { id, onRemoveItem } = this.props;
    if (onRemoveItem) {
      this.setState({ removed: true }, () => { onRemoveItem(id) })
    }
  };

  handleEditItem = () => {
    const { id, onEditItem } = this.props;
    if (onEditItem) onEditItem(id);
  };

  handleEditAttendeeItem = () => {
    const { id, onEditAttendeeItem } = this.props;
    if (onEditAttendeeItem) onEditAttendeeItem(id);
  };

  toggleInfo = () => {
    this.setState({ showInfo: !this.state.showInfo })
  };

  render() {
    const {
      id,
      attendee_name,
      attendee_birthday,
      booking_option_title,
      activity_internal_reference,
      start_date,
      status,
      price,
      schedule_items,
      extras,
      activity,
      can_be_removed,
      onRemoveItem,
      onEditItem,
      onEditAttendeeItem,
      extendedList,
      internalNotes
    } = this.props;

    const { showInfo, removed } = this.state;
    const extrasPresence = (Array.isArray(extras) && extras.length > 0);

    let classList = classnames(['booking-item']); //, { 'removed': removed }]);

    const canBeRemoved = can_be_removed || false;

    return (
      <tbody>
      <tr key={id} className={classList} data-id={id}>
        <td className="text-center" data-title="Attendee">
          {attendee_name} {internalNotes}
        </td>
        <td className="text-center" data-title="DoB">
          <Formatted.Date date={attendee_birthday} showWeekDay={false}/>
        </td>
        <td className="text-center"  data-title="Activity">
          {activity_internal_reference}
        </td>
        <td className="text-center" data-title="Booking option">
          <div>{booking_option_title}</div>
          {extrasPresence && <div>Extras</div>}
        </td>
        <td className="text-center" data-title="Start date">
          <Formatted.Date date={start_date}/>
        </td>
        <td className="text-center" data-title="Price">
          <div><PriceCell value={price}/></div>
          {extrasPresence &&
            <div>
              <PriceCell value={extras.reduce((total, extra) => total + parseFloat(extra.price), 0)}/>
            </div>
          }
        </td>
        <td className="text-center">
          <StatusLabel value={status}/>
        </td>
        <td className="text-center">
          {!extendedList &&
          <div className="btn-action btn-more" onClick={this.toggleInfo}/>
          }
          {onRemoveItem && canBeRemoved ?
            <div className="btn-action btn-remove" onClick={this.handleRemoveItem}/>
            : null
          }

          {onEditItem &&
          <div className="btn-action btn-edit" onClick={this.handleEditItem}/>
          }

          {onEditAttendeeItem &&
            <div className="btn-action btn-edit-attendee" onClick={this.handleEditAttendeeItem}/>
          }
        </td>
      </tr>

      {!internalNotes &&
      <Info
        show={showInfo || extendedList}
        schedule_items={schedule_items}
        extras={extras}
        activity={activity}
      />
      }

      {internalNotes &&
      <InternalNotesInfo schedule_items={schedule_items}/>
      }

      </tbody>
    )
  }
}