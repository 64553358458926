import React, { Component } from 'react';
import FieldGroup from '../../../smart/field_group';
import axios from 'axios';
import Fetch from "../../fetch";
import classnames from 'classnames';
import moment from 'moment';

export default class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ready: false,
      venues: undefined,
      venueId: undefined,
      time_period: 'this_month'
    };

    this.handlers = {};
  }

  componentDidMount() {
    const { onReady } = this.props;

    axios
      .all([Fetch.venues(),])
      .then(axios.spread((venuesData) => {
        const venues = venuesData.data.data;
        const venueId = (venues[0] || {}).id;
        this.setState({ ready: true, venues, venueId }, () => {
          if (onReady) onReady();
        });
      }));
  }

  handleChange = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        this.setState({ [name]: event.target.value }, () => {
          const { onChanged } = this.props;
          if (onChanged) onChanged()
        });
      };
    }

    return this.handlers[name];
  };

  render() {
    const { ready, venues, time_period } = this.state;

    if (!ready) return null;

    return (
      <div>
        <FieldGroup
          label="Venue:"
          type="select"
          name="filter[venue_id]"
          options={venues}
          onChange={this.handleChange('venue_id')}
        />

        <FieldGroup
          label="Period:"
          type="select"
          name="filter[time_period]"
          value={time_period}
          options={[
            { id: "today", title: "Today" },
            { id: "this_week", title: "This Week" },
            { id: "this_month", title: "This Month" },
            { id: "custom", title: "Custom" }
          ]}
          onChange={this.handleChange('time_period')}
        />

        <div className={classnames(['custom_dates', { 'hidden': time_period !== 'custom' }])}>
          <FieldGroup
            label="Start date:"
            type="date"
            defaultValue={moment().subtract(1, 'months').toDate()}
            name="filter[start_date]"
            onRealChange={this.handleChange('start_date')}
          />
          <FieldGroup
            label="End date:"
            type="date"
            defaultValue={moment().toDate()}
            name="filter[end_date]"
            onRealChange={this.handleChange('end_date')}
          />
        </div>
      </div>
    );
  }
}
