import React, { Component } from 'react';
import FieldGroup from '../smart/field_group';

export default class Form extends Component {
  render() {
    return (
      <form className="simple_form new_filter" id="new_filter">
        <FieldGroup
          type="radio-group"
          name="filter[flagged]"
          defaultValue=""
          options={[
            { id: '', title:  'All messages' },
            { id: '1', title:  'Flagged messages' }
          ]}
        />
      </form>
    );
  }
}