import React from 'react';
import SmartModal from '../../smart/modal';

export default class Modal extends SmartModal {
  renderForm() {
    return (
      <div>
        <SmartModal.FieldGroup
          type="text"
          label="Title"
          name="document[title]"
          defaultValue={this.formValue("title")}
          error={this.formError("title")}
        />
        <SmartModal.FieldGroup
          type="textarea"
          label="Comment"
          name="document[comment]"
          defaultValue={this.formValue("comment")}
          error={this.formError("comment")}
        />
        <SmartModal.FieldGroup
          type="file"
          name="document[attachment]"
          defaultValue={this.formValue("attachment")}
          error={this.formError("attachment")}
        />
      </div>
    );
  }
}
