import React, { useState } from 'react';
import CheckoutMenu from './checkout_menu';
import AttendeeQuestions from './attendee_questions/index';
import Questions from './questions/index';
import TermsAndConditions from './terms_and_conditions/index';
import DetailedSummary from './detailed_summary/index';
import Timer from './Timer';

export default () => {
  const [step, setStep] = useState(0);
  const [showTimer, setShowTimer] = useState(false);
  const [timer, setTimer] = useState(0);
  const [trigger, setTrigger] = useState(new Date());

  const changeStep = (step) => {
    setStep(step);
    window.scrollTo(0, 0);
  }

  const handleUpdateTimer = (seconds) => {
    setShowTimer(true);
    setTimer(seconds);
    setTrigger(new Date());
  }

  const handleTimerExpire = () => window.location.href = '/';

  const showPage = () => {
    const params = {
      onBack: () => changeStep(step - 1),
      onNext: () => changeStep(step + 1),
      bookingId: window.PageData.booking_id,
      onUpdatedTimer: handleUpdateTimer
    };

    const steps = [
      <AttendeeQuestions {...params}/>,
      <Questions {...params}/>,
      <TermsAndConditions {...params}/>,
      <DetailedSummary {...params}/>
    ];

    return steps[step];
  }

  return (
    <div className="container" id="main">
      <div className='pull-right' style={{ marginTop: 20 }}>
        {showTimer && <Timer seconds={timer} trigger={trigger} onExpire={handleTimerExpire}/>}
      </div>
      <h1>Booking</h1>
      <div className="row">
        <div className="col-md-3">
          <CheckoutMenu step={step} onChangeStep={changeStep}/>
        </div>
        <div className="col-md-9 col-xs-12">
          {showPage()}
        </div>
      </div>
    </div>
  )
}
