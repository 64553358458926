import React, { Component } from 'react';

export default class Footer extends Component {
  render() {
    return (
      <footer className="standalone-footer" style={{marginBottom: '-60px'}}>
        <div className="container">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <p className="copy text-left copy-inline">
              © 2015-2016 Copyright School Lettings Ltd.
              <br />
              All rights reserved.
              <a rel="nofollow" href="/terms_and_conditions">T&amp;C and Policies</a>
            </p>
            <a className="right-position" href="https://beta.schoolhire.co.uk/"><img
              src={require('images/powered_by_sh_140x32w.png')}
              alt="Powered by sh 140x32w"/></a>
          </div>
        </div>
      </footer>
    )
  }
};
