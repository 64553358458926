import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';

export default class ActionsCell extends Component {
  handleAction = (data, row) => {
    const { action } = data;
    if (action) action(row);
  };

  render() {
    const { items, bsSize, row, pullLeft } = this.props;
    const visibleItems = items.filter((item) => {
      let { hidden } = item;
      if (hidden === undefined) return true;
      return (typeof hidden === 'function') ? !hidden(row) : !hidden;
    });

    const dropDownParams = {
      id: `dropdown-basic-${Math.random().toString(36)}`,
      bsStyle: 'default',
      title: "Actions",
      bsSize: bsSize,
      pullRight: (!pullLeft)
    };

    return (
      <DropdownButton {...dropDownParams}>
        {visibleItems.map((item, index) => {
          let href = '#';

          if (typeof item.href === 'function') href = item.href(row);
          if (typeof item.href === 'string') href = item.href;

          return (
            <MenuItem key={index} href={href} onClick={() => this.handleAction(item, row)}>
              {item.title}
            </MenuItem>
          );
        })}
      </DropdownButton>
    )
  }
}