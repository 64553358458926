import React, { Component } from 'react';
import ActionCable from 'actioncable';
import configureStore from "./store/configure_store";
import { ActionCableProvider } from 'react-actioncable-provider';
import { UserProvider } from './user';
import { StandaloneProvider } from './standalone';
import { Provider } from 'react-redux';

export default class Contexts extends Component {
  constructor(props) {
    super(props);
    this.store = configureStore();
    this.cable = ActionCable.createConsumer(`/websocket`)
  }

  render() {
    const { children } = this.props;

    return (
      <StandaloneProvider>
        <UserProvider>
          <Provider store={this.store}>
            <ActionCableProvider cable={this.cable}>
              {children}
            </ActionCableProvider>
          </Provider>
        </UserProvider>
      </StandaloneProvider>
    );
  }
}