import React, { Component } from 'react';
import FieldGroup from "../../smart/field_group";
import Documents from './documents';

export default class Question extends Component {
  constructor(props) {
    super(props);
  }

  formError(name) {
    const { errors } = this.props;
    if (errors === undefined) return null;

    return (name in errors) ? errors[name] : null;
  }

  render() {
    const { data, index } = this.props;
    const {
      id,
      text,
      allow_comment,
      allow_documents,
      allow_options,
      allow_yes_no_options,
      documents,
      comment_required
    } = data;

    const name = `booking[involved_questions_attributes][${index}]`

    return (
      <div className="vert-offset-bottom-25 question">
        <label className="question_text">
          {text}
        </label>

        <input name="booking[p]" value="1" type="hidden"/>
        <input name={`${name}[id]`} value={id} type="hidden"/>

        {allow_options &&
          <FieldGroup
            name={`${name}[option]`}
            className="venue_questions_answers_option"
            type="radio-group"
            defaultValue={data.option}
            options={
              [
                { id: 'yes', title: 'Yes' },
                { id: 'no', title: 'No' },
                { id: 'not_applicable', title: 'Not applicable' }
              ]
            }
            error={this.formError(`involved_questions[${index}].option`)}
          />
        }

        {allow_yes_no_options && !allow_options &&
          <FieldGroup
            name={`${name}[option]`}
            className="venue_questions_answers_option"
            type="radio-group"
            defaultValue={data.option}
            options={
              [
                { id: 'yes', title: 'Yes' },
                { id: 'no', title: 'No' }
              ]
            }
            error={this.formError(`involved_questions[${index}].option`)}
          />
        }

        {allow_comment &&
          <FieldGroup
            label="Additional comment:"
            name={`${name}[comment]`}
            type="textarea"
            required={comment_required}
            defaultValue={data.comment}
            error={this.formError(`involved_questions[${index}].comment`)}
          />
        }

        {allow_documents &&
          <Documents
            id={id}
            documents={documents}
            error={this.formError(`involved_questions[${index}].document`)}
          />
        }
      </div>
    )
  }
}