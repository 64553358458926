import React, { Component } from 'react';
import Question from './question';
import axios from 'axios';
import { BackButton, ContinueButton } from '../step_buttons';

export default class Index extends Component {
  constructor() {
    super();

    this.state = { data: undefined, included: undefined, errorDetails: {} };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { bookingId } = this.props;

    axios
      .get(`/bookings/${bookingId}/checkout/questions.json`)
      .then((response) => {
        const { data, included } = response.data;
        this.props.onUpdatedTimer(included.timer)
        this.setState({ data, included });
      })
      .catch((error) => console.log(error));
  }

  perform = () => {
    const { bookingId, onNext } = this.props;
    const data = new FormData(this.formRef.current);
    data.append('booking[key]', 'Hhf3y4vb-34bv-4cv-23yfb');

    axios.post(`/bookings/${bookingId}/checkout/questions.json`, data)
      .then((response) => onNext())
      .catch((error) => {
        const responseData = error.response.data;
        const formErrors = responseData.errors;
        this.setState({ formErrors })
      });
  };

  getErrors = (index) => {
    const { formErrors } = this.state;
    if (formErrors === undefined) return undefined;
    return formErrors[index];
  };

  render() {
    const { data, formErrors } = this.state;
    const { onBack } = this.props;

    if (data === undefined) return null;

    return (
      <form ref={this.formRef}>
        <div className="questions">
          {data.map((item, index) =>
            <Question key={item.id} index={index} data={item} errors={formErrors}/>)
          }
        </div>
        <div className="vert-offset-bottom-20 vert-offset-top-20">
          <div className="btn-toolbar pull-right">
            <BackButton onClick={onBack}/>
            <ContinueButton onClick={this.perform}/>
          </div>
          <div className="clearfix" />
        </div>
      </form>
    );
  }
}
