import React, { Component, Fragment } from 'react';
import Table from './table';

export default class Presenter extends Component {
  render() {
    const { data } = this.props;

    if (data === null) return null;

    return <Table items={data.items}/>;
  }
}
