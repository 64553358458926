import React, { Component } from 'react';
import classnames from 'classnames';

const weekDays = [
  { name: 'Mon', value: 1 },
  { name: 'Tue', value: 2 },
  { name: 'Wed', value: 3 },
  { name: 'Thu', value: 4 },
  { name: 'Fri', value: 5 },
  { name: 'Sat', value: 6 },
  { name: 'Sun', value: 7 }
];

export default class WeekDaysInput extends Component {
  constructor(props) {
    super(props);

    this.state = { defaultDays: props.defaultDays || [] };
  }

  render() {
    const { defaultDays } = this.state;

    return (
      <div>
        <span style={{ marginRight: 10 }}>
          on
        </span>
        {weekDays.map((weekDay, index) =>
          <span style={{ marginRight: 10 }} key={index}>
            <input
              type='checkbox'
              name="week_days[]"
              value={weekDay.value}
              defaultChecked={defaultDays.includes(weekDay.value)}
            />
            {weekDay.name}
          </span>
        )}
      </div>
    );
  }
}