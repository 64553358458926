import React, { Component } from 'react';
import Attendee from './attendee';

export default class Attendees extends Component {
  addAttendee = (e) => {
    const { onAddAttendee } = this.props;
    e.preventDefault();
    if (onAddAttendee) onAddAttendee();
  };

  removeAttendee = (e, index) => {
    const { onRemoveAttendee } = this.props;
    e.preventDefault();
    if (onRemoveAttendee) onRemoveAttendee(index);
  };

  changeName = (value, index) => {
    this.updateValue(index, 'name', value);
  };

  changeBirthday = (value, index) => {
    this.updateValue(index, 'birthday', value);
  };

  updateValue(index, name, value) {
    const { attendees, onUpdateAttendees } = this.props;
    let items = attendees.slice();
    items[index][name] = value;
    onUpdateAttendees(items);
  }

  formError(index) {
    const { errors } = this.props;
    let tmpErrors = errors || [];
    return tmpErrors[index];
  }

  render() {
    const { attendees } = this.props;

    return (
      <div className="section" id='attendees-section'>
        <h4>Enter attendees</h4>
        <div>
          {attendees.map((item, index) =>
            <Attendee
              key={index}
              changeName={this.changeName}
              changeBirthday={this.changeBirthday}
              removeAttendee={this.removeAttendee}
              attendees={attendees}
              index={index}
              errors={this.formError(index)}
            />
          )}
          <a href='#' onClick={this.addAttendee}> Add one more attendee</a>
        </div>
      </div>
    );
  }
}