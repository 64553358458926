import React, { Component } from 'react';
import Table from "./table";
import Page from '../page';

export default class Index extends Component {
  render() {
    return (
      <div>
        <Page.Header leftSide={<Page.NewButton title="Add venue" name="Venue"/>}>
          Venues
        </Page.Header>

        <Table title="Venue" name="Venue" path="/admin/venues"/>
      </div>
    );
  }
}
