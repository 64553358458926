import React, { Component } from 'react';
import Formatted from '../../formatted';
import StatusLabel from '../../involved_schedule_items/status_label'
import { Button, ButtonGroup } from 'react-bootstrap';
import { Context } from './context';

export default class Info extends Component {
  render() {
    const { schedule_items } = this.props;

    const ids = schedule_items.map((item) => item.id);

    return (
      <Context.Consumer>
        {internalNotes =>
          <tr>
            <td colSpan={8} className='booking-info-block'>
              <div className='booking-info-block-table'>
                <div className="row">
                  <div className="col-md-12">

                    <strong>SCHEDULE OF ACTIVITY</strong>

                    <ButtonGroup bsSize='xs' className='internal-notes-actions'>
                      <Button className='btn-success' onClick={() => internalNotes.selectIds(ids)}>All</Button>
                      <Button onClick={() => internalNotes.deselectIds(ids)}>None</Button>
                    </ButtonGroup>

                    <table className="table-jsmall">
                      <tbody>
                      {schedule_items.map((item, index) =>
                        <tr key={index}>
                          <td>
                            <input
                              name='ids[]'
                              value={item.id}
                              type='checkbox'
                              checked={internalNotes.selectedIds.includes(item.id)}
                              onChange={() => internalNotes.toggle(item.id)}
                            />
                          </td>
                          <td className="text-center">
                            <Formatted.Date date={item.event_date}/>
                          </td>
                          <td><span>{item.time_slot}</span></td>
                          <td style={{ width: '20' }}><StatusLabel value={item.status}/></td>
                          <td style={{ width: '70%' }}>{item.internal_note}</td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        }
      </Context.Consumer>
    )
  }
}