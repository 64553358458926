import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import AttendeeQuestions from './attendee_questions';

export default class InvolvedAttendees extends Component {
  render() {
    const { data } =  this.props;

    if (!(Array.isArray(data) && data.length > 0)) return null;

    return (
      <div className="section">
        <div className="section-header">
          <h3>Attendees Information</h3>
        </div>

        <Tabs id={`involved-attendees-${Math.random().toString(36).substring(7)}`}>
          {data.map((involvedAttendee, index) =>
            <Tab key={index} eventKey={index} title={involvedAttendee.name}>
              <div className='vert-offset-bottom-10'/>
              <AttendeeQuestions data={involvedAttendee.data}/>
            </Tab>
          )}
        </Tabs>
      </div>
    );
  }
}