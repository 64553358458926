import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PubSub from 'pubsub-js';
import ErrorBoundary from "../error_boundary";

export default class ApproveErrorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalStyle: undefined,
      show: false,
      errors: []
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    PubSub.subscribe('Bills.ApprovalError', this.handleShow)
  }

  componentWillUnmount() {
    PubSub.unsubscribe('Bills.ApprovalError')
  }

  handleShow = (_x, data) => {
    console.log(data)
    this.setState({ show: true, errors: data.errors });
  };

  handleClose = () => {
    this.setState({ show: false, errors: [] });
  };

  render() {
    const { bsSize, title } = this.props;
    const { show, errors } = this.state;

    let modalProps = {
      bsSize: bsSize,
      show: show,
      onHide: this.handleClose,
    };

    return (
      <Modal {...modalProps}>
        <div style={{ height: 30 }}/>
        <Modal.Body>
          <ErrorBoundary>
            {errors.map((error, _index) => <p>{error}</p>)}
          </ErrorBoundary>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button className='btn btn-primary' onClick={this.handleClose}>Close</Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

