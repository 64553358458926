import React, { Component, Fragment } from 'react';
import UserMenu from './user_menu';
import { StandaloneContext } from '../standalone';
import { toastr } from 'react-redux-toastr';

class Logo extends Component {
  constructor() {
    super();

    this.defaultLogo = require('images/logo.png');
  }

  render() {
    return (
      <StandaloneContext.Consumer>
        {standalone =>
          <a className="navbar-brand" href="/">
            {standalone && <img className="img-responsive" alt="SchoolHire" src={standalone.logo}/>}
            {!standalone && <img className="img-responsive" alt="SchoolHire" src={this.defaultLogo}/>}
          </a>
        }
      </StandaloneContext.Consumer>
    )
  }
}

class PhoneNumber extends Component {
  render() {
    return (
      <StandaloneContext.Consumer>
        {venue =>
          <Fragment>
            {venue &&
            <ul className="nav navbar-nav">
              <li className="phone">
                <span>{venue.phone_number}</span>
              </li>
            </ul>
            }
          </Fragment>
        }
      </StandaloneContext.Consumer>
    )
  }
}

export default class Header extends Component {
  componentDidMount() {
    const flash = (window.PageData || {}).flash;

    if (flash) {
      if (flash.alert) { toastr.warning('', flash.alert) }
      if (flash.notice) { toastr.info('', flash.notice) }
      if (flash.error) { toastr.error('', flash.error) }
    }
  }

  render() {
    return (
      <header>
        <nav className="navbar navbar-default navbar-userside">
          <div className="container">
            <div className="navbar-header">
              <button aria-expanded="false"
                      className="navbar-toggle collapsed"
                      data-target="#bs-example-navbar-collapse-1"
                      data-toggle="collapse"
                      type="button">
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"/>
                <span className="icon-bar"/>
                <span className="icon-bar"/>
              </button>
              <Logo/>
              <PhoneNumber/>
            </div>
            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
              <ul className="nav navbar-nav navbar-right">
                <UserMenu/>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    )
  }
};