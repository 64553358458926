import React, { Component } from 'react';
import SmartLabel from '../../smart/label';

export default class TypeLabel extends SmartLabel {
  constructor() {
    super();

    this.items = {
      pay_as_you_go: { title: 'Pay as you go', className: 'label-warning' },
      multiple_sessions: { title: 'Multiple sessions', className: 'label-primary' },
    }
  }
}