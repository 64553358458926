import React, { Component } from 'react';
import Question from './question';

export default class Questions extends Component {
  render() {
    const { data } = this.props;
    if (!(Array.isArray(data) && data.length > 0)) return null;

    return (
      <div className="section">
        <div className="section-header">
          <h3>Venue Questions</h3>
        </div>
        <div className="questions">
          {data.map((question, index) => <Question key={index} {...question}/>)}
        </div>
      </div>
    );
  }
}