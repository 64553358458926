import React from 'react';
import Question from './question';

export default ({ data }) => {
  if (!(Array.isArray(data) && data.length > 0)) return null;

  return (
    <div className="section">
      <div className="questions">
        {data.map((question, index) => <Question key={index} {...question}/>)}
      </div>
    </div>
  );
}