import React, { Component, Fragment } from 'react';
import PubSub from 'pubsub-js';
import DeviseSignInModal from '../../devise/sign_in/modal';
import DeviseSignUpModal from '../../devise/sign_up/modal';
import DeviseResetPasswordModal from '../../devise/reset_password/modal';

export default class LoginButton extends Component {
  signIn(e) {
    e.preventDefault();
    PubSub.publish('Devise.SignIn', { modalTitle: 'Sign In' });
  }

  render() {
    return (
      <div>
        <DeviseSignInModal name="Devise"/>
        <DeviseSignUpModal name="Devise"/>
        <DeviseResetPasswordModal name="Devise"/>
        <a id='login_btn' href="#" onClick={(e) => this.signIn(e)}>Login</a>
      </div>
    );
  }
}