import React, { Component, Fragment } from 'react';
import Row from './row';
import axios from 'axios';

export default class Block extends Component {
  constructor() {
    super();

    this.state = { data: undefined };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    axios
      .get('/admin/notifications.json')
      .then((response) => this.setState({ data: response.data.data }))
      .catch((error) => console.log(error));
  };

  render() {
    const { data } = this.state;

    if (!data) return null;

    const { venue_users } = data;

    return (
      <Fragment>
        <h4>Notifications</h4>

        <table className='table table-striped table-notifications'>
          <thead>
          <tr>
            <th>Venue</th>
            <th>Notification enabled?</th>
          </tr>
          </thead>
          <tbody>
          {venue_users.map((venue_user) =>
            <Row key={venue_user.id} {...venue_user} onUpdate={this.fetchData}/>
          )}
          </tbody>
        </table>
      </Fragment>
    )
  }
}