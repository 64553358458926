import React, { Component} from 'react';
import { Checkbox } from 'react-bootstrap';
import Extra from './extra';

export default class Extras extends Component {
  handleUpdateExtra = (index, checked) => {
    const { extras, onUpdate } = this.props;
    let newExtras = extras.map((extra) => ({...extra}));
    newExtras[index]['checked'] = checked;
    onUpdate(newExtras);
  };

  render() {
    const { extras } = this.props;

    if (!(Array.isArray(extras) && extras.length > 0)) return null;

    return (
      <div className="section" id="extras-sections">
        <h4>Choose extras</h4>
        <div className="row extras">
          {extras.map((extra, index) =>
            <Extra
              key={index}
              onChange={this.handleUpdateExtra}
              {...extra}
              index={index}
            />
          )}
        </div>
      </div>
    );
  }
}
