import React, { Component } from 'react';
import { ActionsCell as ParentActionsCell } from '../../smart/cells';
import PubSub from 'pubsub-js';

export default class ActionsCell extends Component {
  getViewBookingUrl = (row) => `/account/bookings/${row.id}`;
  getViewInvoiceUrl = (row) => `/account/bookings/${row.id}/invoices`;

  handleViewBooking = (row) => window.location = this.getViewBookingUrl(row);
  handleViewInvoice = (row) => window.location = this.getViewInvoiceUrl(row) ;
  handleInternalNotes = (row) => window.location = this.getInternalNotesUrl(row);

  handleSendMessage(row) {
    PubSub.publish(
      'Message.New',
      {
        fixedTo: true,
        inbox: { type: 'User', id: PageData.user.id },
        interlocutor: { type: 'Venue', id: row.venue_id }
      }
    )
  }

  handleViewBooking(row) {
    window.location = `/account/bookings/${row.id}`;
  }

  handleViewInvoice(row) {
    window.location = `/account/bookings/${row.id}/invoices`;
  }

  render() {
    const { row, bsSize, hideViewBooking, pullLeft } = this.props;

    return (
      <ParentActionsCell
        row={row}
        bsSize={bsSize}
        pullLeft={pullLeft}
        items={[
          { title: 'View booking',
            action: this.handleViewBooking,
            hidden: hideViewBooking,
            href: this.getViewBookingUrl
          },
          {
            title: 'Messages',
            action: this.handleSendMessage
          },
          {
            title: 'View invoice',
            action: this.handleViewInvoice,
            href: this.getViewInvoiceUrl
          },
        ]}
      />
    )
  }
}
