import React from 'react';
import SmartModal from '../../smart/modal';
import { FormGroup, HelpBlock } from 'react-bootstrap'

export default class Modal extends SmartModal {
  renderForm() {
    return (
      <div>
        <SmartModal.FieldGroup
          type="text"
          label="Title"
          name="city[title]"
          defaultValue={this.formValue('title')}
          error={this.formError('title')}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Slug"
          name="city[slug]"
          defaultValue={this.formValue('slug')}
          error={this.formError('slug')}
        />
        <SmartModal.FieldGroup
          type="select"
          label="County"
          options={this.includedData('counties')}
          name="county[county_id]"
          defaultValue={this.formValue('county_id')}
          error={this.formError('county_id')}
        />
      </div>
    );
  }
}
