import FieldGroup from "../../../smart/field_group";
import React, { useEffect, useState } from "react";
import classnames from "classnames";
import moment from "moment";

export default ({ onChange, data }) => {
  const [timePeriod, setTimePeriod] = useState(null)

  const handleChangeTimePeriod = (v) => {
    setTimePeriod(v.target.value)
  }

  useEffect(()=> { onChange() }, [timePeriod])

  return (
    <>
      <FieldGroup
        label="Time period:"
        type="radio-group"
        name="time_period"
        defaultValue={null}
        options={[
          { id: null, title: "All Times", checked: true },
          { id: "today", title: "Today" },
          { id: "this_week", title: "This Week" },
          { id: "this_month", title: "This Month" },
          { id: "custom", title: "Custom" }
        ]}
        onChange={handleChangeTimePeriod}
      />

      <div className={classnames(['custom_dates', { 'hidden': timePeriod !== 'custom' }])}>
        <FieldGroup
          label="Start date"
          type="date"
          defaultValue={moment().subtract(1, 'months').toDate()}
          name="start_date"
          onChange={onChange}
        />
        <FieldGroup
          label="End date"
          type="date"
          defaultValue={moment().toDate()}
          name="end_date"
          onChange={onChange}
        />
      </div>
    </>
  )
};
