import React, { Component } from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate } from 'react-day-picker/moment';

export default class DatePickerInput extends Component {
  constructor(props) {
    super(props);

    let selectedDay = moment(props.defaultValue);

    this.state = {
      selectedDay: (selectedDay.isValid() ? selectedDay : moment().add(1, 'days')).toDate(),
      isDisabled: false,
    };

    this.inputRef = React.createRef();
  }

  handleDayChange = (selectedDay, modifiers) => {
    const { onChange, onRealChange } = this.props;

    this.setState({ selectedDay, isDisabled: modifiers.disabled === true}, () => {
      if (onChange) { onChange(selectedDay) } // this function is not correct
      if (onRealChange) { onRealChange({ target: { value: selectedDay }}) }
    });
  };

  render() {
    const { selectedDay } = this.state;
    const { format, name } = this.props;
    let selectedDayValue = selectedDay;

    if (selectedDay === undefined) selectedDayValue = '';

    return (
      <div className="day-picker-box">
        <DayPickerInput
          value={selectedDay}
          onDayChange={this.handleDayChange}
          format={format || "ddd, D MMM YYYY"}
          formatDate={formatDate}
          locale='en-gb'
          placeholder=''
          inputProps={{ className: 'form-control' }}
          dayPickerProps={{
            selectedDays: selectedDay,
            locale: 'en-gb',
            localeUtils: MomentLocaleUtils,
          }}
        />
        <input ref={this.inputRef} name={name} type='hidden' value={selectedDayValue} readOnly/>
      </div>
    );
  }
}