import React from "react";
import { Modal } from "react-bootstrap";
import Body from "./Body";

export default ({ show, setShow, paymentPath, buttonTitle, spinnerMessage, offline }) => {
  return (
    <Modal show={show}>
      <Modal.Body>
        <button type="button" className="close" style={{ marginTop: 10 }} onClick={() => setShow(false)}>
          <span aria-hidden="true">×</span>
          <span className="sr-only">Close</span>
        </button>

        <div style={{ textAlign: 'center' }}>
          <div className='stripe-title-image'/>
        </div>

        <Body
          paymentPath={paymentPath}
          setShow={setShow}
          buttonTitle={buttonTitle}
          spinnerMessage={spinnerMessage}
          offline={offline}
        />
      </Modal.Body>
    </Modal>
  )
};