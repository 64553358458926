import React, { Fragment } from 'react';
import SmartModal from '../../smart/modal';
import FieldGroup from '../../smart/field_group'
import { Can } from '../../ability_context';
import VenueRow from './modal/venue_row';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';

export default class Modal extends SmartModal {
  applyResendInvitation = () => {
    const { id } =  this.state.data;

    axios
      .post(`/admin/users/${id}/resend_invitation.json`)
      .then(() => {
        toastr.success('Invitation', 'Successfully sent');
        this.handleClose()
      })
      .catch((error) => console.log(error));
  };

  renderForm() {
    let venues = this.includedData('venues');

    return (
      <Fragment>
        <FieldGroup
          type="text"
          label="Name"
          required={true}
          name="user[name]"
          defaultValue={this.formValue('name')}
          error={this.formError('name')}
        />

        <FieldGroup
          type="text"
          label="Email"
          required={true}
          name="user[email]"
          defaultValue={this.formValue('email')}
          error={this.formError('email')}
        />

        <FieldGroup
          type="text"
          label="Role in organisation"
          name="user[role_in_organisation]"
          defaultValue={this.formValue('role_in_organisation')}
          error={this.formError('role_in_organisation')}
        />

        <Can I="changeAccesses" a="AdminUsers">
          <table className="table">
            <thead>
            <tr>
              <th>Venue</th>
              <th>Access type</th>
            </tr>
            </thead>
            <tbody className="access_table_body">
            {venues && venues.map((venue, index) => <VenueRow key={index} index={index} {...venue}/>)}
            </tbody>
          </table>
        </Can>
      </Fragment>
    )
  }
}
