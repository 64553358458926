import React from 'react';
import SmartModal from '../../smart/modal';
import classnames from 'classnames';
import { FormGroup, HelpBlock } from 'react-bootstrap';
import FieldGroup from '../../smart/field_group';

export default class Modal extends SmartModal {
  renderCheckBox(name, title) {
    let inputName = "question[" + name + "]";
    let id = "question[" + name + "]";

    return (
      <div>
        <input value="0" type="hidden" name={inputName}/>
        <label>
          <input
            className="boolean optional"
            type="checkbox"
            value="1"
            name={inputName}
            id={id}
            defaultChecked={this.formValue(name)}
          />
        </label>
        {title}
      </div>
    )
  }

  renderCheckboxes() {
    let error = this.formError('items');

    return (
      <FormGroup className={classnames({ 'has-error': error})}>
        {this.renderCheckBox('allow_options', 'Allow users to select answer from Yes/No/Not applicable options')}
        {this.renderCheckBox('allow_yes_no_options', 'Allow users to select answer from Yes/No options')}
        {this.renderCheckBox('allow_documents', 'Allow users to upload documents')}
        {this.renderCheckBox('allow_comment', 'Allow users to leave additional comments')}
        {error && <HelpBlock>{error}</HelpBlock>}
      </FormGroup>
    )
  }

  renderForm() {
    return (
      <div>
        <FieldGroup
          type="textarea"
          label="Text"
          name="question[text]"
          defaultValue={this.formValue('text')}
          error={this.formError('text')}
        />

        <FieldGroup
          type="textarea"
          label="Comment"
          name="question[comment]"
          defaultValue={this.formValue('comment')}
          error={this.formError('comment')}
        />

        {this.renderCheckboxes()}

        <FieldGroup
          type='checkbox'
          name='question[link_to_internal_notes_enabled]'
          placeholder='Link to internal notes'
          defaultValue={this.formValue('link_to_internal_notes_enabled')}
          error={this.formError('link_to_internal_notes_enabled')}
        />
      </div>
    );
  }
}
