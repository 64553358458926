import React, { Component } from 'react';
import { Radio } from 'react-bootstrap';

export default class RadioGroup extends Component {
  render() {
    const { options, name, defaultValue, value, onChange } = this.props;

    if (typeof options !== 'object') return null;

    return (
      options.map((option, index) =>
        <span key={index} className='radio'>
          <Radio
            name={name}
            value={option.id}
            onChange={onChange}
            inline
            defaultChecked={ option.id === defaultValue }
          >
            {option.title}
          </Radio>{' '}
        </span>
      )
    );
  }
}