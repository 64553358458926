import React, { Component } from 'react';
import { AbilityContext } from './ability_context';
import { defineAbilitiesFor } from './ability';

export const UserContext = React.createContext();

export class UserProvider extends Component {
  constructor() {
    super();

    const user = { ...(window.PageData || {}).user };

    this.state = {
      ...user,
      change: (user) => {
        window.PageData.user_id = user.id;
        window.PageData.user = user;
        this.setState({ ...user })
      },
      isLoggedIn: () => !!this.state.name,
      isSystemAdmin: () => this.state.role === 'system_admin',
      isVenueAdmin: () => this.state.role === 'venue_admin',
      isSubadmin: () => this.state.role === 'subadmin',
      isUser: () => this.state.role === 'user',
      hasFullAccess: () => this.state.has_full_access,
      canSwitchBack: () => this.state.switch_user !== null
    }
  }

  render() {
    const { children } = this.props;
    const ability = defineAbilitiesFor(this.state);

    return (
      <UserContext.Provider value={this.state}>
        <AbilityContext.Provider value={ability}>
          {children}
        </AbilityContext.Provider>
      </UserContext.Provider>
    );
  }
}