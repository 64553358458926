import SmartLabel from "../../smart/label";

export default class AccessLabel extends SmartLabel {
  constructor() {
    super();

    this.items = {
      full_access: { className: 'label-user-role-venue', title: 'Full access' },
      read_only: { className: 'label-user-role-subadmin', title: 'Read only' }
    }
  }
}