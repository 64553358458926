import React, { Component } from 'react';
import axios from 'axios';
import PubSub from 'pubsub-js';

export default class Info extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: undefined,
      included: undefined
    };

    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    this.fetchData();
    PubSub.subscribe(`${this.props.name}.Updated`, this.fetchData);
  }

  fetchData() {
    const { path, updatePageData } = this.props;

    axios
      .get(`${path}.json`)
      .then((response) => {
        const responseData = response.data;
        const { data, included } = responseData;
        this.setState({ data, included }, () => updatePageData(data, included));
      })
      .catch((error) => {
        console.log(error)
      });
  }

  onPreviewClick = (e) => {
    e.preventDefault();
    const { data } = this.state;
    window.open(data.seo_link, '_blank');
  };

  onEditClick = (e) => {
    e.preventDefault();
    PubSub.publish(`${this.props.name}.Edit`, { id: this.state.data.id })
  };

  render() {
    return null;
  }
}