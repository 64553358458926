import React, { Fragment } from 'react';
import Modal from './modal';
import AreasInfo from '../areas/info';
import { Button } from 'react-bootstrap';
import Formatted from '../../formatted';
import Nl2Br from "../../smart/nl2br";
import PhoneLabel from './phone_label';

export default class Info extends AreasInfo {
  render() {
    const { data } = this.state;
    if (data === undefined) return null;

    const {
      title,
      commission_percent,
      payment_fees,
      address_1,
      address_2,
      postcode,
      region,
      county,
      city,
      coordinates_detected,
      latitude,
      longitude,
      local_authority,
      phone_number,
      visible_phone_number,
      display_phone_number,
      email,
      created_at,
      owner,
      standalone_website,
      standalone_link,
      split_payment_option,
      preorder_first_part_percent,
      preorder_second_part_due_days,
      description
    } = data;

    return(
      <div>
        <Modal title='Venue' name="Venue" path="/admin/venues"/>

        <div className="row">
          <div className="col-lg-5 col-xs-12">
            <dl className="dl-horizontal">
              <dt>Status:</dt>
              <dd>
                <span className="label label-primary">Active</span>
              </dd>
            </dl>
          </div>
          <div className="col-lg-7 col-xs-12">
            <div className="pull-right">
              <div className="btn-group btn-group-xs">
                <a className='btn btn-primary' target="_blank" href="#" onClick={this.onPreviewClick}>Preview</a>
                <a className="btn btn-primary" href="#" onClick={this.onEditClick}>Edit</a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 col-xs-12">
            <dl className="dl-horizontal">
              <dt>Name:</dt>
              <dd>{title}</dd>

              <dt>SH commission:</dt>
              <dd>{commission_percent}%</dd>

              <dt>Payment fees:</dt>
              <dd>{payment_fees}%</dd>

              <dt>Address 1:</dt>
              <dd>{address_1}</dd>

              <dt>Address 2:</dt>
              <dd>{address_2}</dd>

              <dt>Postcode:</dt>
              <dd>{postcode}</dd>

              <dt>Region:</dt>
              <dd>{region}</dd>

              <dt>County:</dt>
              <dd>{county}</dd>

              <dt>City:</dt>
              <dd>{city}</dd>

              {coordinates_detected &&
              <Fragment>
                <dt>Coordinates:</dt>
                <dd>
                  <a href={`http://www.google.com/maps/place/${latitude},${longitude}`} target="_blank">
                    [{latitude}, {longitude}]
                  </a>
                </dd>
              </Fragment>
              }

              <dt>Local authority:</dt>
              <dd>{local_authority}</dd>
            </dl>
          </div>
          <div className="col-lg-7 col-xs-12" id="cluster_info">
            <dl className="dl-horizontal">
              <dt>Phone number:</dt>
              <dd>
                {visible_phone_number}
                &nbsp;&nbsp;&nbsp;
                <PhoneLabel value={display_phone_number}/>
              </dd>

              <dt>Email:</dt>
              <dd>{email}</dd>

              <dt>Date created:</dt>
              <dd><Formatted.Date date={created_at} format="L LTS"/></dd>

              <dt>Created by:</dt>
              <dd>{owner.name}</dd>

              <dt>Stand-alone Website:</dt>
              <dd>
                {standalone_website ? 'Yes' : 'No'}
                &nbsp;
                {standalone_website &&
                (<span>(<a target='_blank' href={standalone_link}>{standalone_link}</a>)</span>)
                }
              </dd>

              <dt>Splitting Payment Term:</dt>
              <dd>{split_payment_option.title}</dd>

              <dt>Deposit (%) payable on booking:</dt>
              <dd>{preorder_first_part_percent}</dd>

              <dt>Balance due x days before event:</dt>
              <dd>{preorder_second_part_due_days}</dd>
            </dl>
          </div>

          <div className="col-lg-12">
            <dl className="dl-horizontal">
              <dt>Description:</dt>
              <dd><Nl2Br text={description}/></dd>
            </dl>
          </div>
        </div>
      </div>
    )  
  }
}