import React from 'react';
import Block from "./block";
import Page from '../page';

export default () => (
  <div>
    <Page.Header leftSide={<Page.NewButton title="Add activity category" name="ActivityCategory"/>}>
      Activity Categories
    </Page.Header>

    <Block
      title="Activity Category"
      path="/admin/activity_categories"
      name="ActivityCategory"
    />
  </div>
);
