import { AbilityBuilder } from '@casl/ability'

export function defineAbilitiesFor(user) {
  return AbilityBuilder.define((can, cannot) => {
    can('seeVenues', 'AdminMenu');
    can('seeActivities', 'AdminMenu');
    can('seeCalendars', 'AdminMenu');
    can('seeBookings', 'AdminMenu');
    can('seeMessages', 'AdminMenu');
    can('seeCustomers', 'AdminMenu');
    can('seeReports', 'AdminMenu');
    can('seeUsers', 'AdminMenu');
    can('seeBookingInfo', 'AdminCalendars');
    can('seeActivities', 'AdminReports');
    can('seeBookings', 'AdminReports');
    can('seeRemittance', 'AdminReports');
    can('sendMessage', 'Venue');

    if (user.isSystemAdmin()) {
      can('index', 'SystemAdministrator');
      can('editSlug', 'AdminVenues');
      can('editSHCommission', 'AdminVenues');
      can('editSubdomain', 'AdminVenues');
      can('editStandaloneWebsite', 'AdminVenues');
      can('delete', 'AdminActivities');
      can('log-in', 'AdminUsers');
      can('delete', 'AdminUsers');
      can('delete', 'AdminCustomers');
      can('newMessageModal', 'AdminMessages');
      can('seeIdColumn', 'AdminUsers');
      can('changeAccesses', 'AdminUsers');
      can('seeStatusColumn', 'AdminUsers');
      can('seeOwnCanonicalLink', 'AdminActivities');
      can('index', 'AdminMessages');
      can('seeSettings', 'AdminMenu');
      cannot('sendMessage', 'Venue');
    }

    if (user.isVenueAdmin()) {
      can('invite', 'AdminUsers');
      can('changeAccesses', 'AdminUsers');
      can('index', 'VenueManagement');
      can('newMessageForm', 'AdminMessages');
      can('groupMessage', 'AdminMessages');
      can('index', 'AdminMessages');
      cannot('sendMessage', 'Venue');
    }

    if (user.isSubadmin()) {
      if (user.hasFullAccess()) {
        can('index', 'VenueManagement');
        can('newMessageForm', 'AdminMessages');
        can('groupMessage', 'AdminMessages');
        can('index', 'AdminMessages');
      } else {
        can('index', 'VenueManagement');
        can('newMessageForm', 'AdminMessages');
        can('groupMessage', 'AdminMessages');
        can('index', 'AdminMessages');

        cannot('seeVenues', 'AdminMenu');
        cannot('seeActivities', 'AdminMenu');
        cannot('seeBookings', 'AdminMenu');
        cannot('seeMessages', 'AdminMenu');
        cannot('seeCustomers', 'AdminMenu');
        cannot('seeUsers', 'AdminMenu');
        cannot('seeBookingInfo', 'AdminCalendars');
        cannot('seeActivities', 'AdminReports');
        cannot('seeBookings', 'AdminReports');
        cannot('seeRemittance', 'AdminReports');
      }
      cannot('sendMessage', 'Venue');
    }

    if (user.isUser()) {
      can('index', 'AccountBookings');
      can('index', 'AccountMessages')
      can('newMessageForm', 'AccountMessages');
    }

    if (user.canSwitchBack()) {
      can('back', 'SwitchUser');
    }
  })
}
