import React, { Component} from 'react';
import * as Sentry from '@sentry/browser';
import { Alert } from 'react-bootstrap'

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });

    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => { scope.setExtra(key, errorInfo[key]) });
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;

    if (hasError) return <Alert bsStyle="danger">Sorry, something went wrong!</Alert>;
    return this.props.children;
  }
}