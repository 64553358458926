import React, { Component } from 'react';
import Search from './search';
import SimpleBooking from './simple_booking';
import ActivityCategories from './activity_categories';

export default class Index extends Component {
  render() {
    return (
      <div>
        <section id="content">
          <Search/>
          <div style={{ backgroundColor: '#fff' }}>
            <SimpleBooking/>
          </div>
          <ActivityCategories/>
        </section>
      </div>
    );
  }
};