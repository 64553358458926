import React, { Component } from 'react';
import axios from 'axios';
import PubSub from 'pubsub-js';
import UploadImage from './upload_image';
import ExistImage from './exist_image';
import EmptyImage from './empty_image';

export default class Image extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadProgress: null
    };

    this.uploadImage = this.uploadImage.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.primaryImage = this.primaryImage.bind(this);
  }

  uploadImage(position, e) {
    let file = e.target.files[0];
    let formData = new FormData();

    formData.append('image[position]', position);
    formData.append('image[image]', file);

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        console.log(percentCompleted);
        this.setState({ uploadProgress: percentCompleted });
      }
    };

    axios.post(this.props.path, formData, config)
      .then((response) => {
        this.setState({ uploadProgress: null });
        PubSub.publish('Image.Created', {});
      })
      .catch((error) => {
        this.setState({ uploadProgress: null });
        this.setState({ formErrors: error.response.data.errors });
      });
  }

  deleteImage(id) {
    axios.delete(this.props.path + '/' + id + '.json')
      .then((response) => {
        PubSub.publish('Image.Deleted', {});
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  }

  primaryImage(id) {
    axios.put(this.props.path + '/' + id + '/primary.json')
      .then((response) => {
        PubSub.publish('Image.Updated', {});
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  }

  render() {
    const { item, position } = this.props;
    const { uploadProgress } = this.state;

    let imageBox = null;

    if (uploadProgress) {
      imageBox = (
        <UploadImage progress={uploadProgress}/>
      )
    } else {
      if (item) {
        imageBox = (
          <ExistImage
            onUpload={this.uploadImage}
            onDelete={this.deleteImage}
            onPrimary={this.primaryImage}
            position={position}
            item={item}
          />
        )
      } else {
        imageBox = (
          <EmptyImage
            onUpload={this.uploadImage}
            position={position}
          />
        )
      }
    }

    return (
      <div className="col-sm-4">
        {imageBox}
      </div>
    )
  }
};