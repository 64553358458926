import React from 'react';
import SmartModal from '../../smart/modal';
import FieldGroup from '../../smart/field_group';

export default class Modal extends SmartModal {
  renderForm() {
    return (
      <div>
        <FieldGroup
          label="Title"
          name="info_page[title]"
          defaultValue={this.formValue('title')}
          error={this.formError('title')}
        />

        <FieldGroup
          type="rich-text"
          label="Text"
          name="info_page[content]"
          defaultValue={this.formValue('content')}
          error={this.formError('content')}
        />
      </div>
    );
  }
}
