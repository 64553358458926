import React from 'react';
import { Form } from 'react-bootstrap';
import SmartModal from '../../smart/modal';
import FieldGroup from '../../smart/field_group';
import moment from 'moment';
import axios from 'axios';
import { spacesDropDownList } from './helper';

export default class Modal extends SmartModal {
  showEdit = () => {
    const { path, groupUid } = this.props;
    const { data } = this.state;

    axios
      .get(`${path}/${data.id}/edit.json?group_uid=${groupUid}`)
      .then((response) => {
        const response_data = response.data;
        this.setState(
          { formData: response_data.data, included: response_data.included, loading: false },
          () => this.onShowEdit()
        );
      })
      .catch((error) => console.log(error));
  };

  renderForm() {
    const { groupUid } = this.props;
    const activity = this.includedData('activity');

    return (
      <div>
        <FieldGroup
          formType="custom"
          label="Date"
          type='date'
          format="ddd, D MMM YYYY"
          name="schedule_item[event_date]"
          defaultValue={this.formValue('event_date')}
          error={this.formError('event_date')}
          custom={{
            colLabelStyle: { width: 100 },
            colLabelClass: '',
            colInputStyle: {},
            colInputClass: '',
          }}
        />

        <FieldGroup
          formType="custom"
          type="time-slot"
          label="Time slot:"
          start={{
            name: "schedule_item[start_time]",
            defaultValue: moment.utc(this.formValue('start_time')),
            error: this.formError('start_time')
          }}
          end={{
            name: 'schedule_item[end_time]',
            defaultValue: moment.utc(this.formValue('end_time')),
            error: this.formError('end_time')
          }}
          custom={{
            colLabelStyle: { width: 100 },
            colLabelClass: '',
            colInputStyle: {},
            colInputClass: '',
          }}
        />

        <FieldGroup
          formType="custom"
          label="Spaces:"
          name="schedule_item[places]"
          defaultValue={this.formValue('places')}
          type="select"
          options={spacesDropDownList(activity.places)}
          error={this.formError('places')}
          custom={{
            colLabelStyle: { width: 100 },
            colLabelClass: '',
            colInputStyle: {},
            colInputClass: '',
          }}
        />

        <input
          type='hidden'
          name="schedule_item[group_uid]"
          value={groupUid}
        />
      </div>
    );
  }

  renderBody() {
    const { loading, action } = this.state;
    if (loading) return <div className="text-center">Loading....</div>;

    let fn = this[`render${action}Form`];

    return (
      <form className='form-horizontal' ref={this.formRef}>
        {(typeof fn === 'function') && fn()}
      </form>
    )
  }
}
