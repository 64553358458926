import React from 'react';
import Modal from './modal';
import SmartBlock from '../../smart/block';

export default class Block extends SmartBlock {
  renderTable() {
    const { data } = this.state;

    if (data === '') return null;

    const { commission_fee_percent, booking_ttl_seconds } = data;

    return (
      <table className="table table-striped table-sm">
        <tbody>
          <tr>
            <td style={{ width: 400 }}>
              <strong>Commission fee for all transactions (%)</strong>
            </td>
            <td>
              {commission_fee_percent}
            </td>
          </tr>
          <tr>
            <td style={{ width: 400 }}>
              <strong>Booking TTL (seconds)</strong>
            </td>
            <td>
              {booking_ttl_seconds}
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  render() {
    return (
      <div>
        <Modal {...this.props} />
        {this.renderTable()}
      </div>
    )
  }
}

