import React, { Component, Fragment } from 'react';
import Formatted from "../../formatted";
import { Popover, OverlayTrigger, Overlay } from 'react-bootstrap';

export default class BookingOptionItemRow extends Component {
  handleEdit = () => {
    const { id, onEdit } = this.props;
    onEdit(id);
  };

  handleDelete = () => {
    const { id, onDelete } = this.props;
    onDelete(id);
  };

  handleDisable = () => {
    const { id, onDisable } = this.props;
    onDisable(id);
  };

  handleEnable = () => {
    const { id, onEnable } = this.props;
    onEnable(id);
  };

  render() {
    const { can_be_deleted, disabled, schedule_items } = this.props;

    if (!schedule_items[0]) return null;

    const startDate = schedule_items[0].event_date;
    const startTimeSlot = schedule_items[0].time_slot;

    const popoverClick = (
      <Popover id="popover-trigger-click" title="Schedule">
        <table className="table">
          <tbody>
          {schedule_items.map((item, index) => (
            <tr key={index}>
              <td><Formatted.Date date={item.event_date}/></td>
              <td>{item.time_slot}</td>
            </tr>
          ))}
          </tbody>
        </table>
      </Popover>
    );

    let actions = [];
    actions.push(<button className="btn btn-primary btn-xs" onClick={this.handleEdit}>Edit</button>);

    if (can_be_deleted) {
      actions.push(<button className="btn btn-danger btn-xs" onClick={this.handleDelete}>Delete</button>);
    } else {
      if (disabled) {
        actions.push(<button className="btn btn-xs btn-green" onClick={this.handleEnable}>Enable</button>);
      } else {
        actions.push(<button className="btn btn-xs btn-warning" onClick={this.handleDisable}>Disable</button>);
      }
    }

    return (
      <tr>
        <td>
          <div className="btn-group">
            <OverlayTrigger trigger="click" placement="bottom" overlay={popoverClick} rootClose={true}>
              <a className="btn btn-primary btn-xs" style={{ width: '260px' }}>
                <Formatted.Date date={startDate}/> {startTimeSlot}
              </a>
            </OverlayTrigger>
            {actions}
          </div>
        </td>
      </tr>
    )
  }
}