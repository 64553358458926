import React, { Component } from 'react';
import Table from "./table";
import Page from '../page';

export default class Index extends Component {
  render() {
    return (
      <div className='admin-activities'>
        <Page.Header leftSide={<Page.NewButton title="Add activity" name="Activity"/>}>
          Activities
        </Page.Header>

        <Table title="Activity" name="Activity" path="/admin/activities"/>
      </div>
    );
  }
}
