import React, { Component } from 'react';
import SmallGallery from "./small_gallery";

export default class ActivityBox extends Component {
  render() {
    const { data } = this.props;

    if (!data) return null;

    const { images, internal_reference, formatted_address, id } = data;

    return (
      <div className="room">
        <div>
          <SmallGallery images={images}/>
        </div>
        <div className="room-desc">
          <h4>
            <a href={`/booking?activity_id=${id}`}>
              {internal_reference}
            </a>
          </h4>
          <p className="address">
            {formatted_address}
          </p>
        </div>
      </div>
    );
  }
}
