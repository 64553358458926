import React, { Component } from 'react';
import Modal from './group_message_modal';
import PubSub from 'pubsub-js';

export default class GroupMessageButton extends Component {
  showModal = () => {
    const { data } = this.props;

    PubSub.publish(
      'GroupMessage.New',
      {
        inbox: this.props.inbox,
        interlocutors: data
      },
    )
  };

  render() {
    return (
      <div className="vert-offset-bottom-15">
        <Modal
          path="admin/messages"
          name="GroupMessage"
          title="Message"
        />

        <button className='btn btn-primary' onClick={this.showModal} style={{ width: '100%' }}>
          Message to all
        </button>
      </div>
    );
  }
}
