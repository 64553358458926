import React, { useState, useEffect } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import StripeFormPay from "./StripeFormPay";
import Spinner from "./Spinner";
import Cookies from 'js-cookie'

import './styles.scss';
import { toastr } from "react-redux-toastr";

export default ({ spinnerMessage, buttonTitle, paymentPath, setShow }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [listenWS, setListenWS] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState(null);

  const handleSubmit = (cardOptions) => {
    setProcessing(true);

    axios.get(paymentPath).then(result => {
      const { paymentOptions, error } = result.data;
      const { clientSecret } = paymentOptions || {};

      if (error !== undefined) {
        if (error.redirectTo) {
          Cookies.set('FullyBookedMessage', error.message)
          window.location.href = error.redirectTo;
        }
        return;
      }

      setPaymentOptions(paymentOptions);

      let confirmFn = null;
      if (/^seti_/.test(clientSecret)) confirmFn = stripe.confirmCardSetup;
      if (/^pi_/.test(clientSecret)) confirmFn = stripe.confirmCardPayment;

      confirmFn(clientSecret, cardOptions)
        .then((result) => {
          if (result.error) {
            setProcessing(false)
            toastr.error('', result.error.message)
          } else {
            setListenWS(true)
          }
        })
        .catch(() => { console.log('error')});
    });
  };

  useEffect(() => {
    if (!processing) setPaymentOptions(null);
  }, [processing])

  return (
    <>
      <Spinner
        show={processing}
        setShow={setShow}
        paymentOptions={paymentOptions}
        message={spinnerMessage}
        setProcessing={setProcessing}
        listenWS={listenWS}
        setListenWS={setListenWS}
      />

      <StripeFormPay
        stripe={stripe}
        elements={elements}
        onSubmit={handleSubmit}
        spinnerMessage={spinnerMessage}
        buttonTitle={buttonTitle}
      />
    </>
  );
};