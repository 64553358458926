import React, { Component } from 'react';
import { DateCell, PriceCell } from '../smart/cells';
import classnames from 'classnames';
import axios from "axios";
import PubSub from 'pubsub-js';
import { toastr } from "react-redux-toastr";

const PaymentStatus = props => {
  const { status } = props;

  let spanClass = classnames([
    { 'label': true },
    { 'label-payment-status-pending': status === 'pending' },
    { 'label-payment-status-success': status === 'success' },
    { 'label-payment-status-skipped': status === 'skipped' },
    { 'label-payment-status-failed': status === 'failed' },
  ]);

  return (
    <div className="text-center">
      <span className={spanClass}>{status}</span>
    </div>
  )
};

const PaymentType = props => {
  const { type } = props;

  let spanClass = classnames([
    { 'label': true },
    { 'label-payment-type-charge': type === 'Charge' },
    { 'label-payment-type-refund': type === 'Refund' },
    { 'label-payment-type-customer': type === 'Customer' },
    { 'label-payment-type-capture': type === 'Capture' }
  ]);

  return (
    <div className="text-center">
      <span className={spanClass}>{type}</span>
    </div>
  )
};

const DeleteButton = props => {
  if (!props.item.allowDelete) return null;

  function removePayment(e) {
    e.preventDefault();

    axios
      .delete('/admin/payments/' + props.item.id + '.json')
      .then((data) => {
        PubSub.publish('Booking.Updated', { id: data.bookingId })
        toastr.success('', 'Payment was removed successfully');
      })
      .catch((error) => {
        toastr.error('', 'Payment was not removed');
      });
  }

  return (<a onClick={(e) => removePayment(e)} className='btn btn-danger btn-xs'>DELETE</a>)
}

export default class Table extends Component {
  render() {
    const { items } = this.props;

    const canBeRemoved = (item) => item.allowDelete;
    const showDeleteColumn = items.some(canBeRemoved);

    return(
      <div className="table-block">
        <table className="table table-bordered-2">
          <thead>
          <tr>
            <th>Request date</th>
            <th>Status</th>
            <th>Payment date</th>
            <th style={{width: 10}}>Type</th>
            <th>Amount, £</th>
            {showDeleteColumn && <th style={{width: 50}}/>}
          </tr>
          </thead>
          <tbody>
          {items.map((item) =>
            <tr key={item.id}>
              <td data-title='Request date'><DateCell date={item.created_at}/></td>
              <td data-title='Status'><PaymentStatus status={item.status}/></td>
              <td data-title='Payment date'><DateCell date={item.payment_date}/></td>
              <td data-title='Type'><PaymentType type={item.type}/></td>
              <td data-title='Amount, £'><PriceCell value={item.amount}/></td>
              {showDeleteColumn && <td><DeleteButton item={item}/></td>}
            </tr>
          )}
          </tbody>
        </table>
      </div>
    )
  }
}