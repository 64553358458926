import React, { Component, Fragment } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import axios from 'axios';
import Invoice from "./show";
import Formatted from '../formatted';

export default class Index extends Component {
  constructor(props) {
    super(props);

    this.state = { key: 0, data: null };
  }

  handleSelectTab = (key) =>  {
    this.setState({ key });
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    axios
      .get(`${window.location.pathname}.json`)
      .then((response) => {
        const response_data = response.data;
        this.setState({ data: response_data.data });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  render() {
    const { data, key } = this.state;

    if (data === null) return null;

    return (
      <div className="vert-offset-top-10 vert-offset-bottom-10">
        <Tabs activeKey={this.state.key} onSelect={this.handleSelectTab} id="invoices">
          {data.map((bill, index) => {
            const tabTitle = <Formatted.DateTime date={bill.created_at} showSeconds={false}/>;

            return (
              <Tab key={index} eventKey={index} title={tabTitle}>
                {key === index && <Invoice billId={bill.id}/>}
              </Tab>
            )
          })}
        </Tabs>
      </div>
    );
  }
}