import React from 'react';
import { Radio } from 'react-bootstrap';

export default ({ options, name, value, onChange }) => {
  if (typeof options !== 'object') return null;

  return (
    options.map((option, index) =>
      <span key={index} className='radio'>
        <Radio
          name={name}
          value={option.id}
          checked={option.uid === value}
          onChange={() => onChange(option.uid)}
          inline
        >
          {option.title}
        </Radio>{' '}
      </span>
    )
  );
}