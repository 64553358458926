import React, { Fragment } from 'react';
import { withGoogleMap, GoogleMap, Marker, Circle } from "react-google-maps";


function renderCircle(center, distance) {
  if (center === undefined) return null;
  if (distance === undefined) return null;

  let radius = distance * 1609.34;

  return (
    <Circle
      radius={radius}
      center={center}
      options={{
        strokeColor: '#3386FF',
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: '#E6FF60',
        fillOpacity: 0.1,
      }}
    />
  );
}

function renderMarkers(markers) {
  return (
    <Fragment>
      {markers.map((marker, index) =>
        <Marker
          key={index}
          position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) } }
        />
      )
    }
    </Fragment>
  );
}

const MapWithAMarker = withGoogleMap(props => {
    let {
      loading,
      center,
      markers,
      distance,
      onCenterChanged,
      onMapMounted,
      onDragEnd
    } = props;

    if (!Array.isArray(markers)) markers = [];

    // var markers = [];//some array
    // var bounds = new google.maps.LatLngBounds();
    // for (var i = 0; i < markers.length; i++) {
    //   bounds.extend(markers[i].getPosition());
    // }
    //
    // map.fitBounds(bounds);

    center = { lat: parseFloat(center.lat), lng: parseFloat(center.lng) };

    const attributes = {
      ref: onMapMounted,
      defaultZoom: 14,
      center: center,
      onCenterChanged: onCenterChanged,
      onDragEnd: onDragEnd
    };

    return (
      <GoogleMap {...attributes}>
        {!loading && renderCircle(center, distance)}
        {renderMarkers(markers)}
      </GoogleMap>
    );
  }
);

export default MapWithAMarker;