import React, { useState, Fragment } from 'react';
import SmartTable from '../../smart/table';
import { PriceCell, DateCell, WithTooltip } from '../../smart/cells';
import Filter from './filter';
import StatusCell from '../../bookings/status_cell';
import ModificationStatusCell from '../modifications/status_cell';
import ActionsCell from './actions_cell';
import Modal from './modal';
import MessageModal from '../../messages/modal';
import AttachmentsListModal from '../attachments/list_modal';

export default (props) => {
  const [visibleVenuesColumn, setVisibleVenuesColumn] = useState(true);

  const onChangeSearch = (params) => {
    setVisibleVenuesColumn(params.venue_id === "");
  };

  return (
    <Fragment>
      <Modal {...props}/>

      <MessageModal
        name='Message'
        path='/admin/messages'
        title="Message"
      />

      <AttachmentsListModal
        name='ListAttachments'
        path='/admin/bookings'
        title="Document manager"
      />

      <SmartTable
        TableLeftSide={Filter}
        name="Booking"
        showHeader={false}
        onChangeSearch={onChangeSearch}
        dataPath='/admin/bookings.json'
        defaultSorted={[
          {
            id: "created_at",
            desc: true
          }
        ]}
        columns={[
          {
            Header: "Date of request",
            accessor: "created_at",
            width: 110,
            resizable: false,
            Cell: props => <DateCell date={props.value}/>
          },
          {
            Header: "Customer",
            accessor: "customer_name",
            width: 120,
            Cell: props => <WithTooltip>{props.value}</WithTooltip>
          },
          {
            Header: "Venue",
            accessor: "venue_title",
            show: visibleVenuesColumn,
            Cell: props => <WithTooltip>{props.value}</WithTooltip>
          },
          {
            Header: "Activity",
            accessor: "activity_internal_references",
            sortable: false,
            Cell: props => <WithTooltip>{props.value}</WithTooltip>
          },
          {
            Header: "Attendees",
            accessor: "attendees",
            sortable: false,
            Cell: props => <WithTooltip>{(props.original.attendees || []).join(', ')}</WithTooltip>
          },
          {
            Header: "Price",
            accessor: "gross_total",
            width: 90,
            resizable: false,
            Cell: props => <PriceCell value={props.value}/>
          },
          {
            Header: "Status",
            accessor: "status",
            width: 120,
            resizable: false,
            Cell: props => <StatusCell status={props.original.status} offline={props.original.offline}/>
          },
          {
            Header: "Amendment",
            accessor: "modification_status",
            width: 85,
            resizable: false,
            Cell: props => <ModificationStatusCell value={props.original.modification_status}/>
          },
          {
            Header: "Actions",
            sortable: false,
            width: 70,
            resizable: false,
            style: { overflow: 'inherit' },
            Cell: props => <ActionsCell bsSize='xs' row={props.original}/>
          }
        ]}
      />
    </Fragment>
  );
}