import React from 'react';
import BookButton from './button';
import ParentPage from '../page';

const Description = () => (
  <div className="summery-text">
    <p>
      Once you request to book, the Venue will be notified.
      Please contact the venue directly to arrange payment.
    </p>
  </div>
);

export default (props) => (
  <ParentPage
    description={<Description/>}
    bookButton={<BookButton {...props}/>}
    {...props}
  />
);
