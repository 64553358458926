import React, { Component } from 'react';

export default class Breadcrumb extends Component {
  render() {
    return (
      <div className="breadcrumb-menu">
        <ol className="breadcrumb">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            Activities
          </li>
        </ol>
      </div>
    );
  }
}