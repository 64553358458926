import React, { Component, Fragment } from 'react';
import Interlocutor from './interlocutor';
import GroupMessageButton from './group_message_button';
import { Can } from '../ability_context';

export default class Interlocutors extends Component {
  render() {
    const { data, interlocutor, onChange, inbox } = this.props;

    if (!data) return null;

    console.log({ interlocutor: interlocutor });

    return(
      <Fragment>
        <div id="recipients">
          {inbox.type === 'Venue' &&
          <Can I='groupMessage' a='AdminMessages'>
            <GroupMessageButton data={data} inbox={inbox}/>
          </Can>
          }

          <ul className="list-group list-group-venues interlocutors">
            {data.map((item, index) =>
              <Interlocutor
                key={index}
                data={item}
                interlocutor={interlocutor}
                onChange={onChange}
              />
            )}
          </ul>
        </div>
      </Fragment>
    )
  }
}