import React, { Component } from 'react';
import Formatted from '../formatted';
import { PriceCell } from '../smart/cells';
import InfoActivityAddress from './info_activity_address';
import StatusLabel from '../involved_schedule_items/status_label'

export default class Info extends Component {
  render() {
    const { show, schedule_items, extras, activity } = this.props;

    if (!show) return null;

    return (
      <tr>
        <td colSpan={8} className='booking-info-block'>
          <div className='booking-info-block-table'>
            <div className="row">
              <div className="col-md-4">
                <strong>SCHEDULE OF ACTIVITY</strong>
                <table className="table-jsmall">
                  <tbody>
                  {schedule_items.map((item, index) =>
                    <tr key={index}>
                      <td className="text-center">
                        <Formatted.Date date={item.event_date}/>
                      </td>
                      <td><span>{item.time_slot}</span></td>
                      <td style={{width: 1}}><StatusLabel value={item.status}/></td>
                    </tr>
                  )}
                  </tbody>
                </table>
              </div>
              <div className="col-md-4">
                <InfoActivityAddress activity={activity}/>
              </div>
              <div className="col-md-4">
                {extras.length > 0 &&
                <div>
                  <strong>EXTRAS</strong>
                  <table className="table-jsmall">
                    <tbody>
                    {extras.map((item, index) =>
                      <tr key={index}>
                        <td>{item.title}</td>
                        <td>
                          <PriceCell value={item.price}/>
                        </td>
                      </tr>
                    )}
                    </tbody>
                  </table>
                </div>
                }
              </div>
            </div>
          </div>
        </td>
      </tr>
    )
  }
}