import React, { Component } from 'react';
import TimePicker from 'rc-time-picker';

const MinuteStep = 5;

export default class TimeSlotInput extends Component {
  render() {
    const { start, end, defaultValue } = this.props;

    return (
      <div>
        <TimePicker
          style={{ width: 113, display: 'inline-block' }}
          {...start}
          showSecond={false}
          inputReadOnly
          minuteStep={MinuteStep}
        />
        &nbsp;&nbsp;
        -
        &nbsp;&nbsp;
        <TimePicker
          style={{ width: 113, display: 'inline-block' }}
          {...end}
          showSecond={false}
          inputReadOnly
          minuteStep={MinuteStep}
        />
      </div>
    );
  }
}