import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import PubSub from 'pubsub-js';

class NewButton extends Component {
  render() {
    return (
      <Button bsStyle="primary" className="btn-block" onClick={() => PubSub.publish(`${this.props.name}.New`)}>
        {this.props.title}
      </Button>
    )
  }
}

class EditButton extends Component {
  render() {
    return (
      <Button bsStyle="primary" className="btn-block" onClick={() => PubSub.publish(`${this.props.name}.Edit`)}>
        {this.props.title}
      </Button>
    )
  }
}

class Header extends Component {
  render() {
    const { children, leftSide } = this.props;

    return (
      <h3 className="page-title">
        {children}

        <div className="pull-right">
          {leftSide}
        </div>
      </h3>
    )
  }
}

class Page extends Component {
}

Page.NewButton = NewButton;
Page.EditButton = EditButton;
Page.Header = Header;

export default Page;