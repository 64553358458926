import React, { useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import StripeForm from './StripeForm';
import { loadStripe } from "@stripe/stripe-js";
import OfflineForm from "./OfflineForm";

export default ({ paymentPath, setShow, spinnerMessage, buttonTitle, offline }) => {
  const stripePromise = useMemo(() => { return loadStripe(window.stripeKey) }, []);

  if (offline) return (
    <OfflineForm
      paymentPath={paymentPath}
      setShow={() => setShow(false)}
      spinnerMessage={spinnerMessage}
      buttonTitle={buttonTitle}
    />
  );

  return (
    <Elements stripe={stripePromise}>
      <StripeForm
        paymentPath={paymentPath}
        setShow={() => setShow(false)}
        spinnerMessage={spinnerMessage}
        buttonTitle={buttonTitle}
      />
    </Elements>
  )
};

