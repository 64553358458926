import React, { Component } from 'react';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

export default class SliderInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 18
    };
  }

  handleChange = (value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    const { name } = this.props;

    return (
      <div>
        <div style={{ width: 50, float: 'right' }}>
          <input className="form-control text-center" name={name} value={value}/>
        </div>

        <div style={{ marginRight: 70, paddingTop: 10 }}>
          <Slider
            min={0}
            max={18}
            defaultValue={value}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}
