import React from 'react';
import SmartLabel from '../smart/label';

export default class StatusLabel extends SmartLabel {
  constructor() {
    super();

    this.items = {
      true: { className: 'label-booking-offline', title: 'Offline' }
    }
  }
}