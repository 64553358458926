import React, { Component } from 'react';

export default class SmallGallery extends Component {
  constructor(props) {
    super(props);
    this.smallGalleryRef = React.createRef();
  }

  componentDidMount() {
    this.initGallery();
  }

  componentWillUnmount() {
    this.destroyGallery();
  }

  componentWillUpdate() {
    this.destroyGallery();
  }

  componentDidUpdate() {
    this.initGallery();
  }

  initGallery = () => {
    $(this.smallGalleryRef.current).lightSlider({
      item: 1,
      slideMargin: 0,
      loop: true,
      pager: false
    });
  };

  destroyGallery = () => {
    let smallGallery = $(this.smallGalleryRef.current).lightSlider();
    smallGallery.destroy();
  };

  render() {
    const { images } = this.props;

    if (!images) return null;

    return (
      <div style={{ overflow: 'hidden', backgroundColor: '#eee' }}>
        <ul className="smallGallery" ref={this.smallGalleryRef}>
          {images.map((image, index) =>
            <li key={index}>
              <a>
                <img className="img-responsive" src={image.thumb} />
              </a>
            </li>
          )}
        </ul>
      </div>
    );
  }
};
