import React, { Component } from 'react';

export default class SimpleBooking extends Component {
  render() {
    return (
      <div className="container">
        <div className="row">
          <h3>SchoolHire makes booking simple</h3>

          <div id="first_block">
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 search_block">
              <h6>Search</h6>
              <p>
                Find the exact activity you need in a matter of seconds at an affordable price.
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 fr view_block">
              <h6>View</h6>
              <p>
                Pictures? Prices? It’s all there. But if you do have a question,
                connect with the venue directly via our integrated instant messenger.
              </p>
            </div>
            <div className="clear"/>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 book_block">
              <h6>Book</h6>
              <p>
                No need to bring cash on the day. Pay instantly and securely using our online booking and payment platform.
              </p>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 fr track_block">
              <h6>Relax</h6>
              <p>Sit back and chill out; you have just booked a fantastic activity!</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
};