import React, { Component, Fragment } from 'react';
import { Button, Alert } from 'react-bootstrap';
import SmartBlock from '../../smart/block';
import FieldGroup from "../../smart/field_group";
import Modal from "./modal";
import Row from './row';
import moment from 'moment';
import axios from 'axios';

export default class Block extends SmartBlock {
  constructor() {
    super();

    this.state = { attachmentType: 'current' }
  }

  requestData() {
    const { path, venueCustomerId } = this.props;
    const data = { venue_customer_id: venueCustomerId };

    return new axios.get(`${path}.json`, { params: data })
  }

  handleAttachmentType = (e) => {
    this.setState({ attachmentType: e.target.value })
  };

  renderTable() {
    const { created, data, attachmentType } = this.state;
    let filtered_data = data;

    if (!created) return <div className='text-center'>Loading...</div>;

    if (attachmentType === 'current') {
      filtered_data = filtered_data.filter((item) => {
        return (item.expired_at === null || moment(item.expired_at).isSameOrAfter(moment()))
      })
    }

    if (filtered_data.length === 0) {
      return (
        <Alert bsStyle="info">
          <div className='text-center'>There are no documents</div>
        </Alert>
      );
    }

    return (
      <table className="table table-striped table-bordered table-xs">
        <thead>
        <tr>
          <th>Title</th>
          <th>File</th>
          <th>Expiry Date</th>
          <th/>
        </tr>
        </thead>
        <tbody>
        {filtered_data.map((item) =>
          <Row
            key={item.id}
            {...item}
            onEdit={this.handleEdit}
            onDelete={this.handleDelete}
          />
        )}
        </tbody>
      </table>
    )
  }

  render() {
    const { path, venueCustomerId } = this.props;
    const { attachmentType } = this.state;

    return (
      <Fragment>
        <Modal name='Attachment' path={path} title="File" venueCustomerId={venueCustomerId}/>

        <div className='row vert-offset-bottom-10 attachments-types'>
          <div className='col-md-9 form-inline'>
            <FieldGroup
              name='attachment_type'
              type="radio-group"
              value={attachmentType}
              defaultValue={attachmentType}
              options={[
                { id: 'all', title: 'All documents' },
                { id: 'current', title: 'Current documents' },
              ]}
              onChange={this.handleAttachmentType}
            />
          </div>
          <div className='col-md-3 text-right'>
            <Button bsStyle="primary" onClick={this.handleNew}>Add document</Button>
          </div>
        </div>

        {this.renderTable()}
      </Fragment>
    )
  }
}

