import React, { Component, Fragment } from 'react';
import classnames from 'classnames';
import PubSub from 'pubsub-js';
import Formatted from '../../formatted';
import axios from 'axios';

const itemClassNames = (item) => {
  const { booking_option_item_id, enabled } = item;
  return { success: booking_option_item_id && enabled };
};

class ChildRow extends Component {
  render() {
    const { id, event_date, time_slot, places, onRevert } = this.props;

    return (
      <tr key={id} className={classnames([itemClassNames(this.props), 'child'])}>
        <td className="text-center">
          <i style={{color: '#770088'}} className='fa fa-chevron-circle-right'/>
        </td>
        <td className="text-center"><u><Formatted.Date date={event_date}/></u></td>
        <td className="text-center"><u>{time_slot}</u></td>
        <td className="text-center"><u>{places}</u></td>
        <td/>
        <td/>
        <td/>
        <td/>
        <td>
          <div className="btn-group btn-group-xs" style={{width: '100%'}}>
            <button className="btn btn-warning" style={{width: '100%'}} onClick={() => onRevert(id)}>
              Revert changes
            </button>
          </div>
        </td>
      </tr>
    )
  }
}

export default class Item extends Component {
  edit = () => {
    const { id } = this.props;
    PubSub.publish('ScheduleItem.Edit', { id: id })
  };

  remove = () => {
    const { id, path, onChanged, groupUid } = this.props;
    axios
      .delete(`${path}/${id}.json?group_uid=${groupUid}`)
      .then(() => onChanged())
      .catch((response) => console.log('error', response));
  };

  revert = (id) => {
    const { path, onChanged } = this.props;
    axios
      .delete(`${path}/${id}.json`)
      .then(() => onChanged())
      .catch((response) => console.log('error', response));
  };

  disable = () => {
    const { id, path, onChanged, groupUid } = this.props;
    axios
      .post(`${path}/${id}/disable.json?group_uid=${groupUid}`)
      .then(() => onChanged())
      .catch((response) => console.log('error', response));
  };

  enable = () => {
    const { id, path, onChanged, groupUid } = this.props;
    axios
      .post(`${path}/${id}/enable.json?group_uid=${groupUid}`)
      .then(() => onChanged())
      .catch((response) => console.log('error', response));
  };

  render() {
    const {
      id,
      status,
      event_date,
      time_slot,
      places,
      enabled,
      booked_places,
      available_places,
      child,
      booking_option_item_id
    } = this.props;

    const nonEditChild = child && ['removed', 'enabled', 'disabled'].includes(child.change_type);
    const editChild = child && child.change_type === 'updated';
    const hasChildClass = child && editChild ? 'has-child' : '';

    return (
      <Fragment>
        <tr key={id} className={classnames([itemClassNames(this.props), hasChildClass])}>
          <td className="text-center">
            <span style={{
              color: status !== 'success' ? '#ff2e00' : '#57d500',
              transition: 'all .3s ease'
            }}>
              &#x25cf;
            </span>
          </td>
          <td className="text-center"><Formatted.Date date={event_date}/></td>
          <td className="text-center"><div>{time_slot}</div></td>
          <td className="text-center">{places}</td>
          <td className="text-center">{booked_places}</td>
          <td className="text-center">{available_places}</td>
          <td className="text-center">
            {enabled ? 'Yes' : 'No'}
          </td>
          <td className="text-center">
            {!booking_option_item_id && <span className='label label-primary'>New</span>}
            {child && nonEditChild &&
              <span className='label label-danger'>{child.change_type}</span>
            }
          </td>
          <td className='text-center'>
            <div className="btn-group btn-group-xs" style={{ width: '100%'}}>
              {child && nonEditChild &&
                <button className="btn btn-warning" style={{width: '100%'}} onClick={() => this.revert(child.id)}>
                  Revert changes
                </button>
              }

              {child && editChild &&
              <button style={{ width: '100%'}} type='button' className="btn btn-primary" onClick={this.edit}>
                Edit
              </button>
              }

              {!child &&
                <Fragment>
                  <button style={{ width: '50%'}} type='button' className="btn btn-primary" onClick={this.edit}>
                    Edit
                  </button>
                  {booked_places > 0 && enabled &&
                  <button style={{ width: '50%'}} className="btn btn-warning" onClick={this.disable}>
                    Disable
                  </button>
                  }
                  {booked_places > 0 && !enabled &&
                  <button style={{ width: '50%'}} className="btn btn-warning" onClick={this.enable}>
                    Enable
                  </button>
                  }
                  {(booked_places === 0 || booking_option_item_id === null) &&
                  <button style={{width: '50%'}} className="btn btn-danger" onClick={this.remove}>
                    Delete
                  </button>
                  }
                </Fragment>
              }
            </div>
          </td>
        </tr>
        {child && editChild &&
        <ChildRow
          booking_option_item_id={booking_option_item_id}
          {...child}
          onRevert={this.revert}
        />
        }
      </Fragment>
    )
  }
}