import React, { Fragment } from 'react';
import SmartModal from '../../smart/modal';
import FieldGroup from '../../smart/field_group';

export default class Modal extends SmartModal {
  applyAmend = () => {
    const { data } = this.state;
    this.applyDefault('amending', { method: 'amend', id: data.id });
  };

  showCancel = () => {
    this.showDefault('cancellation');
  };

  renderCancelForm = () => {
    return (
      <Fragment>
        <p>
          Once this has been submitted, the booking will be cancelled. The customer
          will recevie a proportional refund for any future sessions booked
          as part of this booking and will receive a system message informing
          them of the cancellation.
        </p>

        <p>
          The customer will be removed from the activity and the space
          will be free to other customers to book.
        </p>

        <FieldGroup
          label="Message"
          type="textarea"
          name="cancellation[message]"
          rows={4}
        />
      </Fragment>
    )
  };

  applyCancel = () => {
    this.applyDefault('cancellation');
  }
}
