import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

class ActionButtonRow extends Component {
  render() {
    const { title, description, onClick } = this.props;

    return (
      <div className="row">
        <div className="col-md-4">
          <Button bsStyle='primary' className="create_notes_action notes-buttons" onClick={onClick}>
            {title}
          </Button>
        </div>
        <div className="col-md-8 action-description" style={{ lineHeight: '32px' }}>
          {description}
        </div>
      </div>
    )
  }
}

export default class Editor extends Component {
  render() {
    const { onSubmit } = this.props;

    return (
      <div className="row vert-offset-bottom-15">
        <div className="col-md-7">
          <textarea
            name="internal_note"
            className="form-control"
            style={{height: '120px'}}
            defaultValue={""}
          />
        </div>
        <div className="col-md-5 actions">
          <ActionButtonRow
            onClick={() => onSubmit('create')}
            title='Create'
            description='overwrites the current text or adds a new note'
          />

          <div className='vert-offset-bottom-10'/>

          <ActionButtonRow
            onClick={() => onSubmit('update')}
            title='Update'
            description='adds additional text to the current note'
          />

          <div className='vert-offset-bottom-10'/>

          <ActionButtonRow
            onClick={() => onSubmit('destroy')}
            title='Delete'
            description='deletes the current text shown'
          />
        </div>
      </div>
    )
  }
}