import React, { Component } from 'react';
import Block from "./block";
import Page from '../page';

export default class Index extends Component {
  render() {
    return (
      <div>
        <Page.Header>
          Info pages
        </Page.Header>

        <Block
          title="Info Page"
          path="/admin/info_pages"
          name="InfoPage"
        />
      </div>
    );
  }
}
