import React, { Component, Fragment } from 'react';
import Copyright from './footer/copyright';
import InfoPages from './footer/info_pages';
import Social from './footer/social';
import { StandaloneContext } from '../standalone';
import StandaloneFooter from './standalone/footer';

const columnsOptions = {
  general: [
    'col-lg-3 col-md-4 col-sm-12 col-xs-12',
    'col-lg-2 col-md-4 col-sm-6 col-xs-12',
    'col-lg-2 col-md-4 col-sm-6 col-xs-12'
  ],
  search: [
    'col-lg-5 col-md-5 col-sm-12 col-xs-12',
    'col-lg-3 col-md-3 col-sm-12 col-xs-12',
    'col-lg-3 col-md-3 col-sm-12 col-xs-12'
  ]
};

export default class Footer extends Component {
  render() {
    const { type } = this.props;
    let option = type || 'general';

    return (
      <StandaloneContext.Consumer>
        {standalone => (
          <Fragment>
            {!standalone &&
            <footer>
              <div className="container">
                <div className={columnsOptions[option][0]}>
                  <Copyright/>
                </div>
                <div className={columnsOptions[option][1]}>
                  <InfoPages/>
                </div>
                <div className={columnsOptions[option][2]}>
                  <Social/>
                </div>
              </div>
            </footer>
            }

            {standalone && <StandaloneFooter/>}
          </Fragment>
        )}
      </StandaloneContext.Consumer>
    )
  }
}
