import React, { Component } from 'react';
import Formatted from "../../formatted";
import PubSub from 'pubsub-js';
import classnames from 'classnames';

export default class InvolvedScheduleItem extends Component {
  handleShowInfo = (e) => {
    const { schedule_item_id } = this.props;

    e.preventDefault();
    PubSub.publish('Calendar.Info', { id: schedule_item_id, modalTitle: 'Session details' })
  };

  render() {
    const {
      start_time,
      end_time,
      title,
      scheduled_places,
      booked_places,
      session_number,
      sessions_count
    } = this.props;

    let classNames = classnames(['booking', status]);

    return (
      <a className={classNames} href="#" onClick={this.handleShowInfo}>
        <Formatted.Time value={start_time} showSeconds={false}/>
        &nbsp;-&nbsp;
        <Formatted.Time value={end_time} showSeconds={false}/>
        &nbsp;{title}
        &nbsp;({booked_places}/{scheduled_places})
        &nbsp;S{session_number} of {sessions_count}
      </a>
    )
  }
}
