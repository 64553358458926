import React, { Component } from 'react';
import Formatted from "../../../formatted";

export default class Row extends Component {
  render() {
    const {
      name,
      created_at,
      booking_id,
      invoice_index,
      total,
      vat_total,
      gross_total,
      sh_commission,
      venue_payment_fees,
      net_total,
      sh_vat,
      gross_sh_total,
      total_amount_remitted
    } = this.props;

    return (
      <tr>
        <td className="text-center">{name}</td>
        <td className="text-center"><Formatted.Date value={created_at} showWeekDay={false}/></td>
        <td className="text-center"><a target='_blank' href={`/admin/bookings/${booking_id}`}>{invoice_index}</a></td>
        <td className="text-center"><Formatted.Price price={total} brackets={true}/></td>
        <td className="text-center"><Formatted.Price price={vat_total} brackets={true}/></td>
        <td className="text-center"><Formatted.Price price={gross_total} brackets={true}/></td>
        <td className="text-center"><Formatted.Price price={sh_commission} brackets={true}/></td>
        <td className="text-center"><Formatted.Price price={venue_payment_fees} brackets={true}/></td>
        <td className="text-center"><Formatted.Price price={net_total} brackets={true}/></td>
        <td className="text-center"><Formatted.Price price={sh_vat} brackets={true}/></td>
        <td className="text-center"><Formatted.Price price={gross_sh_total} brackets={true}/></td>
        <td className="text-center"><Formatted.Price price={total_amount_remitted} brackets={true}/></td>
      </tr>
    )
  }
}