import React from 'react';
import SmartModal from '../../smart/modal';

export default class Modal extends SmartModal {
  renderForm() {
    return (
      <div>
        <SmartModal.FieldGroup
          type="text"
          label="Title"
          name="venue_collection[title]"
          defaultValue={this.formValue('title')}
          error={this.formError('title')}
        />
        <SmartModal.FieldGroup
          type="textarea"
          label="Description"
          name="venue_collection[description]"
          defaultValue={this.formValue('description')}
          error={this.formError('description')}
        />
        <SmartModal.FieldGroup
          type="file"
          label="Image"
          name="venue_collection[image]"
          defaultValue={this.formValue('image')}
          error={this.formError('image')}
        />
        <SmartModal.FieldGroup
          type="select"
          label="Activity category"
          options={this.includedData('activity_categories')}
          name="venue_collection[activity_category_id]"
          defaultValue={this.formValue('activity_category_id')}
          error={this.formError('activity_category_id')}
        />
      </div>
    );
  }
}
