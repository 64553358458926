import React, { Fragment } from 'react';
import SmartModal from '../../smart/modal';
import FieldGroup from '../../smart/field_group';
import moment from 'moment';

export default class Modal extends SmartModal {
  renderForm() {
    const { venueCustomerId } = this.props;

    const id = this.formValue("id");
    let expiredAt = null;
    let expired_at = '';

    if (this.formValue("expired_at")) {
      expiredAt = moment(this.formValue("expired_at"));
      expired_at = expiredAt.isValid() ? expiredAt.format('D MMM YYYY') : '';
    }

    return (
      <div>
        <FieldGroup
          label="Title"
          name="attachment[title]"
          defaultValue={this.formValue("title")}
          error={this.formError("title")}
        />

        {!id &&
        <Fragment>
          <FieldGroup
            label="File"
            type="file"
            name="attachment[attachment]"
            defaultValue={this.formValue("attachment")}
            error={this.formError("attachment")}
          />

          <input type='hidden' name='venue_customer_id' value={venueCustomerId}/>
        </Fragment>
        }

        <FieldGroup
          label="Expiry date"
          type="date2"
          format="D MMM YYYY"
          name="attachment[expired_at]"
          defaultValue={expired_at}
          error={this.formError("expired_at")}
        />
      </div>
    );
  }
}
