import React, { Component } from 'react';
import Document from './document';

export default class Documents extends Component {
  constructor(props) {
    super(props);

    this.state = { newDocuments: [0] }

    this.addDocument = this.addDocument.bind(this);
    this.removeNewDocument = this.removeNewDocument.bind(this);
  }

  addDocument(e) {
    e.preventDefault();
    let newDocuments = this.state.newDocuments;
    newDocuments.push(0);
    this.setState(newDocuments)
  }

  removeNewDocument(e, index) {
    e.preventDefault();
    let { newDocuments } = this.state;
    newDocuments.splice(index, 1);
    this.setState(newDocuments)
  }

  render() {
    const { documents } = this.props;
    const { newDocuments } = this.state;

    return (
      <div>
        <div className="form-group file form-inline">
          <label className="file optional control-label">
            Attach documents:
          </label>
          <div className="documents">
            {documents.map((document, index) =>
              <Document key={index} document={document} index={index}/>
            )}

            {newDocuments.map((document, index) =>
              <Document key={index} onRemove={this.removeNewDocument} index={index}/>
            )}

            <a className="add-question" href="#" onClick={this.addDocument}>Add document</a>
          </div>
        </div>
      </div>
    )
  }
}