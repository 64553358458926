import React, { Component } from 'react';
import Table from "./table";
import Page from '../page';
import InviteButton from './invite_button';

export default class Users extends Component {
  render() {
    return (
      <div>
        <Page.Header rightSide={<InviteButton title="Invite user" name="User"/>}>
          Users
        </Page.Header>

        <Table title="User" name="User" path="/admin/users"/>
      </div>
    );
  }
}
