import React, { Component } from 'react';
import axios from 'axios';
import FieldGroup from '../../smart/field_group';
import queryString from 'query-string';
import { HelpBlock } from 'react-bootstrap';

export default class Edit extends Component {
  constructor() {
    super();

    this.state = {
      data: {},
      included: {},
      formData: {},
      formErrors: {},
    };

    this.formRef = React.createRef();
  }

  confirmPassword = () => {
    axios
      .put('/users/password.json', new FormData(this.formRef.current))
      .then(() => {
        window.location.href = '/'
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  };

  formValue(name) {
    const { formData } = this.state;
    if (formData === undefined) return undefined;
    return (name in formData) ? formData[name] : undefined;
  }

  formError(name) {
    const { formErrors } = this.state;
    if (formErrors === undefined) return null;
    return (name in formErrors) ? formErrors[name] : null;
  }

  includedData(name) {
    const { included } = this.state;
    if (included === undefined) return undefined;
    return (name in included) ? included[name].data : undefined;
  }

  render() {
    const query = queryString.parse(location.search);
    const resetPasswordTokenError = this.formError('reset_password_token');

    return (
      <div style={{ width: 340, margin: '0 auto' }}>
        <form ref={this.formRef}>
          <h3>Choose your password</h3>

          <FieldGroup
            label="New password"
            autoFocus={true}
            autoComplete="off"
            type="password"
            name="user[password]"
            error={this.formError('password')}
          />

          <span className="help-block">
            Password requirements: minimum of 8 characters, with at least 1 upper case letter,
            1 lower case letter and 1 number
          </span>

          <FieldGroup
            label="Confirm new password"
            autoComplete="off"
            type="password"
            name="user[password_confirmation]"
            error={this.formError('password_confirmation')}
          />

          <input
            type="hidden"
            value={query.reset_password_token}
            name="user[reset_password_token]"
          />

          {resetPasswordTokenError && <HelpBlock>Token {resetPasswordTokenError}</HelpBlock>}

          <div className="form">
            <div className="sbm w305">
              <button name="commit" type="button" className="button right" onClick={this.confirmPassword}>
                Confirm Password
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
};
