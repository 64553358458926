import React, { Component } from 'react'
import Inbox from './inbox';

export default class Inboxes extends Component {
  render() {
    const { onChange, data, inbox } = this.props;

    if (!data) return null;
    
    return(
      <div id="conversations_menu">
        <ul className="list-group list-group-venues" id="conversations">
          {data.map((item, index) =>
            <Inbox
              key={index}
              data={item}
              inbox={inbox}
              onChange={onChange}
            />
          )}
        </ul>
      </div>
    )
  }
}