import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Formatted from "../../formatted";

export default class Row extends Component {
  render() {
    const {
      id,
      title,
      filename,
      url,
      expired_at,
      onEdit,
      onDelete
    } = this.props;

    return (
      <tr>
        <td style={{ width: 300 }}>
          {title}
        </td>
        <td style={{ width: 300 }}>
          <a href={url} target="_blank">{filename}</a>
        </td>
        <td style={{ width: 120 }} className='text-center'>
          <Formatted.Date value={expired_at} showWeekDay={false}/>
        </td>
        <td style={{width: 90}}>
          <div className="btn-group">
            <Button bsStyle="primary" bsSize="xsmall" onClick={() => onEdit(id)}>Edit</Button>
            <Button bsStyle="danger" bsSize="xsmall" onClick={() => onDelete(id)}>Delete</Button>
          </div>
        </td>
      </tr>
    )
  }
}