import React, { Component } from 'react';

export const StandaloneContext = React.createContext();

export class StandaloneProvider extends Component {
  constructor() {
    super();

    const standalone = (window.PageData || {}).standalone;

    this.state = { standalone };
  }

  render() {
    const { children } = this.props;
    const { standalone } = this.state;

    return (
      <StandaloneContext.Provider value={standalone}>
        {children}
      </StandaloneContext.Provider>
    );
  }
}