import React, { Fragment } from 'react';
import SmartModal from '../../../smart/modal';
import GroupMessageButton from '../../../messages/group_message_button';
import ModalBookingItem from './booking_item';

export default class Modal extends SmartModal {
  render() {
    const { items, formData } = this.props;

    if (!items) return null;
    if (!(Array.isArray(items) && items.length > 0)) return null;

    let data = items.map(item => {
      return { id: item.user_id, type: 'User', name: item.name }
    });

    return (
      <Fragment>
        <div className='pull-right'>
          <GroupMessageButton
            path="admin/messages"
            name="GroupMessage"
            title="Message"
            inbox={{ type: 'Venue', id: formData.venue_id }}
            data={data}
          />
        </div>

        <table className="table table-bordered-2">
          <thead>
          <tr>
            <th scope="col">Attendee</th>
            <th scope="col">Customer</th>
            <th scope="col">Phone number</th>
            <th scope="col"/>
          </tr>
          </thead>
          <tbody>
          {items.map((item, index) =>
            <ModalBookingItem
              key={index}
              {...item}
              venue_id={formData.venue_id}
            />
          )}
          </tbody>
        </table>
      </Fragment>
    )
  }
}
