import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

const DisableButton = (props) => (
  <Button bsSize='xs' className='btn-promo-code btn-promo-code-disable' onClick={props.onClick}>
    Disable
  </Button>
);

const EnableButton = (props) => (
  <Button bsSize='xs' className='btn-promo-code btn-promo-code-enable' onClick={props.onClick}>
    Enable
  </Button>
);

export default class StatusButton extends Component {
  handleEnable = () => {
    const { id } = this.props;
    PubSub.publish('PromoCode.Enable', { id: id });
  };

  handleDisable = () => {
    const { id } = this.props;
    PubSub.publish('PromoCode.Disable', { id: id });
  };

  render() {
    let { enabled } = this.props;
    return enabled ? <DisableButton onClick={this.handleDisable}/> :  <EnableButton onClick={this.handleEnable}/>;
  }
}
