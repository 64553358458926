import React, { Component } from 'react'
import classnames from 'classnames';

export default class Inbox extends Component {
  changeInbox = (e) => {
    e.preventDefault();
    const { onChange, data } = this.props;
    onChange(data);
  };

  render() {
    const { data, inbox } = this.props;
    const { type, id, name, unread_conversations_count } = data;

    let selectedInbox = inbox || {};

    let classNames = classnames(
      [
        'list-group-item',
        'inbox_conversation_item',
        { 'selected': (selectedInbox.id === id && selectedInbox.type === type) }
      ]
    );

    return (
      <li className={classNames}>
        {unread_conversations_count > 0 &&
        <span className="badge badge-green">{unread_conversations_count}</span>
        }
        <strong>
          <a title={name} className="recipients" href="#" onClick={this.changeInbox}>{name}</a>
        </strong>
      </li>
    )
  }
}