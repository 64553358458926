import React, { Component } from 'react';
import Formatted from "../../../formatted";

export default class BookingItemRow extends Component {
  render() {
    const {
      id,
      user_name,
      user_phone,
      attendee_name,
      attendee_birthday,
      booking_id,
      extras,
      internal_note
    } = this.props;

    return (
      <tr>
        <td className='text-center' data-id={id}>
          {[user_name, user_phone].join(" - ")}
        </td>
        <td className='text-center'>
          {attendee_name}
        </td>
        <td className='text-center'>
          <Formatted.Date value={attendee_birthday} showWeekDay={false}/>
        </td>
        <td className='text-center'>
          <a target='_blank' href={`/admin/bookings/${booking_id}`}>{booking_id}</a>
        </td>
        <td>{extras.map(item => item.title).join(', ')}</td>
        <td>{internal_note}</td>
      </tr>
    );
  }
}
