import FieldGroup from "../../../smart/field_group";
import React from "react";

export default ({ onChange }) => (
  <FieldGroup
    label="Modifications:"
    type="radio-group"
    name="child_bill_status"
    defaultValue={null}
    options={[
      { id: null, title: "All", checked: true },
      { id: "pending", title: "Pending" },
      { id: "request", title: "Request" },
      { id: "failed_payment", title: "Failed Payment" },
    ]}
    onChange={onChange}
  />
)