import React from 'react';
import SmartLabel from '../../smart/label';

export default class PhoneLabel extends SmartLabel {
  constructor() {
    super();

    this.items = {
      true: { className: 'label-phone-visible', title: 'Visible on standalone site' }
    }
  }
}
