import React, { Component } from 'react';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

export default class RangeInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      minValue: 0,
      maxValue: 18
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(values) {
    this.setState({ minValue: values[0], maxValue: values[1] });
  }

  render() {
    const { minValue, maxValue } = this.state;

    return (
      <div>
        <div style={{ width: 50, float: 'left' }}>
          <input className="form-control text-center" value={minValue}/>
        </div>

        <div style={{ width: 50, float: 'right' }}>
          <input className="form-control text-center" value={maxValue}/>
        </div>

        <div style={{ marginRight: 70, marginLeft: 70, paddingTop: 10 }}>
          <Range
            min={0}
            max={18}
            defaultValue={[minValue, maxValue]}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }
}
