import React from 'react';
import SmartModal from '../../smart/modal';
import FieldGroup from '../../smart/field_group';
import axios from 'axios';

export default class Modal extends SmartModal {
  showRetract = () => {
    const { data } = this.state;
    const { path } = this.props;

    axios
      .get(path + '/' + data.id + '/retraction.json')
      .then((response) => {
        const response_data = response.data;
        this.setState({ formData: response_data.data, included: response_data.included, loading: false });
      })
      .catch((error) => {
        console.log(error)
      });
  };

  renderRetractForm = () => {
    return (
      <div>
        <p>
          Message
        </p>

        <FieldGroup
          label="Message"
          type="textarea"
          name="retraction[message]"
          error={this.formError('message')}
        />
      </div>
    )
  };

  applyRetract = () => {
    const { path, name } = this.props;
    const { data } = this.state;
    const requestData = this.getData();

    axios
      .post(path + '/' + data.id + '/retraction.json', requestData)
      .then(() => {
        this.handleClose();
        console.log(name + ".Updated");
        PubSub.publish(name + ".Updated", {});
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  }
}
