import React, { Component } from 'react';
import HeaderSearch from "./header_search";
import FormToObject from 'form-to-object';

export default class Header extends Component {
  constructor() {
    super();

    this.state = { ready: false };
    this.formRef = React.createRef();
  }

  handleReady = () => {
    const { onReady } = this.props;

    this.setState({ ready: true }, () => {
      if (onReady) onReady();
    })
  };

  handleUpdate = () => {
    const { onChangeSearch } = this.props;
    const data = FormToObject(this.formRef.current);
    if (onChangeSearch) onChangeSearch(data);
  };

  render() {
    const { TopLeft } = this.props;

    return (
      <form ref={this.formRef}>
        <div className="smart-table-header">
          <div className="smart-table-filter">
            {TopLeft && <TopLeft onReady={this.handleReady} onChangeSearch={this.handleUpdate}/>}
          </div>
          <div className="smart-table-search">
            {HeaderSearch && <HeaderSearch onChangeSearch={this.handleUpdate}/>}
          </div>
          <div className="clearfix"/>
        </div>
      </form>
    )
  }
};
