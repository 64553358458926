import React, { Component } from 'react';
import Nl2Br from '../smart/nl2br';

export default class Description extends Component {
  render() {
    const { text } = this.props;

    return (
      <div>
        <h3>
          <img src={require("images/desc-icon.png")} alt=""/>
          What you will find at this venue
        </h3>

        <Nl2Br text={text}/>
      </div>
    )
  }
}