import React, { Component, Fragment } from 'react';
import Formatted from '../formatted'
import DataItem from './data_item';

export default class InvoiceCategoryDetails extends Component {
  render() {
    const { data, billData, userData } =  this.props;

    if (!data) return null;

    const {
      company_name,
      invoice_address_line_1,
      invoice_city,
      invoice_postcode,
      invoice_phone_number,
      billing_email,
      vat_registration_number
    } = data;

    const {
      invoice_number,
      created_at,
    } = billData;

    const {
      name
    } = userData;

    return (
      <Fragment>
        <h5>VENUE DETAILS</h5>

        <ul className="invoice-list">
          <DataItem title="Name">{company_name}</DataItem>
          <DataItem title="Address">{invoice_address_line_1}</DataItem>
          <DataItem title="City">{invoice_city}</DataItem>
          <DataItem title="Postcode">{invoice_postcode}</DataItem>
          <br />
          <DataItem title="Tel">{invoice_phone_number}</DataItem>
          <DataItem title="Email"><a href={`mailto:${billing_email}`}>{billing_email}</a></DataItem>
        </ul>

        <ul className="invoice-list">
          <DataItem title="Vat registration number">{vat_registration_number}</DataItem>
          <DataItem title="Invoice number">{invoice_number}</DataItem>
          <DataItem title="Invoice date"><Formatted.Date date={created_at}/></DataItem>
          <DataItem title="Account name">{name}</DataItem>
        </ul>
      </Fragment>
    )
  }
}
