import React from 'react';
import SmartModal from '../../smart/modal';
import FieldGroup from '../../smart/field_group';
import { Can } from '../../ability_context';

export default class Modal extends SmartModal {
  handleChangeVenueAddressEnabled = (e) => {
    const venueAddressEnabled = e.target.checked;
    const { formData } = this.state;
    formData.venue_address_enabled = venueAddressEnabled;
    this.setState({ formData });
  };

  renderForm() {
    const id = this.formValue('id');
    const venueAddressEnabled = this.formValue('venue_address_enabled');

    let venue = null;

    if (id) {
      venue = this.includedData('venue') || {};
    } else {
      const venue_id = parseInt(this.state.selectedVenueId || this.includedData('venues')[0].id);
      venue = this.includedData('venues').find((venue) => venue.id === venue_id);
    }

    const {
      newPostCode,
      newAddress1,
      newCityId
    } = this.state;

    return (
      <div>
        {!id &&
          <FieldGroup
            type="select"
            options={this.includedData('venues')}
            label="Venue"
            name="activity[venue_id]"
            defaultValue={this.formValue('venue_id')}
            error={this.formError('venue')}
            onChange={(e) => this.setState({ selectedVenueId: e.target.value })}
          />
        }
        <FieldGroup
          type="select"
          options={this.includedData('activity_categories')}
          label="Category"
          name="activity[activity_category_id]"
          defaultValue={this.formValue('activity_category_id')}
          error={this.formError('activity_category')}
        />
        <FieldGroup
          type="text"
          label="Internal name"
          name="activity[internal_reference]"
          defaultValue={this.formValue('internal_reference')}
          error={this.formError('internal_reference')}
        />
        <FieldGroup
          type="text"
          label="Number of spaces"
          name="activity[places]"
          defaultValue={this.formValue('places')}
          error={this.formError('places')}
        />
        <FieldGroup
          type="checkbox"
          placeholder={<strong>Use venue address</strong>}
          name="activity[venue_address_enabled]"
          defaultValue={this.formValue('venue_address_enabled')}
          error={this.formError('address_1')}
          onChange={this.handleChangeVenueAddressEnabled}
        />
        <FieldGroup
          type="text"
          label="Address 1"
          name="activity[address_1]"
          value={newAddress1 || this.formValue('address_1')}
          replaced={venueAddressEnabled}
          replacedValue={venue.address_1}
          error={this.formError('address_1')}
          onChange={(e) => this.setState({ newAddress1: e.target.value })}
        />
        <FieldGroup
          type="select"
          options={this.includedData('cities')}
          label="City"
          name="activity[city_id]"
          value={newCityId || this.formValue('city_id')}
          replaced={venueAddressEnabled}
          replacedValue={venue.city_id}
          error={this.formError('city')}
          onChange={(e) => this.setState({ newCityId: e.target.value })}
        />
        <FieldGroup
          type="text"
          label="Postcode"
          name="activity[postcode]"
          value={newPostCode || this.formValue('postcode')}
          replaced={venueAddressEnabled}
          replacedValue={venue.postcode}
          error={this.formError('postcode')}
          onChange={(e) => this.setState({ newPostCode: e.target.value })}
        />

        <FieldGroup
          type="textarea"
          label="Description"
          name="activity[description]"
          defaultValue={this.formValue('description')}
          error={this.formError('description')}
          rows={8}
        />

        <Can I="seeOwnCanonicalLink" a="AdminActivities">
          <FieldGroup
            type="checkbox"
            placeholder={<strong>Own Canonical Link</strong>}
            name="activity[own_canonical_link]"
            defaultValue={this.formValue('own_canonical_link')}
            error={this.formError('own_canonical_link')}
          />
        </Can>
      </div>
    );
  }

  showPublish = () => {
    this.showDefault('publish');
  };

  renderPublishForm = () => {
    return <div><p className="text-center">Are you sure?</p></div>
  };

  applyPublish = () => {
    this.applyDefault('publish');
  }
}
