import React, { Component } from 'react';
import axios from 'axios';
import FieldGroup from "../smart/field_group";
import FormToObject from 'form-to-object';
import moment from 'moment';

export default class Filter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      category: window.PageData,
      showExtraFilter: false,
      activity_categories: undefined,
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchActivityCategories();
  }

  fetchActivityCategories() {
    const { updateData } = this.props;

    axios
      .get('/activity_categories.json')
      .then((response) => {
        this.setState({ activity_categories: response.data.data }, () => {
          const data = FormToObject(this.formRef.current);
          data.update_location = true;
          updateData(data);
        })
      })
      .catch((error) => console.log(error));
  }

  toggleExtraFilter = (e) => {
    e.preventDefault();
    this.setState({ showExtraFilter: !this.state.showExtraFilter })
  };

  fetchData = () => {
    const { updateData } =  this.props;
    const data = FormToObject(this.formRef.current);
    data.update_location = true;
    updateData(data);
  };

  render() {
    const { activity_categories, showExtraFilter } = this.state;
    const { category, area, date } = window.PageData;
    const { toggleExtraFilter } = this;

    return (
      <div className="filter-block">
        <div className="search-block">
          <form ref={this.formRef}>
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <ul className="search-filter">
                  <li>
                    <FieldGroup
                      type="select"
                      options={activity_categories}
                      name="category"
                      valueKey="title"
                      defaultValue={category}
                    />
                  </li>
                  <li>
                    <FieldGroup
                      type="location"
                      name="area"
                      defaultValue={area}
                    />
                  </li>
                  <li>
                    <FieldGroup
                      type="date"
                      name="date"
                      format="ddd, DD MMM YYYY"
                      defaultValue={moment(date, "DD/MM/YYYY").toDate()}
                    />
                  </li>
                </ul>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 col-lg-12 search-filter-submit">
                <div className="pull-left">
                  <a href="#" className="btn-link" onClick={toggleExtraFilter}>+ Maximize the filter</a>
                </div>

                <div className="form-group">
                  <button className="search" type="button" onClick={this.fetchData}>search</button>
                  <div className="clearfix"/>
                </div>
              </div>
            </div>

            {showExtraFilter &&
            <div className="hide-filter">
              <div className="">
                {false && <div className="row">
                  <div className="col-lg-12">
                    <FieldGroup
                      type="select"
                      options={[
                        { id: 'a', title: 'Nearest first' },
                        { id: 'b', title: 'Price, low to high' },
                        { id: 'c', title: 'Price, high to low' }
                      ]}
                      valueKey='title'
                      name="price_order"
                    />
                  </div>
                </div>}
                <div className="row">
                  <div className="col-lg-12 distance-filter">
                    <FieldGroup
                      label="Distance (miles):"
                      type="slider"
                      name="distance"
                    />
                  </div>
                </div>
                {false && <div className="row">
                  <div className="col-lg-12 price-filter">
                    <FieldGroup
                      label="Price (pounds/hour):"
                      type="range"
                      name="price"
                    />
                  </div>
                </div>}
              </div>
            </div>
            }
          </form>
        </div>
      </div>
    );
  }
}