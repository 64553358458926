import React, { Component } from 'react';
import classnames from 'classnames';

export default  class ExistImage extends Component {
  render() {
    const { onUpload, onDelete, onPrimary } = this.props;
    const { id, image_small_path, position, primary } = this.props.item;

    let boxClasses = classnames(['collection', { 'main-image-box': primary }]);

    return (
      <div className={boxClasses} style={{backgroundImage: `url(${image_small_path})`}}>
        <div className="overlay">
          <div className="overlay-wrapper">
            <div className="title">
              <div className="row">
                <div style={{ padding: '0 10px', width: '100%' }}>
                  <div className="col-md-6 text-left">
                    {!primary &&
                    <a className="btn btn-default btn-xs" onClick={(e) => onPrimary(id)}>
                      Main image
                    </a>
                    }
                  </div>
                  <div className="col-md-6 text-right">
                    <label className="btn btn-default btn-xs" title="Change image">
                      <div className="form-group file optional image_image hide">
                        <label className="file optional control-label">Image</label>
                        <input className="file optional file" type="file" onChange={(e) => onUpload(position, e)}/>
                      </div>
                      Change image
                    </label>
                    <a className="btn btn-default btn-xs btn-delete" onClick={(e) => onDelete(id)}>Delete</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
