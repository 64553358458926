import React, { Component } from 'react';

export default class Question extends Component {
  render() {
    const {text, option_text, comment, attachments} = this.props;

    return (
      <div className="vert-offset-bottom-10 question">
        <label className="question_text">
          {text}
        </label>
        {option_text && <div><strong>Answer: </strong> {option_text}</div>}
        {comment && <div><strong>Comment: </strong> {comment}</div>}

        {attachments && attachments.length > 0 &&
        <div>
          <div>
            <strong>Documents: </strong>
          </div>
          {attachments.map((item, index) =>
            <div key={index} className="vert-offset-bottom-10">
              <a className="terms_and_conditions_document_link" target="_blank" href={item.url}>
                {item.title}
              </a>
            </div>
          )}
        </div>
        }
      </div>
    )
  }
}