import React, { Component, Fragment } from 'react';
import TableHeader from "./table_header";
import TableRow from "./table_row";
import TableFooter from "./table_footer";

export default class Table extends Component {
  render() {
    const { items } = this.props;

    let showHeader, showItem, showTotals = false;
    let title, prevBoxType = null;

    return (
      <table className="table table-bordered">
        <tbody>
        {items.map((item, index) => {
          showHeader = (item.item_type !== prevBoxType && item.rows_aggregated > 0);
          prevBoxType = item.item_type;
          showItem = (item.group_type === 'item');
          showTotals = (item.group_type !== 'item' && item.rows_aggregated > 0);

          if (showHeader) {
            switch (item.item_type) {
              case 'offline':
                title = 'Offline Payments';
                break;
              case 'online':
                title = 'Online Payments';
                break;
              case 'total':
                title = 'Total Remittance';
                break;
            }
          }

          return (
            <Fragment key={index}>
              {showHeader && <TableHeader title={title}/>}
              {showItem && <TableRow {...item}/>}
              {showTotals && <TableFooter {...item}/>}
            </Fragment>
          )
        })}
        </tbody>
      </table>
    );
  }
}
