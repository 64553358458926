import axios from 'axios';

class Fetcher {
  venues() {
    const params = new URLSearchParams();
    params.append('sorted[0][id]', 'title');
    params.append('sorted[0][desc]', 'false');
    return axios.get('/admin/venues.json', { params: params });
  }

  activityCategories() {
    const params = new URLSearchParams();
    params.append('sorted[0][id]', 'title');
    params.append('sorted[0][desc]', 'false');
    return axios.get('/admin/activity_categories.json', { params: params });
  }

  activities(options) {
    const params = new URLSearchParams();
    params.append('sorted[0][id]', 'internal_reference');
    params.append('sorted[0][desc]', 'false');

    const venue_id = (options || {}).venue_id;
    const skip_pagination = (options || {}).skip_pagination;
    if (venue_id) params.append('filter[venue_id]', venue_id);
    if (skip_pagination) params.append('skip_pagination', skip_pagination);

    return axios.get('/admin/activities.json', { params: params });
  }

  bookingOptions(options) {
    const params = new URLSearchParams();
    const activity_id = (options || {}).activity_id;
    return axios.get(`/admin/activities/${activity_id}/booking_options.json`, { params: params });
  }

  calendarActivities(options) {
    const params = new URLSearchParams();

    const venue_id = (options || {}).venue_id;
    if (venue_id) params.append('venue_id', venue_id);

    return axios.get('/admin/calendar/activities.json', { params: params });
  }

  calendarActivityCategories(options) {
    const params = new URLSearchParams();

    const venue_id = (options || {}).venue_id;
    if (venue_id) params.append('venue_id', venue_id);

    return axios.get('/admin/calendar/activity_categories.json', { params: params });
  }
}

const Fetch = new Fetcher();

export default Fetch;
