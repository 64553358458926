import 'url-search-params-polyfill'; // for Edge [15, 16]

import React from 'react';

// jQuery plugins
require('imports-loader?jQuery=jquery!../../../node_modules/lightgallery/dist/js/lightgallery.js');
require('imports-loader?jQuery=jquery!../../../node_modules/lightslider/dist/js/lightslider.js');
require('imports-loader?jQuery=jquery!../../../node_modules/jquery-backstretch/jquery.backstretch.js');

import 'moment/locale/en-gb';
// Will be removed
import '../src/bootstrap/js/bootstrap';
import '../src/bootstrap/css/bootstrap';
import "react-table/react-table.css";
import 'react-redux-toastr/src/styles/index';
import 'react-day-picker/lib/style.css';
import 'rc-time-picker/assets/index.css';
import 'lightgallery/dist/css/lightgallery.css';
import 'lightslider/dist/css/lightslider.css';
import '../src/styles.scss';

import 'font-awesome/css/font-awesome.css';

import '../src/index';
import '../src/admin/index';
import '../src/account/index';
import '../src/render';
