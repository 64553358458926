import React, { Component } from 'react';
import { FormControl } from 'react-bootstrap';
import ReactSelect from 'react-select';
import ErrorBoundary from "../error_boundary";

export default class Select2 extends Component {
  constructor(props) {
    super(props);

    this.state = { value: props.defaultValue };
  }

  handleChange = (value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    const { options, name, isMulti } = this.props;

    return (
      <ErrorBoundary>
        <ReactSelect
          isClearable
          isMulti={isMulti}
          closeMenuOnSelect={false}
          options={options}
          value={value}
          onChange={this.handleChange}
        />

        {value.map((item, index) =>
          <input key={index} type='hidden' name={`${name}[]`} value={item.value} />
        )}
      </ErrorBoundary>
    )
  }
}