import React, { Component } from 'react';
import Page from '../page';
import ActivityBox from "../../shared/activity_box";

export default class Index extends Component {
  render() {
    return (
      <div>
        <Page.Header>
          Favourites
        </Page.Header>

         <div className="row activities-block">
           <div className="col-lg-3">
              <ActivityBox/>
           </div>
           <div className="col-lg-3">
             <ActivityBox/>
           </div>
           <div className="col-lg-3">
             <ActivityBox/>
           </div>
           <div className="col-lg-3">
             <ActivityBox/>
           </div>
           <div className="col-lg-3">
             <ActivityBox/>
           </div>
           <div className="col-lg-3">
             <ActivityBox/>
           </div>
           <div className="col-lg-3">
             <ActivityBox/>
           </div>
           <div className="col-lg-3">
             <ActivityBox/>
           </div>
         </div>
      </div>
    );
  }
}
