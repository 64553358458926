import React, { Component } from 'react';
import SmartLabel from '../../smart/label';
import classnames from 'classnames';

export default class StatusLabel extends SmartLabel {
  constructor() {
    super();

    this.items = {
      pending: { title: 'Pending', className: 'involved-attendee-status-pending', hidden: true },
      successful: { title: 'Successful', className: 'involved-attendee-status-successful' },
    };
  }

  renderLabel(selectedItem) {
    const spanClasses = classnames([selectedItem.className, 'fa fa-circle']);
    return (<i className={spanClasses}/>);
  }
}