import React from 'react';
import TableFilter from '../../smart/table_filter'
import FieldGroup from "../../smart/field_group";
import FormToObject from 'form-to-object';
import moment from 'moment';
import classnames from 'classnames';

export default class Filter extends TableFilter {
  constructor(props) {
    super(props);

    this.state = {
      showCustomDates: false
    };
  }

  getClassName() {
    return '';
  }

  handleChange = () => {
    const data = FormToObject(this.formRef.current);
    this.setState({ showCustomDates: (data.time_period === 'custom') }, () => {
      this.handleUpdateFilter();
    });
  };

  renderFields() {
    const { venues, showCustomDates } = this.state;

    return (
      <div>
        <FieldGroup
          label="Search"
          type="text-debounce"
          name="terms"
          onChange={this.handleChange}
        />

        <FieldGroup
          label="Statuses:"
          type="checkbox-group"
          name="statuses"
          options={[
            { value: "accepted", title: "Accepted", defaultChecked: true },
            { value: "cancelled", title: "Cancelled", defaultChecked: true },
            { value: "completed", title: "Completed", defaultChecked: true }
          ]}
          onChange={this.handleChange}
        />

        <FieldGroup
          label="Time period:"
          type="radio-group"
          name="time_period"
          defaultValue={null}
          options={[
            { id: null, title: "All Times", checked: true },
            { id: "today", title: "Today" },
            { id: "this_week", title: "This Week" },
            { id: "this_month", title: "This Month" },
            { id: "custom", title: "Custom" }
          ]}
          onChange={this.handleChange}
        />

        <div className={classnames(['custom_dates', { 'hidden': !showCustomDates }])}>
          <FieldGroup
            label="Start date"
            type="date"
            defaultValue={moment().subtract(1, 'months').toDate()}
            name="start_date"
            onChange={this.handleChange}
          />
          <FieldGroup
            label="End date"
            type="date"
            defaultValue={moment().toDate()}
            name="end_date"
            onChange={this.handleChange}
          />
        </div>

        <FieldGroup
          label="Modifications:"
          type="radio-group"
          name="child_bill_status"
          defaultValue={null}
          options={[
            { id: null, title: "All", checked: true },
            { id: "pending", title: "Pending" },
            { id: "request", title: "Request" },
            { id: "failed_payment", title: "Failed Payment" },
          ]}
          onChange={this.handleChange}
        />
      </div>
    )
  }
}
