import React from 'react';
import FieldGroup from "../../smart/field_group";
import TableFilter from '../../smart/table_filter'

const ROLE_OPTIONS = [
  { id: '', title: 'All' },
  { id: 'system_admin', title: 'System admin' },
  { id: 'venue_admin', title: 'Venue admin' },
  { id: 'subadmin', title: 'Subadmin' },
  { id: 'user', title: 'User' },
];

export default class Filter extends TableFilter {
  getClassName() {
    return '';
  }

  renderFields() {
    return (
      <div>
        <FieldGroup
          label="Search"
          type="text-debounce"
          name="terms"
          onChange={this.handleUpdateFilter}
        />

        <FieldGroup
          type="select"
          name="role"
          label="Role"
          options={ROLE_OPTIONS}
          onChange={this.handleUpdateFilter}
        />
      </div>
    );
  }
}
