import React, { Component } from 'react';
import NotificationsBlock from './notifications/block';

export default class Info extends Component {
  render() {
    const {
      name,
      email,
      phone,
      address,
      postcode,
      organisation,
      role_in_organisation
    } = this.props;

    return (
      <div className="row user_profile-box">
        <div className="col-lg-6">
          <dl className="dl-horizontal">
            <dt>Name:</dt>
            <dd>{name}</dd>
            <dt>Email:</dt>
            <dd>{email}</dd>
            <dt>Phone number:</dt>
            <dd>{phone}</dd>
            <dt>Address:</dt>
            <dd>{address}</dd>
            <dt>Postcode:</dt>
            <dd>{postcode}</dd>
            <dt>Organisation:</dt>
            <dd>{organisation}</dd>
            <dt>Role in organisation:</dt>
            <dd>{role_in_organisation}</dd>
          </dl>
        </div>

        <div className="col-lg-6">
          <NotificationsBlock/>
        </div>
      </div>
    );
  }
}
