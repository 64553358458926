import React, { Component } from 'react';
import FormToObject from 'form-to-object';

export default class TableFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.formRef = React.createRef();

    this.fetchData = this.fetchData.bind(this);
    this.handleUpdateFilter = this.handleUpdateFilter.bind(this);
  }

  getClassName() {
    return 'form-inline';
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
  }

  handleUpdateFilter() {
    const { onChangeSearch } = this.props;
    if (!onChangeSearch) return null;
    onChangeSearch(FormToObject(this.formRef.current));
  }

  renderFields() {
    return null;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <form ref={this.formRef}>
          {this.renderFields()}
        </form>
      </div>
    )
  }
};