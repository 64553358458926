import React, { Component, Fragment } from 'react';
import PubSub from 'pubsub-js';

export default class VenueActivityCategoryLink extends Component {
  render() {
    const { activity_category_title, venue_activity_category_id } = this.props;

    return (
      <a href={`admin/venue_activity_categories/${venue_activity_category_id}`}>
        {activity_category_title}
      </a>
    );
  }
}
