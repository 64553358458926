import React from 'react';
import { Checkbox } from 'react-bootstrap';

export default ({ name, options, extendedName, onChange }) => {
  function getName(index, extendedName) {
    let additionalPart = '';
    let additionalIndex = '';

    if (extendedName) {
      additionalIndex = `${index}`;
      additionalPart = `[${extendedName}]`;
    }

    return `${name}[${additionalIndex}]${additionalPart}`;
  }

  if (typeof options !== 'object') return null;

  return (
    options.map((option, index) =>
      <span key={index} className='checkbox'>
        <input type="hidden" name={getName(index, extendedName)} value="0"/>
        <Checkbox {...option} name={getName(index, extendedName)} onChange={onChange}>
          {option.title}
          {extendedName &&
            <input type="hidden" name={getName(index, 'id')} defaultValue={option.id}/>
          }
        </Checkbox>
      </span>
    )
  );
}
