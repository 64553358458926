import React, { Component } from 'react';
import FieldGroup from "../../smart/field_group";
import Fetch from "../fetch";

export default class TableTopLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { onChangeSearch } = this.props;

    Fetch
      .venues()
      .then((response) => {
        this.setState({ venues: response.data.data }, onChangeSearch)
      });

    Fetch
      .activityCategories()
      .then((response) => {
        this.setState({ activityCategories: response.data.data }, onChangeSearch)
      });
  };

  render() {
    const { onChangeSearch } = this.props;
    const { venues, activityCategories } = this.state;

    return (
      <div className="form-inline activity-filter">
        <FieldGroup
          label="Venue:"
          type="select"
          firstIfOne={true}
          className={'venue-input'}
          emptyOption={{ id: '', title: 'All' }}
          options={venues}
          name="venue_id"
          onChange={onChangeSearch}
        />

        <FieldGroup
          label="Categories:"
          type="select"
          className={'activity-category-input'}
          emptyOption={{ id: '', title: 'All' }}
          options={activityCategories}
          name="activity_category_id"
          onChange={onChangeSearch}
        />

        <FieldGroup
          label="Activity status:"
          type="select"
          className={'activity-status-input'}
          options={[
            { id: '', title: 'All activities' },
            { id: 'published', title: 'Published activites' },
            { id: 'unpublished', title: 'Unpublished activities' }
          ]}
          name="activity_status"
          onChange={onChangeSearch}
        />
      </div>
    )
  }
};