import React, { Component, Fragment } from 'react';
import Table from './table';
import { Alert } from 'react-bootstrap';

export default class Presenter extends Component {
  render() {
    const { data } = this.props;

    if (data === null) return null;
    if (data.items === null || data.items === undefined) return null;

    if (data.items.length === 0) {
      return (
        <Alert bsStyle='info' className='text-center'>
          There are no results for the time period searched. Please amend the dates and the report will update.
        </Alert>
      );
    }

    return <Table items={data.items}/>;
  }
}
