import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import PubSub from 'pubsub-js';
import { Can } from "../../ability_context";

export default class InviteButton extends Component {
  handleNew = () => {
    const { name } = this.props;
    PubSub.publish(`${name}.New`, { modalAction: 'Invite' })
  };

  render() {
    const { title } = this.props;

    return (
      <Can I="invite" a="AdminUsers">
        <Button bsStyle='primary' onClick={this.handleNew}>
          {title}
        </Button>
      </Can>
    )
  }
}
