import React, { Component } from 'react';
import UserDetails from "./user_details";
import InvoiceCategoryDetails from "./invoice_category_details";
import BankDetails from "./bank_details";
import BookingItemsTable from '../booking_items/table';
import axios from 'axios';

//https://github.com/Gera-IT/schoolhire/blob/198d61226e2601f4925d3ea3853402546a0b73a7/app/views/shared/invoices/show.html.haml

export default class Invoice extends Component {
  constructor(props) {
    super(props);

    this.state = { data: undefined };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { billId } = this.props;

    axios
      .get(`${window.location.pathname}/${billId}.json`)
      .then((response) => {
        const response_data = response.data;
        this.setState({ data: response_data.data });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  render() {
    const { data } = this.state;

    if (!data) return null;

    return (
      <div>
        <h3>
          Invoice
          <div className="pull-right">
            <a className="btn btn-block btn-primary" href={`${window.location.pathname}/${data.id}.pdf`}>
              Print to PDF
            </a>
          </div>
        </h3>

        <div className="row vert-offset-bottom-20">
          <div className="col-md-8">
            <UserDetails data={data.user.data}/>
          </div>

          <div className="col-md-4">
            <InvoiceCategoryDetails
              data={data.invoice_category.data}
              billData={data}
              userData={data.user.data}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 vert-offset-bottom-20">
            <BookingItemsTable
              extendedList={true}
              billData={data}
              items={data.booking_items.data}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <BankDetails data={data.invoice_category.data}/>
          </div>
        </div>
      </div>
    );
  }
}
