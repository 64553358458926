import React, { Component, Fragment } from 'react';

export default class TableHeader extends Component {
  render() {
    const { title } = this.props;

    return (
      <Fragment>
        <tr>
          <td colSpan="12">
            <strong>
              {title}
            </strong>
          </td>
        </tr>
        <tr>
          <th rowSpan="2">Name</th>
          <th rowSpan="2">Inv Date</th>
          <th rowSpan="2">Order Num</th>
          <th colSpan="3" rowSpan="1">Venue Invoice</th>
          <th colSpan="5" rowSpan="1">SchoolHire ("SH")</th>
          <th rowSpan="2">Total Remitted</th>
        </tr>
        <tr>
          <th>Total Booking Value</th>
          <th>VAT</th>
          <th>Gross Total</th>
          <th>SH Fees</th>
          <th>Payment Fees</th>
          <th>Net Total</th>
          <th>VAT</th>
          <th>Total Fees</th>
        </tr>
      </Fragment>
    );
  }
}
