import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import BookingOptionItemRow from './booking_option_item_row';

export default class Block extends Component {
  componentDidMount() {
    PubSub.subscribe(`BookingOptionItem.Deleted`, () => PubSub.publish('BookingOption.Updated'));
    PubSub.subscribe(`BookingOptionItem.Updated`, () => PubSub.publish('BookingOption.Updated'));
  }

  handleNew = () => {
    let { bookingOption } = this.props;
    PubSub.publish(`BookingOptionItem.New`, { bookingOptionId: bookingOption.id })
  };

  handleEdit = (id) => {
    let { bookingOption } = this.props;
    PubSub.publish(`BookingOptionItem.Edit`, { id: id, bookingOptionId: bookingOption.id })
  };

  handleDelete = (id) => {
    let { bookingOption } = this.props;
    PubSub.publish(`BookingOptionItem.Delete`, { id: id, bookingOptionId: bookingOption.id })
  };

  handleDisable = (id) => {
    let { bookingOption } = this.props;
    PubSub.publish(`BookingOptionItem.Disable`, { id: id, bookingOptionId: bookingOption.id })
  };

  handleEnable = (id) => {
    let { bookingOption } = this.props;
    PubSub.publish(`BookingOptionItem.Enable`, { id: id, bookingOptionId: bookingOption.id })
  };

  render() {
    let { bookingOption } = this.props;
    let bookingOptionItems = bookingOption.booking_option_items;

    return (
      <table className="table table-booking-option-items">
        <tbody>
          {!(bookingOptionItems.length === 1) &&
          <tr>
            <td>
              <button className="btn btn-primary btn-xs" onClick={this.handleNew}>
                Generate schedule
              </button>
            </td>
          </tr>
          }
          {bookingOptionItems.map((bookingOptionItem, index) =>
            <BookingOptionItemRow
              key={index}
              {...bookingOptionItem}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              onDisable={this.handleDisable}
              onEnable={this.handleEnable}
            />
          )}
        </tbody>
      </table>
    );
  }
};
