import React, { Component } from 'react';
import classnames from 'classnames';
import Moment from 'react-moment';
import Nl2Br from '../smart/nl2br';

export default class Message extends Component {
  render() {
    const { data, inbox } = this.props;
    const { body, created_at, attachment, read_at } = data;

    const { sender } = data;
    const outgoing = (inbox.type === sender.type && inbox.id === sender.id);

    let blockClassName = classnames([
      'message',
      { sent_message: outgoing},
      { received_message: !outgoing }
    ]);

    return (
      <div className={blockClassName}>
        <div className="triangle" />
        <div className="datetime">
          <Moment date={created_at} format="ddd, D MMM YYYY, HH:mm"/>
          <span className="pull-right"/>
        </div>
        <div>
          <div dangerouslySetInnerHTML={{ __html: body }}/>
        </div>
        <div>
        </div>

        <div>
          {attachment &&
          <div className="attachment">
            <div className="file-box">
              <div className="file">
                <a href={attachment.url}><span className="corner"/>
                  <div className="icon">
                    <i className="fa fa-file"/>
                  </div>
                  <div className="file-name" title="P1010015.JPG">
                    {attachment.name}
                  </div>
                </a></div>
            </div>
          </div>
          }
        </div>
      </div>
    )
  }
}