import React, { Component } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import 'moment-timezone';

const TimeZone = 'Europe/London';
const Locale = 'en-gb';
const ShortDateFormat = "D MMM YYYY";
const DefaultDateFormat = "ddd, D MMM YYYY";

const ParentMoment = (props) => <Moment date={props.date} format={props.format} locale={Locale} tz={TimeZone}/>;

class Date extends Component {
  render() {
    let { date, value, showWeekDay } = this.props;

    date = date || value;

    if (!date) return null;

    let format = DefaultDateFormat;
    if (showWeekDay === false) format = ShortDateFormat;

    return <ParentMoment date={moment.tz(date, TimeZone)} format={format}/>;
  }
}

class DateTime extends Component {
  render() {
    const { date, showSeconds } = this.props;
    const format = (showSeconds === false) ? "ddd, DD MMM YYYY LT" : "ddd, DD MMM YYYY LTS";
    return <ParentMoment date={date} format={format}/>
  }
}

class Time extends Component {
  render() {
    const { value, showSeconds } = this.props;
    const format = (showSeconds === false) ? "LT" : "LTS";
    return <ParentMoment date={value} format={format}/>
  }
}

class Price extends Component {
  render() {
    const { price, brackets } = this.props;
    const params = { style: 'currency', currency: 'GBP', currencyDisplay: 'symbol' };
    const intl = new Intl.NumberFormat('en-GB', params);

    return (brackets && price < 0) ? `(${intl.format(Math.abs(price))})` : intl.format(price);
  }
}

class Percent extends Component {
  render() {
    const { percent } = this.props;
    return `${percent}%`;
  }
}

export default class Formatted {};

Formatted.Date = Date;
Formatted.Time = Time;
Formatted.DateTime = DateTime;
Formatted.Price = Price;
Formatted.Percent = Percent;
