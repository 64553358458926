import React, { Component, Fragment } from 'react';
import SuperActionsCell from './actions_cell';
import UserRoleLabel from '../users/role_label';
import Formatted from '../formatted';
import moment from 'moment';
import classnames from 'classnames';
import EllipsisWithTooltip from 'react-ellipsis-with-tooltip'

export const IdCell = props => {
  return <div className="text-right">{ props.id }</div>
};

export const DigitCell = props => {
  return <div className="text-right">{ props.value }</div>
};

export const DateCell = props => {
  const date = moment(props.date);

  if (!date.isValid()) return null;

  return (
    <div className="text-center">
      <Formatted.Date date={date} showWeekDay={false}/>
    </div>
  );
};

export const DateTimeCell = props => {
  let date = new Date(props.date);

  return (
    <div className="text-center">
      <Formatted.DateTime date={date}/>
    </div>
  );
};

export const PercentCell = props => {
  return (
    <div className="text-right">
      { props.value }%
    </div>
  );
};

export const PriceCell = props => {
  return (
    <RightText>
      <Formatted.Price price={props.value}/>
    </RightText>
  );
};

export const UserRoleCell = props => {
  return (
    <div className="text-center">
      <UserRoleLabel role={props.role}/>
    </div>
  );
};

const PositionText = props => (
  <div className={classnames([`text-${props.position}`, props.className])}>
    {props.children}
  </div>
);

export const CenterText = props => (
  <PositionText position='center' {...props}/>
);

export const LeftText = props => (
  <PositionText position='left' {...props}/>
);

export const RightText = props => (
  <PositionText position='right' {...props}/>
);

export const WithTooltip = props => (
  <EllipsisWithTooltip placement="top">
    {props.children}
  </EllipsisWithTooltip>
);

export class ActionsCell extends SuperActionsCell {}