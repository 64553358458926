import React, { Component } from 'react';
import axios from 'axios';
import PubSub from 'pubsub-js';
import ErrorBoundary from "../error_boundary";

export default class SmartBlock extends Component {
  constructor() {
    super();

    this.state = {
      data: [],
      messageData: {},
      included: undefined,
      created: false
    };
  }

  componentDidMount() {
    this.fetchData();
    PubSub.subscribe(this.props.name, this.receiveMessage);
  }

  receiveMessage = (message, _data) => {
    let name = message.split('.')[0];

    if (this.props.name !== name) return false;

    let action = message.split('.')[1];
    let updatedItemActions = ['Created', 'Updated', 'Deleted'];

    if (updatedItemActions.includes(action)) this.fetchData()
  };

  fetchData = () => {
    this.requestData().then(response => {
      const { data, included } = response.data;
      this.setState({ data, included, created: true });
    });
  };

  requestData() {
    const { path } = this.props;
    return new axios.get(`${path}.json`)
  }

  handleAction = (action, data) => {
    const { name } = this.props;
    PubSub.publish(`${name}.${action}`, data)
  };

  handleNew = () => this.handleAction('New', {});
  handleEdit = (id) => this.handleAction('Edit', { id: id });
  handleDelete = (id) => this.handleAction('Delete', { id: id });

  renderItemActions = (item) => {
    return (
      <div className="btn-group">
        <button className="btn btn-xs btn-primary" onClick={() => this.handleEdit(item.id)}>Edit</button>
        <button className="btn btn-xs btn-danger" onClick={() => this.handleDelete(item.id)}>Delete</button>
      </div>
    )
  };

  renderModal = () => null;
  renderContext = () => null;

  render() {
    return (
      <ErrorBoundary>
        {this.renderModal()}
        {this.renderContext()}
      </ErrorBoundary>
    )
  }
}

