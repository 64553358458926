import React, { Component } from 'react';
import FieldGroup from '../../../smart/field_group';
import Fetch from "../../fetch";
import classnames from 'classnames';
import moment from 'moment';

function storeItem(name, value) {
  return window.localStorage.setItem(`reports.${name}`, value)
}

function loadItem(name) {
  return window.localStorage.getItem(`reports.${name}`)
}

export default class Filter extends Component {
  constructor(props) {
    super(props);

    let storedStartDate = loadItem('start_date');
    let storedEndDate = loadItem('end_date');

    this.state = {
      ready: false,
      venues: undefined,
      time_period: 'this_month',
      start_date: storedStartDate ? moment(storedStartDate).toDate() : moment().subtract(1, 'months').toDate(),
      end_date: storedEndDate ? moment(storedEndDate).toDate() : moment().toDate()
    };

    this.handlers = {};
  }

  componentDidMount() {
    this.fetchVenues();
  }

  fetchVenues() {
    const { onReady } = this.props;

    Fetch
      .venues()
      .then(response => {
        const venues = response.data.data;
        const venue_id = (venues[0] || {}).id;

        this.setState({ ready: true, venues, venue_id }, () => {
          if (onReady) onReady();
        });
      });
  }

  handleChange = name => {
    if (!this.handlers[name]) {
      this.handlers[name] = event => {
        this.setState({ [name]: event.target.value }, () => {
          if (name === 'start_date') storeItem('start_date', event.target.value);
          if (name === 'end_date') storeItem('end_date', event.target.value);

          const { onChanged } = this.props;
          if (onChanged) onChanged();
        });
      };
    }

    return this.handlers[name];
  };

  render() {
    const {
      ready,
      venues,
      time_period,
      start_date,
      end_date
    } = this.state;

    if (!ready) return null;

    return (
      <div className='activity-report-filter'>
        <FieldGroup
          label="Venue:"
          type="select"
          name="filter[venue_id]"
          options={venues}
          onChange={this.handleChange('venue_id')}
        />

        <FieldGroup
          label="Period:"
          type="select"
          name="filter[time_period]"
          value={time_period}
          options={[
            { id: "today", title: "Today" },
            { id: "this_week", title: "This Week" },
            { id: "this_month", title: "This Month" },
            { id: "custom", title: "Custom" }
          ]}
          onChange={this.handleChange('time_period')}
        />

        <span className={classnames(['custom_dates', { 'hidden': time_period !== 'custom' }])}>
          <FieldGroup
            label='Start:'
            type="date"
            defaultValue={start_date}
            name="filter[start_date]"
            onRealChange={this.handleChange('start_date')}
          />
          <FieldGroup
            label='End:'
            type="date"
            defaultValue={end_date}
            name="filter[end_date]"
            onRealChange={this.handleChange('end_date')}
          />
        </span>
      </div>
    );
  }
}
