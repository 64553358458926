import React from 'react';
import SmartLabel from '../smart/label';

export default class StatusLabel extends SmartLabel {
  constructor() {
    super();

    this.items = {
      pending: { className: 'label-booking-status-pending', title: 'Pending' },
      accepted: { className: 'label-booking-status-accepted', title: 'Accepted' },
      cancelled: { className: 'label-booking-status-cancelled', title: 'Cancelled' },
      completed: { className: 'label-booking-status-completed', title: 'Completed' },
      modified: { className: 'label-booking-status-modified', title: 'Modified' },
      internal_booking: { className: 'label-booking-status-internal-booking', title: 'Internal booking' },
      obsoleted: { className: 'label-booking-status-obsoleted', title: 'Obsoleted' }
    }
  }
}