import React, { Component } from 'react';
import PubSub from 'pubsub-js';

export default class Index extends Component {
  handleNewUser(e) {
    e.preventDefault();
    PubSub.publish('Devise.SignUp', { modalTitle: 'Create account', role: 'user' })
  }

  handleNewVenue(e) {
    e.preventDefault();
    PubSub.publish('Devise.SignUp', { modalTitle: 'Create account', role: 'venue_admin' })
  }

  render() {
    return (
      <div id="main">
        <section id="top">
          <div className="container">
            <h1>See the benefits of the service</h1>
            <div id="search_line">
              <div className="choice_container">
                <a className="blue_link" href="#" onClick={this.handleNewVenue}>BECOME A PARTNER</a>
                <a className="green_link fr" href="#" onClick={this.handleNewUser}>NOT A VENUE</a>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
};
