import React, { Component } from 'react';
import BookingItemsTable from './booking_items_table';

export default class ActivityRow extends Component {
  render() {
    const { booking_items } = this.props;

    return <BookingItemsTable activity={this.props} booking_items={booking_items}/>;
  }
}
