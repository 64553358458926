import React, { Component, Fragment } from 'react';
import ReportShow from '../show';
import Filter from "./filter";
import Presenter from "./presenter";

export default class Show extends Component {
  render() {
    return (
      <ReportShow
        title='Activities report'
        path='/admin/reports/activities'
        filter={<Filter/>}
        presenter={<Presenter/>}
      />
    );
  }
}


