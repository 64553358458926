import React, { Component, Fragment } from 'react';
import Questions from './questions';
import axios from 'axios';
import { Tabs, Tab } from 'react-bootstrap';
import { ContinueButton } from '../step_buttons';
import StatusLabel from "./status_label";

export default class Index extends Component {
  constructor() {
    super();

    this.state = {
      data: undefined,
      attendeeIndex: 0
    };
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData(nextPage) {
    const { bookingId, onNext } = this.props;

    axios
      .get(`/bookings/${bookingId}/checkout/attendees.json`)
      .then((response) => {
        const { data, included } = response.data;

        this.props.onUpdatedTimer(included.timer)

        this.setState({ data }, () => {
          if (nextPage) {
            let pendingIndex = data.findIndex((involvedAttendee) => {
              return (involvedAttendee.status !== 'successful');
            });

            if (pendingIndex === -1) {
              onNext();
            } else {
              this.handleSelect(pendingIndex)
            }
          }
        });
      })
      .catch((error) => console.log(error));
  }

  getTabTitle = (involvedAttendee) => {
    return (
      <Fragment>
        {involvedAttendee.name} <StatusLabel value={involvedAttendee.status}/>
      </Fragment>
    );
  };

  handleSelect = (attendeeIndex) => this.setState({ attendeeIndex });
  handleBack = () => this.handleSelect(this.state.attendeeIndex - 1);
  handleNext = () => this.fetchData(true);

  render() {
    const { bookingId } = this.props;
    const { data, attendeeIndex } = this.state;

    if (data === undefined) return null;

    return (
      <Tabs id="uncontrolled-tabs" activeKey={attendeeIndex} onSelect={this.handleSelect}>
        {data.map((involvedAttendee, index) =>
          <Tab eventKey={index} title={this.getTabTitle(involvedAttendee)} key={index}>
            <div className='vert-offset-bottom-15'/>

            {attendeeIndex === index &&
            <Questions
              index={index}
              bookingId={bookingId}
              onNext={this.handleNext}
              onBack={this.handleBack}
              involvedAttendeeId={involvedAttendee.id}
              allowBack={index !== 0}
            />
            }
          </Tab>
        )}
      </Tabs>
    );
  }
}
