import React, { Component } from 'react';

const Document = (props) => {
  const {title, file} = props.data;

  return (
    <div className="vert-offset-bottom-10">
      <a className="terms_and_conditions_document_link" href={file.url}>{title}</a>
    </div>
  );
};

export default Document;