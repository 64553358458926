import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Can } from '../../../ability_context';
import PubSub from 'pubsub-js';

export default class ModalBookingItem extends Component {
  constructor(props) {
    super(props);

    this.state = { showInfo: false }
  }

  handleSendMessage = () => {
    const { user_id, venue_id } = this.props;

    PubSub.publish(
      'Message.New',
      {
        inbox: { type: 'Venue', id: venue_id },
        interlocutor: { type: 'User', id: user_id }
      }
    )
  };

  render() {
    const {
      booking_id,
      attendee_name,
      name,
      phone
    } = this.props;

    return (
      <tr>
        <td className="text-center">
          {attendee_name}
        </td>
        <td className="text-center">
          {name}
        </td>
        <td className="text-center">
          {phone}
        </td>
        <td className="text-center">
          <Can I='seeBookingInfo' a='AdminCalendars'>
            <a className="btn-action btn-more" target="_blank" href={`/admin/bookings/${booking_id}`}/>
          </Can>
          <a className="btn-action" onClick={this.handleSendMessage}>
            <i style={{ fontSize: '26px' }} className='fa fa-comment-o'/>
          </a>
        </td>
      </tr>
    )
  }
}