import React from 'react';
import SmartBlock from '../../smart/block';
import Modal from './modal';

export default class Block extends SmartBlock {
  renderTable() {
    const { data } = this.state;

    if (!(Array.isArray(data) && data.length > 0)) return null;

    return (
      <table className="table table-striped table-bordered table-xs">
        <thead>
        <tr>
          <th>Attendees</th>
          <th>Discount (%)</th>
          <th style={{width: 100}}></th>
        </tr>
        </thead>
        <tbody>
        {data.map((item) =>
          <tr key={item.id}>
            <td>{item.attendees}</td>
            <td className="text-right">{item.percent}</td>
            <td>
              <div className="btn-group">
                <a className="btn btn-xs btn-primary" onClick={(e) => this.handleEdit(item.id)}>Edit</a>
                <a className="btn btn-xs btn-danger" onClick={(e) => this.handleDelete(item.id)}>Delete</a>
              </div>
            </td>
          </tr>
        )}
        </tbody>
      </table>
    );
  }

  render() {
    return (
      <div>
        <Modal {...this.props}/>

        <div className="panel panel-default panel-block">
          <div className="panel-heading">
            <h3 className="pull-left">Multiple Attendee Discounts</h3>
            <div className="btn-group pull-right">
              <a className="btn btn-primary" onClick={this.handleNew}>Add multiple attendee discount</a>
            </div>
            <div className="clearfix"/>
          </div>
          <div className="panel-body panel-body-with-table">
            <div className="table-box">
              {this.renderTable()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

