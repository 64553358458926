import React from 'react';
import SmartLabel from '../../smart/label';

export default class InternalNoteStatusLabel extends SmartLabel {
  constructor() {
    super();

    this.items = {
      true: { title: 'Link to internal notes', className: 'label-question-link-to-internal-notes' },
    }
  }
}
