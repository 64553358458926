import React, { Component } from 'react';

export default class UploadImage extends Component {
  render() {
    const { progress } = this.props;
    return (
      <div className="collection new_image">
        <div className="collection-overlay">
          <div className="collection-overlay-wrapper text-center" style={{ paddingTop: 95 }}>
            <label className="btn btn-primary btn-lg" title="Add image">
              {progress} %
            </label>
          </div>
        </div>
      </div>
    )
  }
}
