import React, { Component } from 'react';
import { CenterText, RightText } from '../smart/cells';
import Formatted from '../formatted';

export default class Table extends Component {
  render() {
    const { items, included } = this.props;

    if (items === null) return null;

    return (
      <div>
        <table className="table table-bordered-2">
          <thead>
          <tr>
            <th style={{width: 10}}></th>
            <th>Payment date</th>
            <th>Start date</th>
            <th>End date</th>
            <th>Amount, £</th>
          </tr>
          </thead>
          <tbody>
          {items.map((item) =>
            <tr key={item.id}>
              <td></td>
              <td><CenterText><Formatted.Date date={item.start_payment_date}/></CenterText></td>
              <td><CenterText><Formatted.Date date={item.start_date}/></CenterText></td>
              <td><CenterText><Formatted.Date date={item.end_date}/></CenterText></td>
              <td><RightText><Formatted.Price price={item.amount}/></RightText></td>
            </tr>
          )}
          </tbody>
          {included &&
          <tfoot>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><CenterText>Total:</CenterText></td>
            <td><RightText><Formatted.Price price={included.total}/></RightText></td>
          </tr>
          </tfoot>
          }
        </table>
      </div>
    );
  }
}
