import React, { Component, Fragment } from 'react';
import Page from '../page';
import Table from "./table";
import PubSub from 'pubsub-js';

export default class Index extends Component {
  componentDidMount() {
    PubSub.subscribe('Booking.Updated', this.updatedBooking)
  }

  updatedBooking = (k, data) => {
    data = data || {};

    if (data.method === 'amend') {
      window.location = `/admin/bookings/${data.id}`
    }
  };

  render() {
    return (
      <Fragment>
        <Page.Header>
          Bookings
        </Page.Header>

        <Table name="Booking" path="/admin/bookings" title="Booking"/>
      </Fragment>
    );
  }
}
