import React, { Fragment } from 'react';
import SmartModal from '../../smart/modal';
import Generator from './generator';
import { Button, Alert } from 'react-bootstrap';
import axios from 'axios';

export default class Modal extends SmartModal {
  renderForm() {
    const { data, included } = this.state;
    const groupUid = included.group_uid;
    const { path } = this.props;
    const bookingOptionId = data.bookingOptionId;
    const bookingOptionItemId = data.id;

    const scheduleItemsErrors = this.formError('schedule_items');

    return (
      <Fragment>
        <Generator
          bookingOptionId={bookingOptionId}
          bookingOptionItemId={bookingOptionItemId}
          groupUid={groupUid}
          activity={this.includedData('activity')}
          path={path}
        />

        {scheduleItemsErrors &&
        <Alert bsStyle="danger">
          <p>
            {scheduleItemsErrors.join(', ')}
          </p>
        </Alert>
        }
      </Fragment>
    );
  }

  save = () => {
    const { path } = this.props;
    const { data, included } = this.state;
    const groupUid = included.group_uid;

    let formData = new FormData();
    formData.append('group_uid', groupUid);
    formData.append('booking_option_id', (data || {}).bookingOptionId);
    formData.append('booking_option_item_id', (data || {}).id);

    axios
      .post(`${path}.json`, formData)
      .then((response) => {
        PubSub.publish('BookingOption.Updated');
        this.handleClose();
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  };

  renderButtons() {
    const { action } = this.state;
    const newOrEdit = ['New', 'Edit'].includes(action);

    return (
      <div>
        <Button className='btn btn-default' onClick={this.handleClose}>Cancel</Button>
        {newOrEdit && <Button bsStyle='primary' onClick={this.save}>Confirm</Button>}
        {!newOrEdit && <Button bsStyle='primary' onClick={this.handlePerform}>Confirm</Button>}
      </div>
    )
  }

  renderBody() {
    const { loading, action } = this.state;
    if (loading) return <div className="text-center">Loading....</div>;
    let fn = this[`render${action}Form`];
    return (typeof fn === 'function') && fn();
  }

  getModalClassName = (action) => {
    if (['New', 'Edit'].includes(action)) return 'schedule-modal';
    return null;
  };

  applyEnable = () => {
    this.applyDefault('enable');
  };

  applyDisable = () => {
    this.applyDefault('disable');
  }
}
