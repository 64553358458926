import React, { Component } from 'react';
import axios from 'axios';
import Document from './document';
import {BackButton, ContinueButton} from "../step_buttons";

export default class Index extends  Component {
  constructor(props) {
    super(props);

    this.state = { data: undefined, formErrors: {} };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    const { bookingId } = this.props;

    axios
      .get(`bookings/${bookingId}/checkout/terms_and_conditions.json`)
      .then((response) => {
        const { data, included } = response.data;
        this.props.onUpdatedTimer(included.timer)
        this.setState({ data, included });
      })
      .catch((error) => console.log(error));
  };

  perform = () => {
    const { bookingId, onNext } = this.props;
    const data = new FormData(this.formRef.current);

    axios
      .post(`/bookings/${bookingId}/checkout/terms_and_conditions.json`, data)
      .then((response) => onNext())
      .catch((error) => this.setState({ formErrors: error.response.data.errors }));
  };

  render() {
    const { data, included, formErrors } = this.state;
    const { onBack } = this.props;

    if (data === undefined) return null;

    const ducuments = included.documents.data;

    return (
      <form ref={this.formRef}>
        <div className="summery-text">
          <div className="form-group string optional terms_and_conditions_confirmed">
            <div style={{float: 'left'}}>
              <input defaultValue={0} type="hidden" name="checkout[confirmed_documents]"/>
              <label>
                <input
                  className="boolean optional"
                  type="checkbox"
                  defaultChecked={data.confirmed_documents}
                  defaultValue={1}
                  name="checkout[confirmed_documents]"
                />
              </label>
            </div>
            <div style={{marginLeft: 30}}>
              To proceed with this booking request, please tick the box to confirm that you have read
              and agree to all the terms and conditions within the document(s) below:

              <div className="vert-offset-bottom-15" />

              {ducuments.map((item) => <Document key={item.id} data={item}/>)}

              <div className="has-error">
                <span className="help-block">
                  { formErrors['confirmed'] }
                </span>
              </div>
            </div>
          </div>
          <div className="vert-offset-bottom-20 vert-offset-top-20">
            <div className="btn-toolbar pull-right">
              <BackButton onClick={onBack}/>
              <ContinueButton onClick={this.perform}/>
            </div>
          </div>
        </div>
      </form>
    );
  }
}
