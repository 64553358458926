import React from 'react';
import SmartModal from '../../smart/modal';

export default class Modal extends SmartModal {
  renderForm() {
    return (
      <div>
        <SmartModal.FieldGroup
          type="text"
          label="Title"
          name="invoice_category[title]"
          defaultValue={this.formValue("title")}
          error={this.formError("title")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Company name"
          name="invoice_category[company_name]"
          defaultValue={this.formValue("company_name")}
          error={this.formError("company_name")}
        />
        <SmartModal.FieldGroup
          type="file"
          label="Invoice logo"
          name="invoice_category[invoice_logo]"
          error={this.formError("invoice_logo")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Invoice address line 1"
          name="invoice_category[invoice_address_line_1]"
          defaultValue={this.formValue("invoice_address_line_1")}
          error={this.formError("invoice_address_line_1")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Invoice city"
          name="invoice_category[invoice_city]"
          defaultValue={this.formValue("invoice_city")}
          error={this.formError("invoice_city")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Invoice postcode"
          name="invoice_category[invoice_postcode]"
          defaultValue={this.formValue("invoice_postcode")}
          error={this.formError("invoice_postcode")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Registered office"
          name="invoice_category[invoice_local_authority]"
          defaultValue={this.formValue("invoice_local_authority")}
          error={this.formError("invoice_local_authority")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Company registration number"
          name="invoice_category[company_registration_number]"
          defaultValue={this.formValue("company_registration_number")}
          error={this.formError("company_registration_number")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Charity number"
          name="invoice_category[charity_number]"
          defaultValue={this.formValue("charity_number")}
          error={this.formError("charity_number")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="VAT registration Number"
          name="invoice_category[vat_registration_number]"
          defaultValue={this.formValue("vat_registration_number")}
          error={this.formError("vat_registration_number")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Invoice phone number"
          name="invoice_category[invoice_phone_number]"
          defaultValue={this.formValue("invoice_phone_number")}
          error={this.formError("invoice_phone_number")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Billing email"
          name="invoice_category[billing_email]"
          defaultValue={this.formValue("billing_email")}
          error={this.formError("billing_email")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Bank account name"
          name="invoice_category[bank_account_name]"
          defaultValue={this.formValue("bank_account_name")}
          error={this.formError("bank_account_name")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Bank account sortcode"
          name="invoice_category[bank_account_sortcode]"
          defaultValue={this.formValue("bank_account_sortcode")}
          error={this.formError("bank_account_sortcode")}
        />
        <SmartModal.FieldGroup
          type="text"
          label="Bank account number"
          name="invoice_category[bank_account_number]"
          defaultValue={this.formValue("bank_account_number")}
          error={this.formError("bank_account_number")}
        />
      </div>
    );
  }
}
