import React from 'react';
import Modal from './modal';
import SmartBlock from '../../smart/block';
import Row from './row';
import { Button } from 'react-bootstrap';

export default class Block extends SmartBlock {
  handleDefault(id) {
    this.handleAction('Default', { id: id });
  }

  renderTable() {
    let { data } = this.state;

    if (!(Array.isArray(data) && data.length > 0)) return null;

    return (
      <table className="table table-bordered">
        <tbody>
        {data.map((item) =>
          <Row
            key={item.id}
            onDefault={this.handleDefault}
            onEdit={this.handleEdit}
            {...item}
          />
        )}
        </tbody>
      </table>
    )
  }

  renderHeaderConfiguration() {
    return null;

    return (
      <div className="price-radio-button pull-left">
        <div className="form-group">
            <span className="radio">
              <label>
                <input
                  className="radio_buttons"
                  type="radio"
                  name="area[single_invoice_category]"
                  value="true"
                />
                Single invoice category
              </label>
            </span>
          <span className="radio">
              <label>
                <input
                  className="radio_buttons"
                  type="radio"
                  name="area[single_invoice_category]"
                  defaultChecked={true}
                  value="false"
                />
                Multiple invoice categories
              </label>
            </span>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <Modal {...this.props}/>

        <div className="panel panel-default panel-block">
          <div className="panel-heading">
            <h3 className="pull-left">Invoice Categories</h3>

            {/*{this.renderHeaderConfiguration()}*/}
            <div className="btn-group pull-right">
              {/*<Button bsStyle="primary" onClick={this.handleNew}>*/}
                {/*Add invoice category*/}
              {/*</Button>*/}
            </div>
            <div className="clearfix"/>
          </div>
          <div className="panel-body panel-body-with-table">
            <div className="table-box">
              {this.renderTable()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

