import React, { Component } from 'react';
import SmartLabel from '../smart/label';
import classnames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export default class StatusLabel extends SmartLabel {
  constructor() {
    super();

    this.items = {
      in_progress: { title: 'Scheduled', className: 'involved-schedule-item-status-in-progress' },
      started: { title: 'Started', className: 'involved-schedule-item-status-started' },
      completed: { title: 'Completed', className: 'involved-schedule-item-status-completed' },
      cancelled: { title: 'Cancelled', className: 'involved-schedule-item-status-cancelled' }
    };
  }

  renderLabel(selectedItem) {
    const spanClasses = classnames([selectedItem.className, 'fa fa-circle']);
    const id = `isi-${selectedItem.id}`;

    return (
      <OverlayTrigger
        overlay={<Tooltip id={id}>{selectedItem.title}</Tooltip>}
        placement="top"
      >
        <i className={spanClasses}/>
      </OverlayTrigger>
    );
  }
}