import React, { useRef, useState, useEffect } from 'react';
import FormToObject from 'form-to-object';
import Search from "./Filter/Search";
import Venues from "./Filter/Venues";
import Statuses from "./Filter/Statuses";
import TimePeriods from "./Filter/TimePeriods";
import Modifications from "./Filter/Modifications";
import Categories from "./Filter/Categories";

export default ({ onChangeSearch }) => {
  const formRef = useRef();
  const [readyFilters, setReadyFilters] = useState([]);

  const handleUpdateFilter = () => {
    if (!onChangeSearch) return null;
    if (readyFilters.length !== 2) return null;

    onChangeSearch(FormToObject(formRef.current));
  }

  useEffect(() => { handleUpdateFilter();}, [readyFilters])

  const handleReady = (name) => {
    let newReadyFilters = [...readyFilters];
    newReadyFilters.push(name);
    setReadyFilters(newReadyFilters)
  }

  return (
    <div>
      <form ref={formRef}>
        <div>
          <Search onChange={handleUpdateFilter}/>
          <Venues onReady={() => handleReady('venues')} onChange={handleUpdateFilter} />
          <Categories onReady={() => handleReady('categories')} onChange={handleUpdateFilter}/>
          <Statuses onChange={handleUpdateFilter}/>
          <TimePeriods onChange={handleUpdateFilter}/>
          <Modifications onChange={handleUpdateFilter}/>
        </div>
      </form>
    </div>
  )
}
