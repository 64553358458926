import React, { useState, useMemo, useRef, useEffect } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { elementStyles } from "./elementStyles";
import './styles.scss';

export default ({ elements, onSubmit, buttonTitle }) => {
  const nameInput = useRef();
  const zipInput = useRef();
  const [savedErrors, setSavedErrors] = useState([]);

  const elementClasses = { focus: 'focus', empty: 'empty', invalid: 'invalid' };
  const elementOptions = { style: elementStyles, classes: elementClasses };

  function handleSubmit(e) {
    e.preventDefault();

    const cardNumber = elements.getElement(CardNumberElement);

    const name = nameInput.current.value;
    const zip = zipInput.current.value;

    const cardOptions = {
      payment_method: {
        card: cardNumber,
        billing_details: {
          name: name ? name.value : undefined,
          //address_zip: zip ? zip.value : undefined,
        }
      }
    }

    onSubmit(cardOptions)
  }

  function handleChangeCardElement(event, idx) {
    const newErrors = {...savedErrors};
    newErrors[idx] = event.error ? event.error.message : null;
    setSavedErrors(newErrors);
  }

  const nextError = useMemo(() => {
    return Object
      .keys(savedErrors)
      .sort()
      .reduce((maybeFoundError, key) => maybeFoundError || savedErrors[key], null);
  }, [savedErrors]);

  return (
    <div className='stripe-form'>
      <form onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col-md-12'>
            <div className='input-box'>
              <input placeholder='Name on card' className='custom-input' ref={nameInput}/>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12'>
            <div className='input-box'>
              <CardNumberElement options={elementOptions} onChange={(e) => handleChangeCardElement(e, 'number')}/>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-4'>
            <div className='input-box'>
              <CardExpiryElement options={elementOptions} onChange={(e) => handleChangeCardElement(e, 'exp')}/>
            </div>
          </div>

          <div className='col-md-4'>
            <div className='input-box'>
              <CardCvcElement options={elementOptions} onChange={(e) => handleChangeCardElement(e, 'cvc')}/>
            </div>
          </div>

          <div className='col-md-4'>
            <div className='input-box'>
              <input placeholder='Postcode' className='custom-input' ref={zipInput}/>
            </div>
          </div>
        </div>

        <div className="form-group has-error has-feedback text-center">
          <span className="help-block">{nextError}</span>
        </div>

        <button type="submit" className='btn btn-primary btn-lg btn-no-radius pay-button' style={{ width: '100%' }}>
          {buttonTitle}
        </button>
      </form>
      <br/>
    </div>
  )
};