import React, { Component } from 'react';
import FieldGroup from "../../smart/field_group";

export default class Question extends Component {
  constructor(props) {
    super(props);
  }

  formError(name) {
    const { errors } = this.props;
    if (errors === undefined) return null;
    return (name in errors) ? errors[name] : null;
  }

  render() {
    const { data, index } = this.props;
    const {
      id,
      text,
      allow_comment,
      allow_options,
      allow_yes_no_options,
      comment_required
    } = data;

    return (
      <div className="vert-offset-bottom-25 question">
        <label className="question_text">
          {text}
        </label>

        {comment_required && <abbr title="required">*</abbr>}

        <input
          name="involved_attendee[p]"
          value="1"
          type="hidden"
        />

        <input
          name={`involved_attendee[involved_attendee_questions_attributes][${index}][id]`}
          value={id}
          type="hidden"
        />

        <input
          name={`involved_attendee[involved_attendee_questions_attributes][${index}][nested]`}
          value={id}
          type="hidden"
        />

        {allow_options &&
          <FieldGroup
            name={`involved_attendee[involved_attendee_questions_attributes][${index}][option]`}
            className="venue_questions_answers_option"
            type="radio-group"
            defaultValue={data.option}
            options={
              [
                { id: 'yes', title: 'Yes' },
                { id: 'no', title: 'No' },
                { id: 'not_applicable', title: 'Not applicable' }
              ]
            }
            error={this.formError(`involved_attendee_questions[${index}].option`)}
          />
        }

        {allow_yes_no_options && !allow_options &&
          <FieldGroup
            name={`involved_attendee[involved_attendee_questions_attributes][${index}][option]`}
            className="venue_questions_answers_option"
            type="radio-group"
            defaultValue={data.option}
            options={
              [
                { id: 'yes', title: 'Yes' },
                { id: 'no', title: 'No' }
              ]
            }
            error={this.formError(`involved_attendee_questions[${index}].option`)}
          />
        }

        {allow_comment &&
          <FieldGroup
            name={`involved_attendee[involved_attendee_questions_attributes][${index}][comment]`}
            type="textarea"
            defaultValue={data.comment}
            error={this.formError(`involved_attendee_questions[${index}].comment`)}
          />
        }
      </div>
    )
  }
}