import React, { Component } from 'react';
import Block from "./block";
import Page from '../page';

export default class Index extends Component {
  render() {
    return (
      <div>
        <Page.Header leftSide={<Page.NewButton title="Add region" name="Region"/>}>
          Regions
        </Page.Header>

        <Block
          title="Region"
          path="/admin/regions"
          name="Region"
        />
      </div>
    );
  }
}
