import React from "react";
import Formatted from "../../formatted";
import { Button, ButtonGroup } from "react-bootstrap";

const ActionButtons = ({ can_be_deleted, disabled, handleEdit, handleDelete, handleEnable, handleDisable }) => {
  let buttons = [];

  buttons.push(<Button bsStyle='primary' onClick={handleEdit}>Edit</Button>);

  if (can_be_deleted) {
    buttons.push(<Button bsStyle='danger' onClick={handleDelete}>Delete</Button>);
  } else {
    if (disabled) {
      buttons.push(<Button className="btn btn-green" onClick={handleEnable}>Enable</Button>);
    } else {
      buttons.push(<Button bsStyle='warning' onClick={handleDisable}>Disable</Button>);
    }
  }

  return buttons;
}

export default (item) => {
  let priceBoxValue = null;

  if (item.pricing_calculation_type === 'percent') {
    priceBoxValue = <Formatted.Percent percent={item.price}/>
  } else {
    priceBoxValue = <Formatted.Price price={item.price}/>
  }

  return (
    <tr key={item.id}>
      <td>{item.title}</td>
      <td className="text-center">{item.pricing_calculation_type}</td>
      <td className="text-right">{priceBoxValue}</td>
      <td className="text-center">{item.apply_discounts ? 'Yes' : 'No'}</td>
      <td>
        <ButtonGroup bsSize='xs'>
          <ActionButtons
            can_be_deleted={item.can_be_deleted}
            disabled={item.disabled}
            handleEdit={() => item.onEdit(item.id)}
            handleDelete={() => item.onDelete(item.id)}
            handleEnable={() => item.onEnable(item.id)}
            handleDisable={() => item.onDisable(item.id)}
          />
        </ButtonGroup>
      </td>
    </tr>
  )
}