import React, { Fragment } from 'react';
import Modal from './modal';
import AreasInfo from '../areas/info';
import { Button } from 'react-bootstrap';
import PubSub from 'pubsub-js';

export default class Info extends AreasInfo {
  onEditClick = () => {
    const { data } = this.state;

    PubSub.publish(
      'VenueActivityCategory.Edit',
      {
        modalTitle: 'Payment options',
        id: data.id
      }
    );
  };

  render() {
    const { data, included } = this.state;
    if (data === undefined) return null;

    const {
      split_payment_option_enabled,
      preorder_first_part_enabled,
      offline_payment_type
    } = data;

    const {
      split_payment_option,
    } = (split_payment_option_enabled ? data : included.venue.data);

    const {
      preorder_first_part_percent,
      preorder_second_part_due_days
    } = (preorder_first_part_enabled ? data : included.venue.data);


    const offlineOptions = {
      'per_customer': 'offline payment option set per customer',
      'always_available': 'offline payment option always available'
    };

    let selectedOfflineOption = offlineOptions[offline_payment_type];

    return(
      <div>
        <Modal title='Category' name={this.props.name} path="/admin/venue_activity_categories"/>

        <div className="row">
          <div className="col-xs-12">
            <div className="pull-right">
              <div className="btn-group btn-group-xs">
                <Button bsStyle="primary" onClick={this.onEditClick}>Edit</Button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-5 col-xs-12">
            <h4>Split payment</h4>

            <dl className="dl-horizontal dl-payment-options">
              <dt>Split payment schedule:</dt>
              <dd>{split_payment_option.title}</dd>
            </dl>

            <h4>Deposit</h4>

            <dl className="dl-horizontal dl-payment-options">
              <dt>Deposit (%) payable on booking:</dt>
              <dd>{preorder_first_part_percent}%</dd>
              <dt>Balance due x days before event:</dt>
              <dd>{preorder_second_part_due_days}</dd>
            </dl>

            <h4>Offline payment</h4>
            <span>{selectedOfflineOption}</span>
          </div>
        </div>
      </div>
    )  
  }
}