import React  from 'react';
import FieldGroup from "../../smart/field_group";
import TableFilter from '../../smart/table_filter'
import axios from 'axios';
import Fetch from "../fetch";

export default class Filter extends TableFilter {
  componentDidMount() {
    this.fetchVenues()
  }

  fetchVenues() {
    Fetch
      .venues()
      .then(response => this.setState({ venues: response.data.data, }, () => { this.handleUpdateFilter() }));
  }

  getClassName() {
    return '';
  }

  renderFields() {
    const { venues } = this.state;

    let emptyOption = { title: 'All' };
    let options = venues;

    if (venues && venues.length === 1) emptyOption = undefined;

    return (
      <div>
        <FieldGroup
          label="Search"
          type="text-debounce"
          name="terms"
          onChange={this.handleUpdateFilter}
        />

        <FieldGroup
          type="select"
          name="venue_id"
          label="Venue"
          emptyOption={emptyOption}
          options={options}
          onChange={this.handleUpdateFilter}
        />
      </div>
    );
  }
}

