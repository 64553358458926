import React from 'react';

const InfoPages = props => {
  return (
    <dl>
      <dt className="footer-block">Company</dt>
      <dd>
        <ul className="footer-block">
          <li><a href="/about_us">About us</a></li>
          <li><a href="/how_it_works">How it works</a></li>
          <li><a href="/contact_and_support">Contact &amp; Support</a></li>
        </ul>
      </dd>
    </dl>
  )
};

export default InfoPages;