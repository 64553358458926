import React, { Component } from 'react';
import classnames from 'classnames';

export default class Label extends Component {
  constructor() {
    super();
    this.items = {};
  };

  renderLabel(selectedItem) {
    const spanClasses = classnames(['label', selectedItem.className]);
    return <span className={spanClasses}>{selectedItem.title}</span>;
  }

  render() {
    const { value } = this.props;

    let selectedItem = this.items[value];
    if (selectedItem === undefined) return null;
    if (selectedItem.hidden === true) return null;
    return this.renderLabel(selectedItem);
  }
}