import React, { Component } from 'react';
import FieldGroup from "../../smart/field_group";

export default class HeaderConfig extends Component {
  handleChangeOption = (e) => {
    const { onChange } = this.props;
    if (onChange) onChange(e.target.value)
  };

  render() {
    const { value } = this.props;

    return (
      <div className="price-radio-button">
        <FieldGroup
          type="radio-group"
          defaultValue={value}
          name='booking_option_status'
          options={[
            { id: 'active', title: 'Active booking options' },
            { id: 'app', title: 'All booking options' },
          ]}
          onChange={this.handleChangeOption}
        />
      </div>
    )
  }
}

