import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

export default class ExportButtons extends Component {
  constructor() {
    super();

    this.handlers = {};
  }

  handleExport = format => {
    if (!this.handlers[format]) {
      this.handlers[format] = () => {
        const { onClick } = this.props;
        if (onClick) onClick(format)
      };
    }

    return this.handlers[format];
  };

  render() {

    return (
      <div>
        <Button bsStyle='primary' onClick={this.handleExport('xlsx')}>Export to Excel</Button>
        &nbsp;
        <Button bsStyle='primary' onClick={this.handleExport('pdf')}>Export to PDF</Button>
      </div>
    )
  }
}