import React from 'react';
import SmartModal from '../../smart/modal';
import FieldGroup from '../../smart/field_group';

export default class Modal extends SmartModal {
  updatePayPerDay = (e) => {
    const formData = this.state;
    formData.pay_per_day = e.target.checked;
    this.setState({ formData })
  };

  renderForm() {
    const payPerDay = this.formValue("pay_per_day");

    let priceLabel = payPerDay ? 'Price per day' : 'Price for all sessions';
    let disableChangePayParDay = this.formValue("booking_option_items_number") > 0;

    return (
      <div>
        <FieldGroup
          type="text"
          label="Title"
          name="booking_option[title]"
          defaultValue={this.formValue("title")}
          error={this.formError("title")}
        />

        <FieldGroup
          type="text"
          label={priceLabel}
          name="booking_option[price]"
          defaultValue={this.formValue("price")}
          error={this.formError("price")}
        />

        <FieldGroup
          type="checkbox"
          label="Pay as you go"
          disabled={disableChangePayParDay}
          name="booking_option[pay_per_day]"
          defaultValue={this.formValue("pay_per_day")}
          error={this.formError("pay_per_day")}
          onChange={this.updatePayPerDay}
        />
      </div>
    );
  }

  applyEnable = () => {
    this.applyDefault('enable');
  };

  applyDisable = () => {
    this.applyDefault('disable');
  }
}
