import React, { Component } from 'react';
import axios from 'axios';
import Bill from './bill';
import Payments from './payments';
import Documents from './documents';
import Questions from './questions';
import Attachments from './attachments';
import UserInformation from "./user_information";
import InvolvedAttendees from "./involved_attendees";
import StatusLabel from './status_label';
import OfflineLabel from './offline_label';
import PubSub from 'pubsub-js';

export default class Show extends Component {
  constructor(props) {
    super(props);

    this.state = { data: null };
  }

  componentDidMount() {
    this.fetchData();
    PubSub.subscribe('Booking.Updated', this.fetchData);
  }

  fetchData = () => {
    const { url } = this.props;

    axios
      .get(url)
      .then((response) => {
        const response_data = response.data;
        this.setState({ data: response_data.data });
      })
      .catch((error) => {
        console.log(error)
      });
  };

  renderStatus = () => {
    const { data } = this.state;
    return (
      <div className="booking-status-header">
        <StatusLabel value={data.status}/>
        <OfflineLabel value={data.offline}/>
      </div>
    );
  };

  renderHeaderInfo() {
    return this.props.headerInfo;
  }

  renderActions(row) {
    return this.props.renderActions(row);
  }

  render() {
    const { renderActions, onlyViewAttachments } = this.props;
    const { data } = this.state;

    if (data === null) return null;

    return (
      <div className="bookings-wrap white-background">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {this.renderStatus()}

              <h4 className='text-strong'>
                Booking {data.id}
              </h4>

              {this.renderHeaderInfo()}
              {renderActions(data)}

              <Bill data={data.bill.data}/>

              {data.child_bill &&
              <div>
                <h3>Amend booking</h3>
                <Bill
                  data={data.child_bill.data}
                  status={data.child_bill.data.status}
                  onUpdate={this.fetchData}
                  onRemoveBookingItem={() => console.log('remove')}
                />
              </div>
              }

              <Payments data={data.payments.data}/>
              <UserInformation data={(data.user || {}).data}/>
              <InvolvedAttendees data={(data.involved_attendees || {}).data}/>
              <Documents data={data.documents.data}/>
              <Questions data={(data.questions || {}).data}/>

              <Attachments
                onlyView={onlyViewAttachments}
                bookingId={PageData.booking_id}
                data={(data.attachments || {}).data}
              />

              <div className="vert-offset-bottom-25"/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}