import React, { Component } from 'react';
import Block from "./block";
import Page from '../page';

export default class Index extends Component {
  render() {
    return (
      <div>
        <Page.Header leftSide={<Page.NewButton title="Add local authority" name="LocalAuthority"/>}>
          Local authorities
        </Page.Header>

        <Block
          title="Local Authority"
          path="/admin/local_authorities"
          name="LocalAuthority"
        />
      </div>
    );
  }
}
