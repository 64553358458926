import React, { Component } from 'react';
import Select from 'react-select';

export default class ActivityCategories extends Component {
  handleChange = (selectedOption) => {
    const { onChange } = this.props;
    onChange(selectedOption);
  };

  render() {
    const { activityCategories, activityCategory, isDisabled } = this.props;

    return (
      <div className="section">
        <h4>Choose activity category</h4>

        <Select
          name="form-field-name"
          value={activityCategory}
          placeholder='All'
          onChange={this.handleChange}
          isDisabled={isDisabled}
          getOptionLabel={(option) => option.title }
          getOptionValue={(option) => option.id }
          options={activityCategories}
        />
      </div>
    );
  }
}