import React, { Component} from 'react';
import BookingOption from './booking_option';

export default ({ bookingOptions, onChange, selectedBookingOptionId }) => {
  if (!(Array.isArray(bookingOptions) && bookingOptions.length > 0)) return null;

  return (
    <div className="section" id='booking-options-section'>
      <h4>Choose booking option</h4>
      <div>
        {bookingOptions.map(bookingOption =>
          <BookingOption
            key={bookingOption.id}
            {...bookingOption}
            onChange={onChange}
            checked={selectedBookingOptionId === bookingOption.id}
            bookingOption={bookingOption}
          />
        )}
      </div>
    </div>
  );
}
