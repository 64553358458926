import React, { Component, Fragment } from 'react';
import axios from 'axios';
import GeneratorForm from './generator_form';
import ScheduleItemsList from '../schedule_items/list';
import PubSub from 'pubsub-js';

export default class Generator extends Component {
  componentDidMount() {
    this.fetchScheduleItems();
    PubSub.subscribe('ScheduleItem.Updated', this.fetchScheduleItems)
  }

  fetchScheduleItems = () => {
    const { bookingOptionId, bookingOptionItemId, groupUid } = this.props;

    let params = {
      group_uid: groupUid,
      booking_option_id: bookingOptionId,
      booking_option_item_id: bookingOptionItemId
    };

    axios
      .get(`${window.location.pathname}/schedule_items.json`, { params: params })
      .then((response) => this.setState({ data: response.data.data }))
      .catch((response) => console.log('error', response));
  };

  render() {
    const { path, bookingOptionId, groupUid, activity } = this.props;
    const { data } = this.state || {};

    return (
      <Fragment>
        <GeneratorForm
          path={path}
          groupUid={groupUid}
          bookingOptionId={bookingOptionId}
          activity={activity}
          onChangeScheduleItems={this.fetchScheduleItems}
        />

        <ScheduleItemsList
          data={data}
          groupUid={groupUid}
          bookingOptionId={bookingOptionId}
          path={`${window.location.pathname}/schedule_items`}
          onChanged={this.fetchScheduleItems}
        />
      </Fragment>
    );
  }
}
