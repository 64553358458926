import React from 'react';
import SmartLabel from '../smart/label';

export default class ModificationLabel extends SmartLabel {
  constructor() {
    super();

    this.items = {
      pending: { className: 'label-modification-pending', title: 'Pending' },
      pending_payment: { className: 'label-modification-pending-payment', title: 'Payment' },
      failed_payment: { className: 'label-modification-failed-payment', title: 'Failed' },
    }
  }
}