import React, { useEffect, useState } from 'react';
import Spinner from "./Spinner";
import axios from "axios";

import './styles.scss';
import Cookies from "js-cookie";

export default ({ spinnerMessage, paymentPath, setShow }) => {
  const [processing, setProcessing] = useState(true);
  const [paymentOptions, setPaymentOptions] = useState(null);

  useEffect(() => {
    axios.get(paymentPath).then(result => {
      const { paymentOptions, error } = result.data;

      if (error !== undefined) {
        if (error.redirectTo) {
          Cookies.set('FullyBookedMessage', error.message)
          window.location.href = error.redirectTo;
        }
        return;
      }

      setPaymentOptions(paymentOptions);
      setProcessing(true);
    });
  }, []);

  return (
    <div style={{ height: 200 }}>
      <Spinner
        show={processing}
        setShow={setShow}
        paymentOptions={paymentOptions}
        message={spinnerMessage}
        setProcessing={setProcessing}
        listenWS={true}
        setListenWS={()=>{}}
      />
    </div>
  );
};