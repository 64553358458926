import React, { Component } from 'react';
import Formatted from "../../formatted";

export default class ActivityPeriod extends Component {
  render() {
    const { eventsPeriod } = this.props;

    if (!eventsPeriod) return null;

    return (
      <div className="label label-success" style={{ display: 'block' }}>
        <Formatted.Date value={eventsPeriod[0]} showWeekDay={false}/>
        &nbsp;-&nbsp;
        <Formatted.Date value={eventsPeriod[1]} showWeekDay={false}/>
      </div>
    )
  }
}