import React, { useState } from 'react';
import { Button } from "react-bootstrap";
import Modal from '../../../PaymentSystem/Modal';

export default ({ paymentPath }) =>  {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Modal
        show={showModal}
        setShow={setShowModal}
        paymentPath={paymentPath}
        spinnerMessage="Please wait while we process your booking"
        offline={true}
      />

      <Button bsStyle='primary' bsSize='large' className="btn-no-radius" onClick={() => setShowModal(true)}>
        Book now
      </Button>
    </>
  )
}
