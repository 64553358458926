import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import FieldGroup from './field_group';
import axios from 'axios';
import PubSub from 'pubsub-js';
import classnames from 'classnames';
import ErrorBoundary from "../error_boundary";

export default class SmartModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalStyle: undefined,
      show: false,
      data: {},
      included: {},
      formData: {},
      formErrors: {},
      loading: true
    };

    this.formRef = React.createRef();
  }

  componentDidMount() {
    PubSub.subscribe(this.props.name, this.receivedMessage)
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.props.name)
  }

  receivedMessage = (message, data) => {
    const name = message.split(".")[0];

    if (this.props.name !== name) return false;

    const action = message.split(".")[1];
    const fn = this[`show${action}`];

    if (typeof fn === 'function') {
      let { modalStyle } = data || {};

      this.setState({
        action,
        data,
        modalStyle,
        show: true,
        loading: true
      }, () => { fn(data) });

      return true;
    }

    const applyFn = this[`apply${action}`];

    if (typeof applyFn === 'function') {
      this.setState({ action: action, data: data }, () => { applyFn() });
      return true;
    }
  };

  handlePerform = () => {
    const { action } = this.state;
    event.preventDefault();

    let fn = this[`apply${action}`];

    if (typeof fn === 'function') fn();
  };

  onShowNew = () => {};
  onShowEdit = () => {};

  showNew = () => {
    const { path } = this.props;

    axios
      .get(`${path}/new.json`)
      .then((response) => {
        const response_data = response.data;
        this.setState(
          { formData: response_data.data, included: response_data.included, loading: false },
          () => this.onShowNew()
        );
      })
      .catch((error) => {
        console.log(error)
      });
  };

  renderNewForm = () => {
    return this.renderForm();
  };

  getData = () => {
    return new FormData(this.formRef.current || undefined);
  };

  applyNew = () => {
    const { path, name } = this.props;
    let data = this.getData();
    const config = { headers: { 'content-type': 'multipart/form-data' } };

    axios.post(path, data, config)
      .then((response) => {
        this.handleClose();
        PubSub.publish(`${name}.Created`, {});
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  };

  modalClosed = () => {
  };

  handleClose = () => {
    const newState = {
      show: false,
      formData: {},
      formErrors: {},
      loading: true
    };

    this.setState(newState, () => { this.modalClosed() });
  };

  showEdit = () => {
    const { path } = this.props;
    const { data } = this.state;

    axios
      .get(`${path}/${data.id}/edit.json`)
      .then((response) => {
        const response_data = response.data;
        this.setState(
          { formData: response_data.data, included: response_data.included, loading: false },
          () => this.onShowEdit()
        );
      })
      .catch((error) => {
        console.log(error)
      });
  };

  renderEditForm = () => {
    return this.renderForm();
  };

  applyEdit = () => {
    const { path, name } = this.props;
    let data = this.getData();

    const config = { headers: { 'content-type': 'multipart/form-data' } };

    axios
      .put(`${path}/${this.state.data.id}.json`, data, config)
      .then((response) => {
        this.handleClose();
        PubSub.publish(`${name}.Updated`, {});
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  };

  renderDeleteForm = () => {
    return (
      <div>
        <div className="text-center">Are you sure?</div>
      </div>
    )
  };

  showDelete = () => {
    this.setState({ loading: false });
  };

  applyDelete = () => {
    const { path, name } = this.props;
    const { data } = this.state;

    axios
      .delete(`${path}/${data.id}.json`)
      .then((response) => {
        this.handleClose();
        PubSub.publish(`${name}.Deleted`, {});
      })
      .catch((error) => {
        console.log(error)
      });
  };

  formValue(name) {
    const { formData } = this.state;
    if (formData === undefined) return undefined;
    return (name in formData) ? formData[name] : undefined;
  }

  formError(name) {
    const { formErrors } = this.state;
    if (formErrors === undefined) return null;
    return (name in formErrors) ? formErrors[name] : null;
  }

  includedData(name) {
    const { included } = this.state;
    if (included === undefined) return undefined;
    return (name in included) ? included[name].data : undefined;
  }

  renderBody() {
    const { loading, action } = this.state;
    if (loading) return <div className="text-center">Loading....</div>;

    let fn = this[`render${action}Form`];
    
    return (
      <ErrorBoundary>
        <form ref={this.formRef}>
          {(typeof fn === 'function') && fn()}
        </form>
      </ErrorBoundary>
    )
  }

  renderForm() {
    return null;
  }

  renderButtons() {
    const { action } = this.state;
    let fn = this[`render${action}Buttons`];

    if (typeof fn === 'function') return fn();

    return (
      <div>
        <Button className='btn btn-default' onClick={this.handleClose}>Cancel</Button>
        <Button className='btn btn-primary' onClick={this.handlePerform}>Confirm</Button>
      </div>
    )
  }

  showDefault = (action) => {
    const { data } = this.state;
    const { path } = this.props;

    axios
      .get(`${path}/${data.id}/${action}.json`)
      .then((response) => {
        const response_data = response.data;
        this.setState({ formData: response_data.data, included: response_data.included, loading: false });
      })
      .catch((error) => {
        console.log(error);
        this.handleClose();
      });
  };

  applyDefault = (action, callbackParams) => {
    const { path, name } = this.props;
    const { data } = this.state;
    const requestData = this.getData();

    axios
      .post(`${path}/${data.id}/${action}.json`, requestData)
      .then(() => {
        this.handleClose();
        PubSub.publish(`${name}.Updated`, callbackParams);
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  };

  getTitle() {
    const { title } = this.props;
    const { action, data } = this.state;

    let modalAction = (data || {}).modalAction || action;
    let modalTitle = (data || {}).modalTitle;

    if (modalTitle) return modalTitle;

    return `${modalAction} ${title}`;
  }

  getModalClassName = (action) => { return null };

  render() {
    const { bsSize, name } = this.props;
    const { show, action } = this.state;

    const modalClassName = ((name || '').toLowerCase() + '-'  + (action || '').toLowerCase());

    let modalProps = {
      bsSize: bsSize,
      show: show,
      onHide: this.handleClose,
      className: classnames([modalClassName, this.getModalClassName(action)])
    };

    return (
      <Modal {...modalProps}>
        <Modal.Header closeButton>
          <Modal.Title>{this.getTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ErrorBoundary>
            {show && this.renderBody()}
          </ErrorBoundary>
        </Modal.Body>
        <Modal.Footer>
          {show && this.renderButtons()}
        </Modal.Footer>
      </Modal>
    );
  }
}

SmartModal.FieldGroup = FieldGroup;
