import React, { Component, Fragment } from 'react';
import Message from './message';
import Form from './form';
import { Can } from '../ability_context';

export default class Messages extends Component {
  render() {
    const { data, included, inbox, interlocutor, onSendMessage, path } = this.props;

    if (!(Array.isArray(data) && data.length > 0)) return null;

    const isSchoolhireInterlocutor = (interlocutor || {}).schoolhire;

    return(
      <div id="messages">
        <div id="send_message_form">
          <div className="row">
            <div className="col-md-12 vert-offset-bottom-10 text-center" style={{ backgroundColor: '#f2f5fc' }}>
              <h4>{inbox.name} &rarr; {interlocutor.name}</h4>
            </div>
          </div>
          {!isSchoolhireInterlocutor &&
          <Fragment>
            <Can I="newMessageForm" a="AdminMessages">
              <Form inbox={inbox}
                    included={included}
                    interlocutor={interlocutor}
                    onSend={onSendMessage}
                    path={path}/>
            </Can>
            <Can I="newMessageForm" a="AccountMessages">
              <Form inbox={inbox}
                    included={included}
                    interlocutor={interlocutor}
                    onSend={onSendMessage}
                    path={path}/>
            </Can>
          </Fragment>
          }
        </div>

        {data.map((item, index) => <Message key={index} inbox={inbox} data={item}/>)}
      </div>
    )
  }
}
