import React, { Component } from 'react';
import BookingItemRow from './booking_item_row';

export default class Table extends Component {
  render() {
    const { items } = this.props;

    return (
      <table className='table table-bordered'>
        <thead>
        <tr>
          <th>Date of booking</th>
          <th>Invoice number</th>
          <th>Invoice date</th>
          <th>Customer name</th>
          <th>Activity category</th>
          <th>Booking option</th>
          <th>Net price</th>
          <th>VAT</th>
          <th>Gross price</th>
          <th>Date of payment</th>
        </tr>
        </thead>
        <tbody>
        {items.map((booking_item, index) =>
          <BookingItemRow key={index} {...booking_item} />
        )}
        </tbody>
      </table>
    )
  }
}
