import React, { Component } from 'react';

export default class Dataitem extends Component {
  render() {
    const { title, children } = this.props;

    if (children === null || children === undefined || children === "") return null;

    return (
      <li><span>{title}:</span> <span>{children}</span></li>
    )
  }
}
