import React, { Component } from 'react';
import classnames from 'classnames';

export default class Tabs extends Component {
  constructor() {
    super();
    this.state = { activeTab: null };
    this.changeTab = this.changeTab.bind(this);
  }

  componentDidMount() {
    this.changeTab(null, this.props.list[0]);
  }

  changeTab(e, tab) {
    if (e) e.preventDefault();
    this.setState({ activeTab: tab })
  }

  renderActiveTab() {
    if (this.state.activeTab === null) return null;
    return React.cloneElement(this.state.activeTab.component, this.props);
  }

  render() {
    const { list } = this.props;

    if (!list) return null;

    let activeTab = this.state.activeTab || {};
    let activeTabName = activeTab.name;

    //className="ok-icon"

    return (
      <div>
        <div style={{marginBottom: 15}}>
          <ul className='nav nav-tabs nav_tabs_c'>
            {list.filter((tab) => tab.show !== false).map((tab) =>
              <li key={tab.name} className={classnames({ active: (activeTabName === tab.name) })}>
                <a href='#' onClick={(e) => this.changeTab(e, tab)}>
                  { tab.title }
                </a>
              </li>
            )}
          </ul>
        </div>
        {this.renderActiveTab()}
      </div>
    )
  }
}

