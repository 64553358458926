import React from 'react';
import Modal from './modal';
import SmartBlock from '../../smart/block';

export default class Block extends SmartBlock {
  renderTable() {
    const { data } = this.state;
    if (data === '') return null;

    return (
      <table className="table table-striped table-sm">
        <thead>
        <tr>
          <th>Title</th>
          <th style={{ width: 100 }}/>
        </tr>
        </thead>
        <tbody>
        {data.map((item, index) =>
          <tr key={index}>
            <td>
              {item.title}
            </td>
            <td>
              {this.renderItemActions(item)}
            </td>
          </tr>
        )}
        </tbody>
      </table>
    )
  }


  render() {
    return (
      <div>
        <Modal {...this.props} />
        {this.renderTable()}
      </div>
    )
  }
}

