import React, { Component } from 'react';
import Page from '../page';
import Table from './table';

export default class Index extends Component {
  render() {
    return (
      <div>
        <Page.Header leftSide2={<a className="btn btn-primary pull-right">Internal booking</a>}>
          Calendar
        </Page.Header>

        <Table/>
      </div>
    );
  }
}
