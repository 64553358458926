import React, { Component } from 'react';
import MapWithMarkers from './map_with_markers';

const defaultCenter = { lat: 51.500152, lng: -0.126236 }; // London

export default class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      center: defaultCenter
    }
  }

  handleMapMounted = (map) => {
    this.map = map;
  };

  handleDragEnd = () => {
    const { onChangeCenter } = this.props;
    const centerData = this.map.getCenter();
    const center = { lat: centerData.lat(), lng: centerData.lng() };

    this.setState({ center }, () =>
      onChangeCenter(center)
    );
  };

  render() {
    const { loading, markers, center, distance } = this.props;

    return(
      <MapWithMarkers
        loading={loading}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        center={center}
        markers={markers}
        distance={distance}
        onMapMounted={this.handleMapMounted}
        onCenterChanged={this.onCenterChanged}
        onDragEnd={this.handleDragEnd}
      />
    );
  }
};
