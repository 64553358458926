import React, { useEffect, useMemo } from 'react';
import { useTimer } from 'react-timer-hook';

function addPrefixZero(x) {
  return x < 10 ? `0${x}` : x
}

function MyTimer({ initSeconds, trigger, onExpire }) {
  const expiryTimestamp = useMemo(() => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + initSeconds); // 10 minutes timer
    return time
  }, [initSeconds])

  const { seconds, minutes, hours, days, restart } = useTimer({ expiryTimestamp, onExpire: onExpire });

  useEffect(() => {
    const time = new Date();
    time.setSeconds(time.getSeconds() + initSeconds); // 10 minutes timer
    restart(time)
  }, [trigger])

  const fhours = useMemo(() => addPrefixZero(hours), [hours])
  const fminutes = useMemo(() => addPrefixZero(minutes), [minutes])
  const fseconds = useMemo(() => addPrefixZero(seconds), [seconds])

  const daysLine = days > 0 ? <><span>{days}</span> { days > 1 ? 'days' : 'day' }</> : '';
  const hoursLine = fhours > 0 ? <><span>{fhours}</span>:</> : '';
  const minutesLine = <span>{fminutes}</span>;
  const secondsLine = <span>{fseconds}</span>;

  return <>{daysLine} {hoursLine}{minutesLine}:{secondsLine}</>;
}

export default ({ seconds = 10, trigger, onExpire }) => {
  const timer = <MyTimer initSeconds={seconds} trigger={trigger} onExpire={onExpire}/>;
  return (
    <span className='label label-warning' style={{ fontWeight: 'normal', fontSize: 13 }}>
      You have {timer} to complete this stage of the booking process
    </span>
  );
}
