import React, { Component } from 'react';
import classnames from 'classnames';

class Item extends Component {
  render() {
    const { name, href, title, active, hidden } = this.props;

    if (hidden) return null;

    const isActive = (active === name);
    const liClassName = classnames({ 'active': isActive });
    const aClassName = classnames({ 'active': isActive });

    return (
      <li className={liClassName}>
        <a className={aClassName} href={href}>
          {title}
        </a>
      </li>
    );
  }
}

export default class Submenu extends Component {
  render() {
    const { items, active } = this.props;

    return (
      <ul className="maintabs_submenu">
        {items.map((item, index) => <Item key={index} {...item} active={active} />)}
      </ul>
    )
  }
}
