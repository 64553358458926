import React from 'react';
import SmartLabel from '../smart/label';

export default class StatusLabel extends SmartLabel {
  constructor() {
    super();

    this.items = {
      in_progress: { title: 'In progress', className: 'label-booking-item-status-in-progress', hidden: true },
      started: { title: 'Started', className: 'label-booking-item-status-started' },
      completed: { title: 'Completed', className: 'label-booking-item-status-completed' },
      cancelled: { title: 'Cancelled', className: 'label-booking-item-status-cancelled' },
      partially_cancelled: { title: 'Partially cancelled', className: 'label-booking-item-status-partially-cancelled' }
    }
  }
}
