import React, { Component } from 'react';
import { Form, FormGroup, Checkbox, ButtonGroup, Button } from 'react-bootstrap'
import FieldGroup from '../../smart/field_group';
import axios from 'axios';
import moment from 'moment';
import Moment from 'react-moment';
import { spacesDropDownList } from '../schedule_items/helper';

export default class GeneratorForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: new Date(),
      endCondition: 'occurrences',
      multiDayEvent: false,
      formErrors: {},
    };

    this.formRef = React.createRef();
  }

  changeStartDate = (value) => {
    this.setState({ startDate: value }, () => { console.log(value) });
  };

  generateList = () => {
    const { groupUid, bookingOptionId, onChangeScheduleItems } = this.props;
    const data = new FormData(this.formRef.current);

    if (groupUid !== null) data.append('group_uid', groupUid);
    if (bookingOptionId !== null) data.append('booking_option_id', bookingOptionId);

    axios
      .post(`${window.location.pathname}/schedule_items.json`, data)
      .then((response) => {
        const response_data = response.data;
        onChangeScheduleItems(response_data.data.group_uid);
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  };

  removeChanges = () => {
    const { groupUid, onChangeScheduleItems } = this.props;

    axios
      .post(`${window.location.pathname}/schedule_items/clear.json`, { group_uid: groupUid })
      .then(() => onChangeScheduleItems(groupUid))
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  };

  formError = (name) => {
    if (this.state.formErrors === undefined) return null;
    return (name in this.state.formErrors) ? this.state.formErrors[name] : null;
  };

  endTypeChanged = (e) => {
    this.setState({ endCondition: e.target.value }, () => { console.log(this.state) });
  };

  multiDayEventChanged = () => {
    this.setState({ multiDayEvent: !this.state.multiDayEvent })
  };

  render() {
    const { activity } = this.props;
    const { multiDayEvent, startDate, endCondition } = this.state;

    let startDayWeekDay = moment(startDate).day();
    if (startDayWeekDay === 0) startDayWeekDay = 7;

    return (
      <form ref={this.formRef} className='form-horizontal'>
        <FieldGroup
          formType="custom"
          label="Date"
          type='date'
          format="ddd, D MMM YYYY"
          name="start_date"
          value={startDate}
          onChange={this.changeStartDate}
          error={this.formError('start_date')}
          custom={{
            colLabelStyle: { width: 100 },
            colLabelClass: '',
            colInputStyle: {},
            colInputClass: 'start-date-input',
          }}
        />

        <FieldGroup
          formType="custom"
          type="time-slot"
          label="Time slot:"
          start={{
            name: "start_time",
            defaultValue: moment('2008-01-01T09:00:00'),
            error: this.formError('start_time')
          }}
          end={{
            name: 'end_time',
            defaultValue: moment('2008-01-01T15:00:00'),
            error: this.formError('end_time')
          }}
          custom={{
            colLabelStyle: { width: 100 },
            colLabelClass: '',
            colInputStyle: {},
            colInputClass: '',
          }}
        />

        <FieldGroup
          formType="custom"
          label="Spaces:"
          type='select'
          options={spacesDropDownList(activity.places)}
          name="places"
          defaultValue="3"
          error={this.formError('places')}
          custom={{
            colLabelStyle: { width: 100 },
            colLabelClass: '',
            colInputStyle: {},
            colInputClass: 'places-input',
          }}
        />

        <div style={{ paddingBottom: 10 }}>
          <Checkbox name='multi_day_event' value={true} checked={multiDayEvent} onChange={this.multiDayEventChanged}>
            <strong>MULTI DAY EVENT</strong>
          </Checkbox>
        </div>

        {multiDayEvent &&
        <div>
          <FieldGroup
            formType="inline"
            type="select"
            label="Event will run every"
            name="repeat_every"
            defaultValue="1"
            options={[
              { id: 1, title: '1' },
              { id: 2, title: '2' },
              { id: 3, title: '3' },
              { id: 4, title: '4' },
            ]}
            error={this.formError('repeat_every')}
            custom={{
              colLabelStyle: { width: 170 },
              colLabelClass: '',
              colInputStyle: { width: 200 },
              colInputClass: 'repeat-every-input',
              appendInputGroup: 'week(s)'
            }}
          />

          <div>
            <div style={{ width: 170, float: 'left', paddingTop: 8 }}>
              <strong>
                From <Moment date={startDate} format="ddd, D MMM YYYY"/>
              </strong>
            </div>
            <div style={{ width: 320, float: 'left' }}>
              <FieldGroup
                name="occurrences"
                formType="custom"
                label="End after:"
                defaultValue="3"
                error={this.formError('places')}
                custom={{
                  prependLabel: (
                    <input
                      type="radio"
                      name="end_condition"
                      value='occurrences'
                      checked={ endCondition === 'occurrences' }
                      onChange={this.endTypeChanged}
                    />
                  ),
                  colLabelStyle: { width: 115 },
                  colLabelClass: '',
                  colInputStyle: { width: 220 },
                  colInputClass: 'occurrences-input',
                  appendInputGroup: 'occurrences'
                }}
              />

              <FieldGroup
                name="end_date"
                formType="custom"
                label="End by:"
                type="date2"
                defaultValue={moment().toDate()}
                fromMonth={moment().toDate()}
                toMonth={moment().add(5, 'years').toDate()}
                month={moment().toDate()}
                format="ddd, D MMM YYYY"
                error={this.formError('end_date')}
                custom={{
                  prependLabel: (
                    <input
                      type="radio"
                      name="end_condition"
                      value='date'
                      checked={endCondition === 'date'}
                      onChange={this.endTypeChanged}
                    />
                  ),
                  colLabelStyle: { width: 115 },
                  colLabelClass: '',
                  colInputStyle: { width: 220 },
                  colInputClass: 'end-condition-input',
                }}
              />
            </div>
          </div>

          <div className="clearfix"/>

          <div className="col-lg-12">
            <FieldGroup
              defaultDays={[startDayWeekDay]}
              type="week-days"
              name="week_days"
              error={this.formError('places')}
            />
          </div>
        </div>
        }

        <div className="clearfix"/>

        <div className='btn-toolbar vert-offset-bottom-15 vert-offset-top-5'>
          <Button bsStyle="primary" onClick={this.generateList}>Generate sessions</Button>
          <Button bsStyle="danger" onClick={this.removeChanges}>Remove sessions</Button>
        </div>
      </form>
    )
  }
}