import React from 'react';
import SmartBlock from '../../smart/block';
import Modal from './modal';
import Table from "./table";

export default class Block extends SmartBlock {
  handleDisable = (id) =>  {
    this.handleAction('Disable', { id: id });
  };

  handleEnable = (id) => {
    this.handleAction('Enable', { id: id });
  };

  render() {
    return (
      <div>
        <Modal {...this.props}/>

        <div className="panel panel-default panel-block">
          <div className="panel-heading">
            <h3 className="pull-left">Extras</h3>
            <div className="btn-group pull-right">
              <a className="btn btn-primary" onClick={this.handleNew}>Add extra</a>
            </div>
            <div className="clearfix"/>
          </div>
          <div className="panel-body panel-body-with-table">
            <div className="table-box">
              <Table
                data={this.state.data}
                onEdit={this.handleEdit}
                onDelete={this.handleDelete}
                onDisable={this.handleDisable}
                onEnable={this.handleEnable}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

