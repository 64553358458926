import React, { Component, Fragment } from 'react';
import { Form, Button } from 'react-bootstrap';
import SmartModal from '../../smart/modal';
import Block from './block';

export default class ListModal extends SmartModal {
  renderBody() {
    const { loading, action } = this.state;
    if (loading) return <div className="text-center">Loading....</div>;

    let fn = this[`render${action}Form`];

    return <Fragment>{(typeof fn === 'function') && fn()}</Fragment>;
  }

  showIndex = () => this.setState({ show: true, loading: false });

  renderIndexForm = () => {
    const { data } = this.state;
    const { venue_customer_id } = data;

    return (
      <Block
        name='Attachment'
        path='/admin/attachments'
        venueCustomerId={venue_customer_id}
      />
    );
  };

  renderIndexButtons = () => null;
}
