import React, { Component } from 'react';
import SmallGallery from '../shared/small_gallery';
import Nl2Br from "../smart/nl2br";

export default class Activity extends Component {
  render() {
    const { data } = this.props;
    if (!data) return null;

    const { images, internal_reference, formatted_address, description } = data;

    return (
      <div className="panel panel-default activity">
        <div className="panel-body">
          <div className="left-gallery">
            <SmallGallery images={images}/>
          </div>
          <div className="right-info">
            <div className="title">
              {internal_reference}
            </div>

            <div className="address">
              {formatted_address}
            </div>

            <div className="description">
              <Nl2Br text={description}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}