import React, { Component } from 'react';
import axios from 'axios';

export default class Block extends Component {
  constructor() {
    super();

    this.state = { data: undefined };
  }

  handleChange = (e) => {
    const { onUpdate, id } = this.props;

    axios
      .put(`/admin/notifications/${id}.json`, { notify_by_email: e.target.checked })
      .then((response) => {
        this.setState({ data: response.data.data }, () => onUpdate() )
      })
      .catch((error) => {
        console.log(error);
        onUpdate();
      })
  };

  render() {
    const { notify_by_email, venue } = this.props;

    console.log(this.props);

    return (
      <tr>
        <td>{venue.title}</td>
        <td className='text-center'>
          <form ref={this.formRef}>
            <div className='notification-checkbox'>
              <input
                name='notify_by_email'
                defaultChecked={notify_by_email}
                type='checkbox'
                onChange={this.handleChange}
              />
            </div>
          </form>
        </td>
      </tr>
    )
  }
}