import React, { Component } from 'react';
import ActivityCategories from './activity_categories';
import Activities from './activities';
import Activity from './activity';
import BookingOptions from './booking_options';
import Extras from './extras';
import Attendees from './attendees';
import CalendarWrapper from './calendar_wrapper';
import BookingItemsTable from '../booking_items/table';
import SplitPaymentsTable from '../split_payments/table';
import queryString from 'query-string';
import axios from 'axios';
import { Alert, Button } from 'react-bootstrap';
import PubSub from 'pubsub-js';
import { UserContext } from '../user';
import ProcessButton from "./ProcessButton";
import Edit from "../bills/edit";
import Cookies from "js-cookie";
import { toastr } from "react-redux-toastr";
import Timer from "../checkouts/Timer";

const emptyAttendee = {
  name: '',
  birthday: ''
};

export default class Booking extends Component {
  constructor() {
    super();

    this.state = {
      editActivityCategoryDisabled: false,
      venue: null,
      activities: [],
      bookingOptions: [],
      bill: null,
      venueId: null,
      activityCategory: null,
      activity: null,
      fullActivity: null,
      bookingOption: null,
      bookingOptionId: null,
      bookingOptionItem: null,
      attendees: [emptyAttendee],
      dates: [],
      scheduleItemIds: [],
      loadingBill: false,
      bookingItems: null,
      splitPayments: null,
      formErrors: null,
      attendeeErrors: null,
      pendingUser: false,
      step: 0,
      timer: 60 * 60,
      trigger: new Date(),
      showTimer: false
    };

    this.form = React.createRef();
  }

  componentDidMount() {
    this.fetchVenue();
    PubSub.subscribe('userSignedIn', this.userSignedIn);

    const fullyBookedMessage = Cookies.get('FullyBookedMessage');
    if (fullyBookedMessage) {
      Cookies.remove('FullyBookedMessage')
      toastr.error('', fullyBookedMessage);
    }
  }


  fetchVenue = () => {
    const query = queryString.parse(location.search);
    const activityCategoryId = parseInt(query.activity_category_id);
    const activityId = parseInt(query.activity_id);
    const venueId = window.PageData.venue_id;

    axios
      .get(`/venues/${venueId}.json`)
      .then(response => {
        const venue = response.data.data;
        const { activity_categories, activities } = venue;
        const activityCategory = activity_categories.find((item) => item.id === activityCategoryId);

        this.setState({ venue, activityCategory, activities }, () => {
          this.fetchFullActivity(activityId)
        });
      });
  };


  fetchFullActivity(activityId) {
    if (isNaN(activityId))return null;

    axios
      .get(`/activities/${activityId}.json`)
      .then(response => {
        const response_data = response.data;
        this.setState({ activity: response_data.data, fullActivity: response_data.data });
      });
  }

  fetchBookingOption(bookingOptionId, onSuccess) {
    if (isNaN(bookingOptionId)) return null;

    axios
      .get(`/booking_options/${bookingOptionId}.json`)
      .then(response => this.setState({ bookingOption: response.data }, () => onSuccess()))
      .catch(e => console.log(e));
  }

  getFilteredActivities = (activityCategory) => {
    const { venue } = this.state;
    let { activities } = venue;

    if (activityCategory) {
      activities = activities.filter(activity => activity.activity_category_id === activityCategory.id);
    }

    return activities;
  };

  changeActivityCategory = (activityCategory) => {
    const activities = this.getFilteredActivities(activityCategory);
    let { activity, fullActivity } = this.state;
    let activityId = (activity || {}).id;
    let activitiesIds = activities.map(activity => activity.id);

    if (!activitiesIds.includes(activityId)) {
      activity = null;
      fullActivity = null;
    }

    this.setState({ activityCategory, activity, fullActivity }, () => {
      if (activity) this.fetchFullActivity(activity.id);
    });
  };

  changeActivity = (activity) => {
    this.setState({ activity: activity, bookingOption: null, bookingOptions: [] }, () => {
      this.fetchFullActivity(activity.id)
    });
  };

  changeBookingOption = (bookingOptionId) => {
    this.setState({ bookingOptionId: bookingOptionId, bookingOption: null }, () => {
      this.fetchBookingOption(bookingOptionId, () => this.scrollToId('attendees-section'))
    })
  };

  scrollToId(name) {
    setTimeout(
      () => {
        const el = document.getElementById(name);
        if (el === null) return false;
        $('html, body').animate({ scrollTop: el.offsetTop + 150 }, 200);
      },
      50
    )
  }

  generateBooking = () => {
    let config = {};
    let {
      billId,
      activity,
      bookingOptionId,
      attendees,
      bookingOptionItem,
      dates,
      scheduleItemIds
    } = this.state;

    activity = (activity || {});

    let extraIds = ((activity.extras || {}).data || []).filter((extra) => extra.checked).map((extra) => extra.id);

    let data = {
      booking: {
        user_id: window.PageData?.user_id,
        activity_id: activity?.id,
        bill_id: billId,
        booking_option_id: bookingOptionId,
        attendees_attributes: attendees,
        booking_option_item_id: bookingOptionItem?.id,
        extra_ids: extraIds,
        dates: dates,
        schedule_item_ids: scheduleItemIds,
        formErrors: [],
        attendeeErrors: []
      }
    };

    axios
      .post('/booking.json', data, config)
      .then((response) => {
        const response_data = response.data;
        const billData = response_data.bill;
        const { id, booking_id } = billData;
        this.setState({ billId: id, bookingId: booking_id, formErrors: [] }, () => {
          this.fetchBill();
        });
      })
      .catch((error) => {
        const { errors, attendees } = error.response.data;
        this.setState({ loadingBill: false, formErrors: errors, attendeeErrors: attendees }, () => {
          if (Array.isArray(attendees) && attendees.length !== 0) {
            this.scrollToId('attendees-section');
          }
        });
      });
  };

  fetchBill = () => {
    const { bookingId, billId, venue } = this.state;

    axios
      .get(`/bookings/${bookingId}/bills/${billId}.json`)
      .then((response) => {
        const response_data = response.data;
        const { activity_category_id, booking_items, booking_status, booking_obsolete_left_seconds} = response_data.data;
        const activityCategory = venue.activity_categories.find((el) => el.id === activity_category_id);
        const editActivityCategoryDisabled = (booking_items.data.length > 0);

        this.handleUpdateTimer(booking_obsolete_left_seconds);
        this.setState({ bill: response_data, activityCategory, editActivityCategoryDisabled }, () => {
          this.scrollToId('booking-items-section')
        });

        if (booking_status === "obsoleted") {
          this.setState({ bookingId: null, billId: null, bill: null, showTimer: false })
        }
      })
      .catch((error) => {
        this.setState({ loadingBill: false });
      });
  };

  handleAddAttendee = () => {
    let { attendees } = this.state;
    attendees.push({ name: null, birthday: null });
    this.setState({ attendees });
  };

  handleRemoveAttendee = (index) => {
    let { attendees } = this.state;
    attendees.splice(index, 1);
    this.setState({ attendees });
  };

  handleUpdateAttendees = (attendees) => {
    this.setState({ attendees });
  };

  handleUpdateExtras = (extras) => {
    let { fullActivity } = this.state;
    let newActivity = {...fullActivity};
    newActivity.extras.data = extras;
    this.setState({ activity: newActivity });
  };

  handleUpdateBookingOptionItem = (bookingOptionItem, dates, scheduleItemIds) => {
    this.setState({ bookingOptionItem, dates, scheduleItemIds });
  };

  handleRemoveBookingItem = (id) => {
    const { bookingId, billId } = this.state;

    axios
      .delete(`/bookings/${bookingId}/bills/${billId}/booking_items/${id}.json`)
      .then(response => { this.fetchBill() })
      .catch()
  };

  formError(name) {
    const { formErrors } = this.state;
    if (formErrors === null) return null;
    if (typeof formErrors !== 'object') return null;
    return (name in formErrors) ? formErrors[name] : null;
  }

  onUpdate() {}

  handleUpdateTimer = (seconds) => this.setState({ showTimer: true, timer: seconds, trigger: new Date() });
  handleTimerExpire = () => { window.location.href = '/' }

  // to component
  renderBill() {
    const { bill, bookingId, billId } = this.state;

    if (!bill) return null;

    const { booking_items, split_payments, edit_price_adjustment } = bill.data;
    const { timer, trigger, showTimer } = this.state;

    return (
      <div>
        { booking_items.data.length > 0 &&
          <div className="section" id='booking-items-section'>
            <div className="row">
              <div className='col-md-6 col-sm-12'>
                <h4>List of sessions</h4>
              </div>
              <div className='col-md-6 col-sm-12'>
                <div className='booking_timer_box'>
                  {showTimer && <Timer seconds={timer} trigger={trigger} onExpire={this.handleTimerExpire}/>}
                </div>
              </div>
            </div>
            <BookingItemsTable
              billData={bill.data}
              items={booking_items.data}
              onRemoveItem={this.handleRemoveBookingItem}
              onUpdate={this.fetchBill}
            />
          </div>
        }

        {split_payments.data.length > 1 &&
          <div className="section" id='split-payments-section'>
            <h4>Payment Schedule</h4>
            <SplitPaymentsTable
              items={split_payments.data}
              included={split_payments.included}
            />
          </div>
        }

        {edit_price_adjustment &&
          <div>
            <Edit
              path={`/bookings/${bill.data.booking_id}`}
              {...bill.data}
              onUpdate={this.fetchBill}
            />
          </div>
        }

        <div className="section booking-buttons-section">
          <div className="row">
            <div className="col-md-6">
              <Button bsStyle='primary' onClick={() => this.scrollToId('booking-options-section')}>
                Add another activity
              </Button>
            </div>
            <div className="col-md-6 text-right">
              <UserContext.Consumer>
                {user => <ProcessButton billId={billId} bookingId={bookingId} user={user}/>}
              </UserContext.Consumer>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      venue,
      activityCategory,
      editActivityCategoryDisabled,
      activity,
      fullActivity,
      bookingOption,
      bookingOptionItem,
      attendees,
      attendeeErrors
    } = this.state;

    if (venue === null) return null;

    const datesErrors = this.formError('dates');
    const activities = this.getFilteredActivities(activityCategory);

    return (
      <div>
        <h3>Book your sessions on your activity</h3>
        <div className="row">
          <div className="col-lg-12 generator-panel">
            <div>
              <form ref={this.form}>
                <ActivityCategories
                  activityCategories={venue.activity_categories}
                  onChange={this.changeActivityCategory}
                  activityCategory={activityCategory}
                  isDisabled={editActivityCategoryDisabled}
                />

                <Activities
                  activities={activities}
                  onChange={this.changeActivity}
                  activity={activity}
                />

                {fullActivity &&
                <Activity data={fullActivity}/>
                }

                {fullActivity &&
                <div>
                  <BookingOptions
                    selectedBookingOptionId={this.state.bookingOptionId}
                    bookingOptions={fullActivity.booking_options.data}
                    onChange={this.changeBookingOption}
                  />

                  {bookingOption &&
                  <Attendees
                    attendees={attendees}
                    onAddAttendee={this.handleAddAttendee}
                    onRemoveAttendee={this.handleRemoveAttendee}
                    onUpdateAttendees={this.handleUpdateAttendees}
                    errors={attendeeErrors}
                  />
                  }

                  {bookingOption &&
                  <Extras
                    extras={activity.extras.data}
                    onUpdate={this.handleUpdateExtras}
                  />
                  }

                  {bookingOption &&
                  <CalendarWrapper
                    bookingOption={bookingOption}
                    updateBookingOptionItem={this.handleUpdateBookingOptionItem}
                  />
                  }

                  {datesErrors &&
                  <Alert bsStyle="danger">
                    {datesErrors.map((error) =>
                      <div>{error}</div>
                    )}
                  </Alert>
                  }
                </div>
                }
              </form>
            </div>

            {bookingOption && bookingOptionItem &&
            <div className="section">
              <div className="row">
                <div className="col-md-6">
                  <Button bsStyle='primary' onClick={this.generateBooking}>
                    ADD ACTIVITY TO BASKET
                  </Button>
                </div>
              </div>
            </div>}

            {this.renderBill()}
          </div>
        </div>
      </div>
    )
  }
}
