import React from 'react';
import {CenterText} from "../smart/cells";
import StatusLabel from './status_label';
import OfflineLabel from "./offline_label";

export default ({ status, offline }) => (
  <CenterText className='status-column'>
    <StatusLabel value={status}/><OfflineLabel value={offline}/>
  </CenterText>
)
