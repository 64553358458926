import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

export const BackButton = (props) => (
  <Button bsSize='large' className="btn-grey btn-lg btn-no-radius" onClick={props.onClick}>
    Back
  </Button>
);

export const ContinueButton = (props) => (
  <Button bsSize='large' className="btn-primary btn-lg btn-no-radius" onClick={props.onClick}>
    Continue
  </Button>
);
