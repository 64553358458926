import React, { Component } from 'react';

export default class UserInformation extends Component {
  render() {
    const { data } =  this.props;

    if (!data) return null;

    const { name, address, postcode, phone, organisation } = data;

    return (
      <div className="section">
        <div className="section-header">
          <h3>User Information</h3>
        </div>

        <dl className="dl-horizontal dl-approvals">
          <dt>Full name:</dt>
          <dd>{name}</dd>
          <dt>Address:</dt>
          <dd>{address}</dd>
          <dt>Postcode:</dt>
          <dd>{postcode}</dd>
          <dt>Phone Number:</dt>
          <dd>{phone}</dd>
          <dt>Name of Organisation:</dt>
          <dd>{organisation}</dd>
        </dl>
      </div>
    );
  }
}