import FieldGroup from "../../../smart/field_group";
import React, { useEffect, useState } from "react";
import Fetch from "../../fetch";

export default ({ onChange, onReady }) => {
  const [venues, setVenues] = useState(null);

  useEffect(() => { Fetch.venues().then(response => setVenues(response.data.data)) }, []);
  useEffect(() => { if (venues !== null) onReady() }, [venues])

  return (
    <FieldGroup
      label="Venue:"
      type="select"
      firstIfOne={true}
      name="venue_id"
      emptyOption={{ title: 'All venues' }}
      options={venues}
      onChange={onChange}
    />
  )
}