import React from "react";
import Message from "./message";

export default (props) => {
  const { messages, inbox, interlocutor } = props;

  if (!messages) { return <div className='text-center'>Loading...</div>; }

  if (messages.length === 0) return null;

  return (
    <div id="messages" className='modal-messages-box'>
      {messages.map((item, index) => <Message key={index} inbox={inbox} data={item}/>)}
    </div>
  )
};

