import React, { Component, Fragment } from 'react';
import { Button } from 'react-bootstrap';
import Page from '../page';
import Paginate from './paginate';
import ExportButtons from './export_buttons';
import FormToObject from 'form-to-object';
import axios from 'axios';
import queryString from 'query-string';

export default class Show extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      filterReady: false,
      data: null,
      page: 1
    };

    this.filterRef = React.createRef();
  }

  fetchData = (page) => {
    const { path } = this.props;
    const filterParams = FormToObject(this.filterRef.current);

    this.setState({ loading: true });

    axios
      .get(`${path}.json`, { params: {...filterParams, page: (page || 1) } })
      .then((response) => {
        const { data } = response.data;
        this.setState({ data, page: data.current_page, pageCount: data.total_pages, loading: false })
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.log(error);
      });
  };

  onFilterReady = () => this.fetchData();
  onFilterChanged = () => this.fetchData();
  handlePageClick = (clickedPage) => this.fetchData(clickedPage.selected + 1);

  generateFile = (format) => {
    if (!['pdf', 'xlsx'].includes(format)) return false;

    const { path } = this.props;
    const filterParams = FormToObject(this.filterRef.current);

    window.open(`${path}.${format}?${queryString.stringify(filterParams)}`, '_blank');
  };

  render() {
    const { title, filter, presenter } = this.props;
    const { data, page, pageCount, loading } = this.state;

    let context, paginate = null;

    if (loading) {
      context = <div className='text-center'>Loading...</div>;
      paginate = null;
    } else {
      if (data) {
        context = React.cloneElement(presenter, { data: data });
        paginate = (
          <Paginate
            page={page}
            pageCount={pageCount}
            onPageClick={this.handlePageClick}
          />
        )
      }
    }

    return (
      <Fragment>
        <Page.Header leftSide={<ExportButtons onClick={this.generateFile}/>}>
          {title}
        </Page.Header>

        <form ref={this.filterRef} className="form-inline normal-font-weight-labels report-filter">
          {React.cloneElement(filter, { onReady: this.onFilterReady, onChanged: this.onFilterChanged })}
        </form>

        {context}
        {paginate}
      </Fragment>
    );
  }
}
