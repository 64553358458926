import React, { useRef } from 'react';
import { ActionCable } from 'react-actioncable-provider'
import { toastr } from "react-redux-toastr";

export default ({ show, setShow, paymentOptions, message, setProcessing, listenWS, setListenWS }) => {
  if (!show) return null;

  const refModificationChannel = useRef();

  function handleConnected() {
    const channel = refModificationChannel.current;
    if (!channel) return null;
    channel.perform('check');
  }

  function handleReceived(message) {
    switch (message.status) {
      case 'successful':
        setProcessing(false);
        setShow(false);
        setListenWS(false)
        if (paymentOptions.redirectTo) window.location.href = paymentOptions.redirectTo;
        break;
      case 'failed':
        setProcessing(false)
        setListenWS(false)
        toastr.error('', message.error_messages.join(', '));
        break;
    }
  }

  return (
    <div className='payment-spinner-box'>
      {paymentOptions && listenWS &&
        <>
          <ActionCable
            ref={refModificationChannel}
            channel={{ channel: 'ModificationChannel', id: paymentOptions.modificationId }}
            onReceived={handleReceived}
            onConnected={handleConnected}
          />
        </>
      }

      <div>
        <div id="loader-wrapper"><div id="loader"/></div>
        <h3 className="text-center">{message}</h3>
      </div>
    </div>
  );
}
