import React, { Component } from 'react';
import { AbilityContext } from '../../ability_context';
import { ActionsCell as ParentActionsCell } from '../../smart/cells';
import PubSub from 'pubsub-js';

export class ActionsCell extends Component {
  handleDelete(row) {
    PubSub.publish('User.Delete', { id: row.id })
  }

  handleLogin(row) {
    window.location.href = row.switch_user_path;
  }

  handleEdit(row) {
    PubSub.publish('User.Edit', { id: row.id })
  }

  handleResend(row) {
    PubSub.publish('User.ResendInvitation', { id: row.id })
  }

  render() {
    const { row } = this.props;

    let visibleResend = ['subadmin'].includes(row.role);

    return (
      <AbilityContext.Consumer>
        {(ability) =>
          <ParentActionsCell
            bsSize='xs'
            row={row}
            items={[
              { title: 'Login', action: this.handleLogin, hidden: !ability.can('log-in', 'AdminUsers') },
              { title: 'Delete', action: this.handleDelete, hidden: !ability.can('delete', 'AdminUsers') },
              { title: 'Edit', action: this.handleEdit },
              { title: 'Resend', action: this.handleResend, hidden: !visibleResend }
            ]}
          />
        }
      </AbilityContext.Consumer>
    )
  }
}