import React, { Component } from 'react';
import ActivityBox from "../shared/activity_box";
import { BarLoader } from 'react-spinners';

class Item extends Component {
  render() {
    const { data } = this.props;

    return (
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <ActivityBox data={data}/>
      </div>
    )
  }
}

class Loader extends Component {
  render() {
    return (
      <div style={{ height: 300}}>
        <div id="loader-wrapper">
          <div id="loader"/>
        </div>
      </div>
    )
  }
}

export default class ActivitiesList extends Component {
  render() {
    const { data, loading } = this.props;

    if (loading) return <Loader/>;

    return (
      <div className="activities-list">
        <div className="activities-block">
          {data.map((item, index) => <Item key={index} data={item} />)}
        </div>
      </div>
    )
  }
}