import React, { Component } from 'react';
import moment from 'moment';
import Calendar from './calendar';

const sortFunc = (a, b) => a.eventDate.getTime() - b.eventDate.getTime();
const assignAttrs = (scheduleItem) => {
  const eventDate = moment(scheduleItem.start_datetime)
  return {
    ...scheduleItem,
    eventDate: eventDate.toDate(),
    eventDateString: eventDate.format('YYYY-MM-DD')
  }
}

export default class CalendarWrapper extends Component {
  constructor() {
    super();

    this.state = {
      eventDates: [],
      selectedBookingOptionItem: null
    };
  }

  componentDidMount() {
    this.updateProps(this.props);
  }

  componentWillReceiveProps(nextProps) {
    let bookingOptionId = (this.props.bookingOption || {}).id;
    let nextBookingOptionId = (nextProps.bookingOption || {}).id;

    if (nextBookingOptionId !== bookingOptionId) this.updateProps(nextProps);
  }

  updateProps(props) {
    this.prepareDates(props);
  }

  prepareDates = (props) => {
    const { bookingOption, selectedBookingOptionItemId, selectedEventDates } = props;
    const { booking_option_items } = bookingOption;

    let eventDates = [];
    let scheduleDate = {};

    const bookingOptionItem = booking_option_items[0]

    let preselectedBOI = (selectedBookingOptionItemId === bookingOptionItem.id)
    let scheduleItems = bookingOptionItem.schedule_items.map(assignAttrs).sort(sortFunc);

    scheduleItems.forEach((scheduleItem, index) => {
      let preselectedDates = (selectedEventDates || []).includes(scheduleItem.eventDateString)
      let siblingsScheduleItemId = bookingOptionItem.schedule_items.map(schedule_item => schedule_item.id);
      let selected = false;

      if (preselectedBOI) {
        if (preselectedDates) selected = true
      } else {
        if (!bookingOption.pay_per_day) {
          let dates = bookingOptionItem.schedule_items.map(x => x.event_date);
          selected = dates.includes(scheduleItem.eventDateString)
        }
      }

      scheduleDate = {
        bookingOptionItemId: bookingOptionItem.id,
        eventDateString: scheduleItem.eventDateString,
        eventDate: scheduleItem.eventDate,
        startDateTime: scheduleItem.start_datetime,
        endDateTime: scheduleItem.end_datetime,
        timeSlot: scheduleItem.time_slot,
        isStartDate: (bookingOption.pay_per_day ? true : (index === 0)),
        selected: selected,
        bookingOptionItem: bookingOptionItem,
        scheduleItem: scheduleItem,
        scheduleItemId: scheduleItem.id,
        siblingsScheduleItemId: siblingsScheduleItemId,
        availablePlaces: scheduleItem.available_places
      };

      eventDates.push(scheduleDate);
    });

    let selected = (eventDates.filter((eventDate) => eventDate.selected).length > 0);
    let selectedBookingOptionItem = (selected) ? bookingOptionItem : null;

    this.setState({ eventDates, selectedBookingOptionItem }, this.handleUpdateParent);
  };

  handleClickEventDate = (scheduleDate) => {
    const { eventDates } = this.state;
    const { bookingOption } = this.props;
    const { bookingOptionItem, siblingsScheduleItemId } = scheduleDate;

    if (bookingOption.pay_per_day) {
      scheduleDate.selected = !scheduleDate.selected;
    } else {
      let selectedEventDates = eventDates.filter((eventDate) =>
        siblingsScheduleItemId.includes(eventDate.scheduleItemId)
      );

      selectedEventDates.forEach((selectedEventDate) => {
        selectedEventDate.selected = !selectedEventDate.selected;
      });
    }

    let selected = (eventDates.filter((eventDate) => eventDate.selected).length > 0);
    let selectedBookingOptionItem = (selected) ? bookingOptionItem : null;

    this.setState({ selectedBookingOptionItem }, this.handleUpdateParent);
  };

handleUpdateParent() {
  const { updateBookingOptionItem } = this.props;
  const { eventDates, selectedBookingOptionItem } = this.state;

  let selectedStartDates = [];
  let selectedScheduleItemIds = [];

  if (selectedBookingOptionItem) {
    selectedStartDates = eventDates
      .filter(item => item.isStartDate && item.selected)
      .map(item => item.eventDateString);

    selectedScheduleItemIds = eventDates
      .filter(item => item.selected)
      .map(item => item.scheduleItemId);
  }

  updateBookingOptionItem(selectedBookingOptionItem, selectedStartDates, selectedScheduleItemIds);
}

  render() {
    const { eventDates } = this.state;
    const { bookingOption } = this.props;

    return (
      <Calendar
        eventDates={eventDates}
        payPerDay={bookingOption.pay_per_day}
        onClickEventDate={this.handleClickEventDate}
      />
    );
  }
}

