import React, { Component, Fragment } from 'react';
import SmartTable from '../../smart/table';
import { IdCell, DateCell, DigitCell, UserRoleCell, WithTooltip } from '../../smart/cells';
import { ActionsCell } from './cells';
import AccessesCell from './accesses_cell';
import AccessLabel from './access_label';
import StatusLabel from  './status_label';
import Filter from './filter';
import Modal from './modal';
import { AbilityContext } from '../../ability_context';

export default class Table extends Component {
  render() {
    return (
      <AbilityContext.Consumer>
        {ability =>
        <Fragment>
          <Modal {...this.props}/>
          <SmartTable
            TableLeftSide={Filter}
            showHeader={false}
            dataPath='/admin/users.json'
            SubComponent={(row) => <AccessesCell {...row.original}/>}
            name="User"
            columns={[
              {
                Header: "ID",
                accessor: "id",
                width: 50,
                show: ability.can('seeIdColumn', 'AdminUsers'),
                Cell: props => <IdCell id={props.original.id}/>
              },
              {
                Header: "Status",
                accessor: "status_text",
                width: 70,
                sortable: false,
                show: ability.can('seeStatusColumn', 'AdminUsers'),
                Cell: props => <div className="text-center"><StatusLabel value={props.value}/></div>
              },
              {
                Header: "Name",
                accessor: "name",
                Cell: props => <WithTooltip>{props.value}</WithTooltip>
              },
              {
                Header: "Email",
                accessor: "email",
                Cell: props => <WithTooltip>{props.value}</WithTooltip>
              },
              {
                Header: "Role",
                accessor: "role",
                width: 100,
                Cell: props => <UserRoleCell role={props.value}/>
              },
              {
                expander: true,
                Header: "Access",
                width: 80,
                style: { padding: "0" },
                Expander: ({isExpanded, ...rest}) => {
                  if (!rest.original.accesses) {
                    if (rest.original.role === 'system_admin') {
                      return (
                        <div className="text-center" style={{ padding: "7px 5px"}}>
                          <AccessLabel accessType="full_access"/>
                        </div>
                      )
                    } else {
                      return null;
                    }
                  } else {
                    return (
                      <div className="text-center" style={{ fontSize: 25, padding: 0, userSelect: "none" }}>
                        {isExpanded ? <span>&#x2299;</span> : <span>&#x2295;</span>}
                      </div>
                    );
                  }
                },
                getProps: (state, rowInfo, column) => {
                  if (rowInfo) { if (!rowInfo.original.accesses) { return { onClick: () => {} } } }
                  return { className: 'show-pointer' };
                }
              },
              {
                Header: "Logins",
                accessor: "sign_in_count",
                width: 70,
                Cell: props => <DigitCell value={props.value}/>
              },
              {
                Header: "Last Login",
                accessor: "current_sign_in_at",
                width: 100,
                Cell: props => <DateCell date={props.value}/>
              },
              {
                Header: "Actions",
                sortable: false,
                width: 70,
                style: {overflow: 'inherit'},
                Cell: props => <ActionsCell row={props.original}/>
              }
            ]}
          />
        </Fragment>
        }
      </AbilityContext.Consumer>
    );
  }
}
