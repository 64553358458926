import React, { Component } from 'react';

export default class ActivityCategoryItem extends Component {
  handleClick = () => {
    const { id, venueId } = this.props;
    window.location.href = `/booking?venue_id=${venueId}&activity_category_id=${id}`
  };


  render() {
    const { thumb, title } = this.props;

    return (
      <div className="collection" style={{backgroundImage: `url(${thumb})`}} onClick={this.handleClick}>
        <span>
          <div className="overlay">
            <div className="overlay-wrapper">
              <div className="title font-headings">
                {title}
              </div>
            </div>
          </div>
        </span>
      </div>
    )
  }
};