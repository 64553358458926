import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

export default class Block extends Component {
  render() {
    const {
      onDefault,
      onEdit,
      id,
      title,
      company_name,
      invoice_logo,
      invoice_address_line_1,
      invoice_city,
      invoice_postcode,
      invoice_local_authority,
      company_registration_number,
      charity_number,
      invoice_phone_number,
      billing_email,
      vat_registration_number,
      bank_account_name,
      bank_account_sortcode,
      bank_account_number
    } = this.props;

    const { thumb } = invoice_logo;

    return (
      <tr>
        <td>
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <dl className="dl-horizontal">
                <dt>Title:</dt>
                <dd><strong>{title}</strong></dd>
              </dl>
            </div>
            <div className="col-lg-6 col-md-12 text-right">
              <div className="btn-group">
                {/*<Button bsSize="xs" bsStyle="warning" onClick={() => onDefault(id)}>Default</Button>*/}
                <Button bsSize="xs" bsStyle="primary" onClick={() => onEdit(id)}>Edit</Button>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-5">
              <dl className="dl-horizontal">
                <dt>Company Name:</dt>
                <dd>{company_name}</dd>
                <dt>Company Logo:</dt>
                <dd><img src={thumb.url}/></dd>
              </dl>
            </div>

            <div className="col-lg-7" id="cluster_info">
              <dl className="dl-horizontal">
                <dt>Company Address:</dt>
                <dd>{invoice_address_line_1}</dd>

                <dt>City:</dt>
                <dd>{invoice_city}</dd>

                <dt>Postcode:</dt>
                <dd>{invoice_postcode}</dd>

                <dt>Registered office:</dt>
                <dd>{invoice_local_authority}</dd>

                <dt>Registration number:</dt>
                <dd>{company_registration_number}</dd>

                <dt>Charity number:</dt>
                <dd>{charity_number}</dd>

                <dt>Phone number:</dt>
                <dd>{invoice_phone_number}</dd>

                <dt>Billing Email:</dt>
                <dd>{billing_email}</dd>

                <dt>VAT registration number:</dt>
                <dd>{vat_registration_number}</dd>

                <dt>Bank account name:</dt>
                <dd>{bank_account_name}</dd>

                <dt>Bank account sortcode:</dt>
                <dd>{bank_account_sortcode}</dd>

                <dt>Bank account number:</dt>
                <dd>{bank_account_number}</dd>
              </dl>
            </div>
          </div>
        </td>
      </tr>
    )
  }
}

