import React from 'react';
import SmartModal from '../../smart/modal';

export default class Modal extends SmartModal {
  renderForm() {
    return (
      <div>
        <SmartModal.FieldGroup
          type="text"
          label="Title"
          name="county[title]"
          defaultValue={this.formValue('title')}
          error={this.formError('title')}
        />
        <SmartModal.FieldGroup
          type="select"
          label="Region"
          options={this.includedData('regions')}
          name="county[region_id]"
          defaultValue={this.formValue('region_id')}
          error={this.formError('region_id')}
        />
      </div>
    );
  }
}
