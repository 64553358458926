import React, { Component } from 'react';
import axios from 'axios';
import classnames from 'classnames';
import PubSub from 'pubsub-js';

export default class VisibleOnHomepageButton extends Component {
  handleToggleVisibleOnHomepage = (id) => {
    axios
      .put(`/admin/activity_categories/${id}/toggle_visible_on_homepage.json`)
      .then(() => {
        PubSub.publish('ActivityCategory.Updated');
      })
      .catch((error) => {
        console.log(error);
      })
  };

  render() {
    let { id, visible_on_homepage } = this.props;
    const defaultClasses = 'btn btn-xs';
    const attributes = {
      true: { className: 'btn-activity-category-visible', title: 'Visible' },
      false: { className: 'btn-activity-category-hidden', title: 'Hidden' }
    };

    let buttonParams = attributes[visible_on_homepage];
    let buttonClasses = classnames([defaultClasses, buttonParams.className]);
    let buttonTitle = buttonParams.title;

    return (
      <a className={buttonClasses} onClick={() => this.handleToggleVisibleOnHomepage(id)}>
        {buttonTitle}
      </a>
    )
  }
};