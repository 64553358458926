import React, { Component, Fragment } from 'react';
import Item from './item';
import Modal from './modal';

export default class List extends Component {
  itemChanged = () => this.props.onChanged();

  render() {
    const { data, path, groupUid } = this.props;

    if (!(Array.isArray(data) && data.length > 0)) return null;

    return (
      <Fragment>
        <Modal
          name="ScheduleItem"
          title="Schedule Item"
          path={`${path}`}
          groupUid={groupUid}
        />

        <table className="table table-bordered-2 table-schedule-items">
          <thead>
          <tr>
            <th style={{ width: 10 }}/>
            <th style={{ width: 140 }}>Date</th>
            <th style={{ width: 140 }}>Time Slot</th>
            <th style={{ width: 70 }}>Qty spaces</th>
            <th style={{ width: 70 }}>Booked spaces</th>
            <th style={{ width: 70 }}>Available spaces</th>
            <th style={{ width: 70 }}>Enabled</th>
            <th/>
            <th style={{ width: 120 }}/>
          </tr>
          </thead>
          <tbody>
          {data.map((item) =>
            <Item
              key={item.id}
              path={path}
              onChanged={this.itemChanged}
              groupUid={groupUid}
              {...item}
            />)
          }
          </tbody>
        </table>
      </Fragment>
    );
  }
};