import React from 'react';
import SmartModal from '../smart/modal';
import Form from './form';
import PubSub from 'pubsub-js';
import { Button } from "react-bootstrap";

export default class Modal extends SmartModal {
  constructor(props) {
    super(props);

    this.state = {
      modalStyle: undefined,
      show: false,
      data: {},
      included: {},
      formData: {},
      formErrors: {},
      loading: true,
      datesLength: 0
    };

    this.formRef = React.createRef();
  }

  renderForm() {
    this.editForm = React.createRef();

    return (
      <Form
        ref={this.editForm}
        bookingItem={this.state.formData}
        venue={this.includedData('venue')}
        updateDatesCount={(datesLength) => this.setState({ datesLength })}
      />
    );
  }

  applyEdit = () => {
    const { bookingId } = this.state.data;

    this.editForm.current.updateBookingItem(() => {
      this.handleClose();
      PubSub.publish('BookingItem.Updated', { id: bookingId })
    });
  };

  renderButtons() {
    const { action, datesLength } = this.state;
    let fn = this[`render${action}Buttons`];

    if (typeof fn === 'function') return fn();

    return (
      <div>
        <Button className='btn btn-default' onClick={this.handleClose}>Cancel</Button>
        <Button className='btn btn-primary' disabled={datesLength===0} onClick={this.handlePerform}>Confirm</Button>
      </div>
    )
  }
}
