import React, { Component } from 'react';
import ParentActionsCell from '../../smart/actions_cell';
import PubSub from 'pubsub-js';
import { AbilityContext } from "../../ability_context";

export class ActionsCell extends Component {
  handleEdit(row) {
    window.location.href = `/admin/venues/${row.id}`;
  }

  handlePreview(row) {
    window.location.href = row.seo_link;
  }

  handleDelete(row) {
    PubSub.publish('Venue.Delete', { id: row.id });
  }

  handleArchive(row) {
    PubSub.publish('Venue.Delete', { id: row.id });
  }

  render() {
    const { row } = this.props;

    return (
      <AbilityContext.Consumer>
        {(ability) =>
          <ParentActionsCell
            row={row}
            bsSize='xs'
            items={[
              { title: 'Edit', action: this.handleEdit },
              { title: 'Preview', action: this.handlePreview },
              { title: 'Delete', action: this.handleDelete,  hidden: !ability.can('delete', 'AdminVenues') }
            ]}
          />
        }
      </AbilityContext.Consumer>
    )
  }
}