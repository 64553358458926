import React, { Component } from 'react';
import Formatted from '../formatted';

export default class BillTotals extends Component {
  render() {
    const { bill } = this.props;

    if (bill === undefined) return null;

    const {
      subtotal,
      total,
      activity_in_progress_price_adjustment,
      unused_sessions_adjustment,
      multiple_attendees_discount_total,
      promo_code_discount_total,
      final_venue_adjustment,
      overall_discount_reason,
      total_discounts,
      vat_total,
      gross_total,
      total_price_paid
    } = bill;

    return (
      <div className="total-table">
        <table>
          <tbody>
          {false &&
          <tr>
            <td className="text-strong">Subtotal:</td>
            <td><Formatted.Price price={subtotal}/></td>
          </tr>
          }
          <tr>
            <td colSpan={2} className="space"/>
          </tr>
          {activity_in_progress_price_adjustment > 0 &&
          <tr>
            <td>Activity In Progress Adjustment:</td>
            <td><Formatted.Price price={activity_in_progress_price_adjustment * -1}/></td>
          </tr>
          }
          {unused_sessions_adjustment > 0 &&
          <tr>
            <td>Unused Sessions Adjustment:</td>
            <td><Formatted.Price price={unused_sessions_adjustment * -1}/></td>
          </tr>
          }
          {multiple_attendees_discount_total > 0 &&
          <tr>
            <td>Multiple Attendee Adjustment:</td>
            <td><Formatted.Price price={multiple_attendees_discount_total * -1}/></td>
          </tr>
          }
          {promo_code_discount_total > 0 &&
          <tr>
            <td>Promo Code Adjustment:</td>
            <td><Formatted.Price price={promo_code_discount_total * -1}/></td>
          </tr>
          }
          {(final_venue_adjustment > 0 || final_venue_adjustment < 0) &&
          <tr>
            <td>
              <div>Final Venue Adjustment:</div>
              <div className="description">{overall_discount_reason}</div>
            </td>
            <td><Formatted.Price price={final_venue_adjustment * -1}/></td>
          </tr>
          }
          <tr>
            <td colSpan={2} className="space"/>
          </tr>
          <tr>
            <td>Total Booking Value:</td>
            <td><Formatted.Price price={total}/></td>
          </tr>
          <tr>
            <td>VAT:</td>
            <td><Formatted.Price price={vat_total}/></td>
          </tr>
          <tr>
            <td>Gross Total:</td>
            <td><Formatted.Price price={gross_total}/></td>
          </tr>
          <tr>
            <td colSpan={2} className="space"/>
          </tr>
          <tr>
            <td className="text-strong">Total Price:</td>
            <td><Formatted.Price price={total_price_paid}/></td>
          </tr>
          </tbody>
        </table>
      </div>
    );
  }
}