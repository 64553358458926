import React from 'react';
import ReactDOM from 'react-dom';
import ApplicationLayout from './layouts/application'

let components = {};

function renderComponent() {
  let component_element = document.getElementById('component');
  let page = component_element.dataset.page;
  let component = null;

  if (component_element) component = components[page];
  if (component === undefined) return false;

  let applicationLayoutProps = {
    menu: component.menu,
    submenu: component.submenu,
    containerClasses: component.containerClasses,
    showFooter: component.showFooter
  };

  ReactDOM.render(
    <ApplicationLayout {...applicationLayoutProps}>{component.component}</ApplicationLayout>,
    component_element
  )
}

function appendComponents(list) {
  components = { ...components, ...list  }
}

document.addEventListener('DOMContentLoaded', () => {
  renderComponent();
});

export { appendComponents };