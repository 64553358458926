import React from 'react';
import SmartModal from '../../smart/modal';
import axios from 'axios';

export default class Modal extends SmartModal {
  showEdit = () => {
    const { path } = this.props;

    axios
      .get(`${path}/edit.json`)
      .then((response) => {
        const response_data = response.data;
        this.setState(
          { formData: response_data.data, included: response_data.included, loading: false },
          () => this.onShowEdit()
        );
      })
      .catch((error) => console.log(error));
  };

  applyEdit = () => {
    const { path, name } = this.props;
    let data = this.getData();

    axios
      .put(`${path}.json`, data)
      .then(() => {
        this.handleClose();
        PubSub.publish(`${name}.Updated`, {});
      })
      .catch((error) => {
        this.setState({ formErrors: error.response.data.errors });
      });
  };

  renderForm() {
    return (
      <div>
        <SmartModal.FieldGroup
          label="Commission fee for all transactions (%)"
          requred={true}
          name="application_settings[commission_fee_percent]"
          defaultValue={this.formValue('commission_fee_percent')}
          error={this.formError('commission_fee_percent')}
        />

        <SmartModal.FieldGroup
          label="Booking TTL (seconds)"
          requred={true}
          name="application_settings[booking_ttl_seconds]"
          defaultValue={this.formValue('booking_ttl_seconds')}
          error={this.formError('booking_ttl_seconds')}
        />
      </div>
    );
  }
}
